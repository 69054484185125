import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { SnackbarProvider } from 'notistack';
import { SecuredComponent } from './services/authentication';
import { reactPlugin } from './services/appInsights';
import { MsalClient } from './authentication/MsalContext';
import { MsalProvider } from './authentication/MsalProvider';
import { useHandleRedirect } from './authentication/useHandleRedirect';
import { AppBarProvider } from './hooks/useAppBarContext';
import withThemeProvider from './helpers/withThemeProvider';
import GiveCogs from './pages/GiveCogs';
import Team from './pages/Team';
import AvailableBadges from './pages/AvailableBadges';
import MyDetails from './pages/MyDetails';
import Layout from './features/layout/Layout';
import NotFound from './pages/NotFound';
import BadgeManagement from './pages/BadgeManagement';
import MyMessages from './pages/MyMessages';
import MyCogSentHistory from './pages/MyCogSentHistory';

const App: React.FC = () => {
  useHandleRedirect();

  return (
    <MsalProvider instance={MsalClient}>
      <SecuredComponent>
        <AppInsightsContext.Provider value={reactPlugin}>
          <SnackbarProvider maxSnack={4}>
            <AppBarProvider>
              <Router>
                <Layout>
                  <Switch>
                    <Route exact path="/">
                      <GiveCogs />
                    </Route>
                    <Route path="/team">
                      <Team />
                    </Route>
                    <Route path="/badges">
                      <AvailableBadges />
                    </Route>
                    <Route path="/badge-management">
                      <BadgeManagement />
                    </Route>
                    <Route path="/my-details">
                      <MyDetails />
                    </Route>
                    <Route path="/my-messages">
                      <MyMessages />
                    </Route>
                    <Route path="/my-cog-sent-history">
                      <MyCogSentHistory />
                    </Route>
                    <Route>
                      <NotFound />
                    </Route>
                  </Switch>
                </Layout>
              </Router>
            </AppBarProvider>
          </SnackbarProvider>
        </AppInsightsContext.Provider>
      </SecuredComponent>
    </MsalProvider>
  );
};

export default withThemeProvider(App);
