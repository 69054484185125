import React from 'react';
import { InteractionType, RedirectRequest } from '@azure/msal-browser';
import { MsalClient, MsalConsumer } from '../authentication/MsalContext';
import { getAuthenticationSettings } from './authenticationSettings';
import { useMsal } from '../authentication/MsalProvider';
import { useMsalAuthentication } from '../authentication/useMsalAuthentication';

const loginRequest: RedirectRequest = {
  scopes: ['user.read']
};

const useAccessToken = (): [() => Promise<string | undefined | void>] => {
  const authenticationSettings = getAuthenticationSettings();
  const { primaryAccount, loginInProgress } = useMsal();

  const getAccessToken = async (): Promise<string | undefined | void> => {
    if (primaryAccount && !loginInProgress) {
      const request = {
        scopes: [authenticationSettings.ApiScope],
        account: primaryAccount,
        forceRefresh: false
      };

      try {
        const token = await MsalClient.acquireTokenSilent(request);
        return token?.accessToken;
      } catch {
        return await MsalClient.acquireTokenRedirect(request);
      }
    }

    return undefined;
  };

  return [getAccessToken];
};

const SecuredComponent: React.FC = ({ children }) => {
  const { primaryAccount } = useMsal();
  const { error } = useMsalAuthentication(InteractionType.Redirect, loginRequest, {
    homeAccountId: primaryAccount?.homeAccountId
  });

  return (
    <MsalConsumer>
      {msal => (
        <>
          {msal.loginInProgress && <h3>Logging in...</h3>}
          {!msal.loginInProgress && msal.accounts.length > 0 && children}
          {!msal.loginInProgress && msal.accounts.length === 0 && <h3>Not logged in.</h3>}
          {!msal.loginInProgress && msal.accounts.length === 0 && error !== null && (
            <h3>An error occurred when we tried to log you in: {error?.errorMessage}</h3>
          )}
        </>
      )}
    </MsalConsumer>
  );
};

export { SecuredComponent, useAccessToken };
