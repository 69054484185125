import React, { useState } from 'react';
import { Button, Typography, createStyles, makeStyles } from '@material-ui/core';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '../services/appInsights';
import { useGet, useGetAndUpdate } from '../hooks/useApi';
import { useApiErrorHandler } from '../hooks';
import { getDepartments, getUser } from '../api/endpoints';
import { LoadingIndicator } from '../features/common';
import AvatarCreator from '../features/common/avatar/AvatarCreator';
import DepartmentFilter from '../features/give-cogs/DepartmentFilter';
import { Title } from '../features/typography';
import IDepartment from '../models/IDepartment';
import IUser from '../models/IUser';
import { useMsal } from '../authentication/MsalProvider';

const useStyles = makeStyles(() =>
  createStyles({
    titleContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }
  })
);

const MyDetails = () => {
  const classes = useStyles();
  const { onApiError } = useApiErrorHandler();
  const { primaryAccount } = useMsal();
  const [isAvatarCreatorShown, setIsAvatarCreatorShown] = useState<boolean>(false);
  const [user, , putUser, isLoadingUser] = useGetAndUpdate<IUser>(getUser(primaryAccount?.localAccountId || ''), {
    onError: onApiError('user')
  });
  const [departments, isLoadingDepartments] = useGet<IDepartment[]>(getDepartments(), { onError: onApiError('departments') });

  const handleDepartmentSelect = async (selectedDepartment: string | null) => {
    if (user) {
      const newUserDetails = { ...user, DepartmentName: selectedDepartment };
      await putUser(newUserDetails);
    }
  };

  return (
    <>
      <LoadingIndicator isLoading={isLoadingUser} />
      <div className={classes.titleContainer}>
        <Title text={`Hello ${primaryAccount?.name}`} />
        <Button variant="contained" color="secondary" onClick={() => setIsAvatarCreatorShown(!isAvatarCreatorShown)}>
          {isAvatarCreatorShown ? 'Hide Avatar Creator' : 'Create New Avatar!'}
        </Button>
      </div>
      {user?.DepartmentName === null && (
        <>
          <Typography color="error">
            You have not yet selected your department. Please select one from the following choices:
          </Typography>
          <DepartmentFilter
            departments={departments ?? null}
            areDepartmentsLoading={isLoadingDepartments}
            onCurrentDepartment={handleDepartmentSelect}
            showAllOption={false}
          />
        </>
      )}
      {!isLoadingUser && user?.DepartmentName && (
        <Typography>You're in the {user.DepartmentName} department. Some say that's the best one...</Typography>
      )}
      {user && isAvatarCreatorShown && <AvatarCreator userId={user?.UserId} />}
    </>
  );
};

export default withAITracking(reactPlugin, MyDetails, 'My Details Page');
