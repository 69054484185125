import React from 'react';
import { BadgeProps, inactiveColor } from './badgeHelpers';

const ThirtyCogsBadge: React.FC<BadgeProps> = ({ isActive }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 56.69 56.69"
    xmlSpace="preserve"
  >
    <polygon
      style={{ fill: isActive ? '#E66459' : inactiveColor }}
      points="35.78,53.78 29.4,47.66 23.02,53.78 23.02,20.54 35.78,20.54 "
    />
    <polygon
      style={{
        fill: 'none',
        stroke: '#3E3C3C',
        strokeWidth: 0.7648,
        strokeMiterlimit: 10
      }}
      points="35.78,53.78 29.4,47.66 23.02,53.78 23.02,20.54 35.78,20.54 "
    />
    <path
      style={{ fill: '#3E3C3C' }}
      d="M41.35,16.59c0-1.25-0.78-2.31-1.87-2.74c0.66-0.91,0.77-2.16,0.17-3.2c-0.62-1.08-1.83-1.61-2.99-1.43
c0.12-1.12-0.41-2.25-1.45-2.85c-1.08-0.62-2.39-0.48-3.31,0.25c-0.46-1.03-1.49-1.75-2.68-1.75c-1.25,0-2.31,0.78-2.74,1.87
c-0.91-0.66-2.16-0.77-3.2-0.17c-1.08,0.62-1.61,1.83-1.43,2.99C20.74,9.45,19.6,9.99,19,11.02c-0.62,1.08-0.48,2.39,0.25,3.31
c-1.03,0.46-1.75,1.49-1.75,2.68c0,1.25,0.78,2.31,1.87,2.74c-0.66,0.91-0.77,2.16-0.17,3.2c0.62,1.08,1.83,1.61,2.99,1.43
c-0.12,1.12,0.41,2.25,1.45,2.85c1.08,0.62,2.39,0.48,3.31-0.25c0.46,1.03,1.49,1.75,2.68,1.75c1.25,0,2.31-0.78,2.74-1.87
c0.91,0.66,2.16,0.77,3.2,0.17c1.08-0.62,1.61-1.83,1.43-2.99c1.12,0.12,2.25-0.41,2.85-1.45c0.62-1.08,0.48-2.39-0.25-3.31
C40.64,18.82,41.35,17.79,41.35,16.59z"
    />
    <g>
      <g>
        <circle
          style={{ fill: isActive ? '#E66459' : inactiveColor }}
          cx="29.43"
          cy="16.8"
          r="9.79"
        />
        <circle
          style={{
            fill: 'none',
            stroke: '#3E3C3C',
            strokeWidth: 0.7648,
            strokeMiterlimit: 10
          }}
          cx="29.43"
          cy="16.8"
          r="9.79"
        />
      </g>
      <g>
        <polyline
          style={{
            fill: 'none',
            stroke: '#FCF0E6',
            strokeWidth: 0.7648,
            strokeMiterlimit: 10
          }}
          points="23.7,17.83 27.39,21.52 35.78,13.13 		"
        />
      </g>
    </g>
  </svg>
);

export default ThirtyCogsBadge;
