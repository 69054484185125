import { Theme } from '@material-ui/core';

const drawerWidth = 240;

const getDrawerHeaderStyle = (theme: Theme) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end'
});

export { drawerWidth, getDrawerHeaderStyle };
