import React, { useState } from 'react';
import {
  createStyles,
  makeStyles,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import CogSentHistoryRow from './CogSentHistoryRow';
import ICogSentHistory from '../../models/ICogSentHistory';

interface CogSentHistoryTableProps {
  cogsSentHistory: ICogSentHistory[];
  maxRows: number;
}

const useStyles = makeStyles(() =>
  createStyles({
    cogSentHistoryColumn: {
      width: '40%'
    },
    spinnerColumn: {
      width: '5%',
      alignContent: 'left'
    },
    buttonGroup: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      maxWidth: '40%',
      margin: '0 auto'
    }
  })
);

const CogSentHistoryTable: React.FC<CogSentHistoryTableProps> = ({ cogsSentHistory: cogsHistory, maxRows }) => {
  const [startingDisplayIndex, setStartingDisplayIndex] = useState<number>(0);
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date Sent</TableCell>
            <TableCell>Recipient</TableCell>
            <TableCell className={classes.cogSentHistoryColumn}>Message</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cogsHistory
            .sort((a, b) => new Date(b.DateSent).getTime() - new Date(a.DateSent).getTime())
            .slice(startingDisplayIndex, startingDisplayIndex + maxRows)
            .map(cogSentHistory => (
              <CogSentHistoryRow key={cogSentHistory.CogSentHistoryId} cogSentHistory={cogSentHistory} />
            ))}
        </TableBody>
      </Table>
      <div className={classes.buttonGroup}>
        <Button
          size="large"
          color="secondary"
          onClick={() => setStartingDisplayIndex(startingDisplayIndex - maxRows)}
          disabled={startingDisplayIndex === 0}
        >
          <NavigateBeforeIcon fontSize="large" />
        </Button>
        <Button
          size="large"
          color="secondary"
          onClick={() => setStartingDisplayIndex(startingDisplayIndex + maxRows)}
          disabled={startingDisplayIndex + maxRows >= cogsHistory.length}
        >
          <NavigateNextIcon fontSize="large" />
        </Button>
      </div>
    </TableContainer>
  );
};

export default CogSentHistoryTable;
