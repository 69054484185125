import React, { useState } from 'react';
import Bounce from 'react-reveal/Bounce';
import Spin from 'react-reveal/Spin';
import santa from './images/santa.png';
import santaWithRocket from './images/santa_with_rocket.png';
import reindeer from './images/reindeer.png';
import tree from './images/tree.png';
import tree2 from './images/tree2.png';

const randomiseLocation = () => Math.floor(Math.random() * 80) + 15;

const Santa: React.FC = () => {
  const [location, setLocation] = useState(randomiseLocation);
  const [showSanta, setShowSanta] = useState(true);
  const [showSantaWithRockets, setShowSantaWithRockets] = useState(false);
  const [showReindeer, setShowReindeer] = useState(true);

  const treeLocations = [15, 20, 45, 56, 67, 85];

  const handleSantaClick = async () => {
    await setShowSantaWithRockets(false);
    await setShowSanta(false);
    setLocation(randomiseLocation);
    await new Promise(r => setTimeout(r, 1100));
    await setShowSanta(true);
  };

  const handleReindeerClick = async () => {
    await setShowReindeer(false);
    await setShowReindeer(true);
  };

  return (
    <div>
      {treeLocations.map(x => (
        <div key={x} style={{ paddingTop: '2em' }}>
          <img
            alt="Snowy Tree"
            src={tree}
            style={{
              position: 'fixed',
              left: `${x}%`,
              bottom: '0%',
              right: '0'
            }}
          />
          <img
            alt="Snowy Tree"
            src={tree2}
            style={{
              position: 'fixed',
              left: `${x - 10 * 1.6}%`,
              bottom: '0',
              right: '0'
            }}
          />
        </div>
      ))}
      {showSanta ? (
        <Bounce bottom when={!showSantaWithRockets}>
          <img
            src={santa}
            alt="Santa"
            onClick={async () => {
              setShowSantaWithRockets(true);
              await new Promise(r => setTimeout(r, 1100));
              handleSantaClick();
            }}
            style={{
              position: 'fixed',
              left: `${location}%`,
              bottom: '0',
              right: '0'
            }}
          />
        </Bounce>
      ) : (
        <Bounce top>
          <img
            alt="Flying Santa with Rocket Boots"
            src={santaWithRocket}
            style={{
              position: 'fixed',
              left: `${location}%`,
              bottom: '0',
              right: '0'
            }}
          />
        </Bounce>
      )}
      {showReindeer && (
        <Spin>
          <img
            alt="Christmassy Reindeer"
            src={reindeer}
            onClick={() => {
              handleReindeerClick();
            }}
            style={{
              position: 'fixed',
              left: '10%',
              bottom: '0',
              right: '0'
            }}
          />
        </Spin>
      )}
    </div>
  );
};

export default Santa;
