import React from 'react';
import { TableCell, TableRow, Checkbox, CircularProgress } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import IMessage from '../../models/IMessage';
import { usePostAt } from '../../hooks/useApi';
import { useApiErrorHandler, useAppBarContext } from '../../hooks';
import { updateMessageStatusRead, updateMessageStatusUnread } from '../../api/endpoints';

interface MessageRowProps {
  message: IMessage;
  classes: Record<'messageColumn' | 'spinnerColumn' | 'unreadColumn', string>;
}

const MessageRow: React.FC<MessageRowProps> = ({ message, classes }) => {
  const { onApiError } = useApiErrorHandler();
  const { refreshMessageStatuses } = useAppBarContext();

  const [postUnread, , isPostingUnread] = usePostAt<any>(updateMessageStatusUnread(message.PayeeId, message.MessageId), {
    onError: onApiError('messages')
  });

  const [postRead, , isPostingRead] = usePostAt<any>(updateMessageStatusRead(message.PayeeId, message.MessageId), {
    onError: onApiError('messages')
  });

  const handleCheckboxChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      message.IsUnread = false;
      await postRead();
    } else {
      message.IsUnread = true;
      await postUnread();
    }

    await new Promise(r => setTimeout(r, 600));
    await refreshMessageStatuses();
  };

  return (
    <TableRow>
      <TableCell align="center" className={classes.unreadColumn}>
        {message.IsUnread && <FiberManualRecordIcon color="secondary" fontSize="small" />}
      </TableCell>
      <TableCell>{new Date(message.DateSent).toLocaleDateString('en-GB', { timeZone: 'UTC' })}</TableCell>
      <TableCell className={classes.messageColumn}>{message.Body}</TableCell>
      <TableCell>{message.PayerName}</TableCell>
      <TableCell align="center">
        <Checkbox color="secondary" checked={!message.IsUnread} onChange={handleCheckboxChange} />
      </TableCell>
      <TableCell align="left" className={classes.spinnerColumn}>
        {(isPostingRead || isPostingUnread) && <CircularProgress size="1.75em" />}
      </TableCell>
    </TableRow>
  );
};

export default MessageRow;
