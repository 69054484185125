import React from 'react';
import {
  BadgeProps,
  inactiveColor,
  inactiveColorDark,
  inactiveColorLight
} from './badgeHelpers';

const FunkyBadge: React.FC<BadgeProps> = ({ isActive }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 56.69 56.69"
    xmlSpace="preserve"
  >
    <ellipse
      style={{ fill: '#D0CFCE' }}
      cx="34.76"
      cy="46.24"
      rx="15.91"
      ry="0.57"
    />
    <polygon
      style={{ fill: isActive ? '#385C3B' : inactiveColorDark }}
      points="9.66,26.58 9.39,25.63 10.99,25.25 11.22,26.07 "
    />
    <path
      style={{ fill: isActive ? '#8BAD4E' : inactiveColor }}
      d="M10.02,27.51c0,0-0.09-0.23-0.18-0.46c-0.05-0.12-0.09-0.23-0.12-0.32c-0.02-0.04-0.03-0.08-0.04-0.11
	c-0.01-0.03-0.01-0.04-0.01-0.04l1.56-0.51c0,0,0,0.01,0.01,0.04c0.01,0.02,0.01,0.05,0.03,0.09c0.03,0.07,0.07,0.17,0.11,0.27
	c0.08,0.2,0.16,0.39,0.16,0.39L10.02,27.51z"
    />
    <path
      style={{ fill: isActive ? '#385C3B' : inactiveColorDark }}
      d="M10.45,28.4c0,0-0.46-0.88-0.43-0.89l1.51-0.65c0,0,0.02,0.05,0.05,0.12c0.03,0.07,0.08,0.17,0.13,0.26
	c0.1,0.19,0.19,0.38,0.19,0.38L10.45,28.4z"
    />
    <path
      style={{ fill: isActive ? '#8BAD4E' : inactiveColor }}
      d="M10.96,29.25c0,0-0.13-0.21-0.27-0.42c-0.07-0.1-0.13-0.21-0.17-0.29c-0.04-0.08-0.07-0.14-0.07-0.14
	l1.44-0.78c0,0,0.02,0.05,0.06,0.12c0.03,0.07,0.09,0.16,0.15,0.25c0.11,0.18,0.23,0.36,0.23,0.36L10.96,29.25z"
    />
    <path
      style={{ fill: isActive ? '#385C3B' : inactiveColorDark }}
      d="M11.54,30.05c0,0-0.15-0.2-0.3-0.39c-0.08-0.1-0.14-0.2-0.19-0.28c-0.05-0.08-0.08-0.13-0.08-0.13l1.37-0.9
	c0,0,0.03,0.04,0.07,0.11c0.04,0.07,0.1,0.16,0.17,0.24c0.13,0.17,0.26,0.33,0.26,0.33L11.54,30.05z"
    />
    <path
      style={{ fill: isActive ? '#8BAD4E' : inactiveColor }}
      d="M12.19,30.8c0,0-0.17-0.18-0.34-0.36c-0.09-0.09-0.16-0.19-0.22-0.26c-0.06-0.07-0.1-0.12-0.1-0.12l1.29-1.02
	c0,0,0.03,0.04,0.08,0.1c0.05,0.06,0.11,0.15,0.18,0.23c0.15,0.15,0.29,0.31,0.29,0.31L12.19,30.8z"
    />
    <path
      style={{ fill: isActive ? '#385C3B' : inactiveColorDark }}
      d="M12.91,31.49c0,0-0.19-0.16-0.37-0.33c-0.17-0.18-0.34-0.36-0.34-0.36l1.19-1.13c0,0,0.15,0.15,0.29,0.31
	c0.16,0.14,0.32,0.28,0.32,0.28L12.91,31.49z"
    />
    <path
      style={{ fill: isActive ? '#8BAD4E' : inactiveColor }}
      d="M13.68,32.12c0,0-0.2-0.14-0.4-0.3c-0.19-0.16-0.37-0.33-0.37-0.33L14,30.26c0,0,0.16,0.14,0.32,0.28
	c0.17,0.13,0.34,0.25,0.34,0.25L13.68,32.12z"
    />
    <path
      style={{ fill: isActive ? '#385C3B' : inactiveColorDark }}
      d="M14.5,32.67c0,0-0.05-0.03-0.13-0.08c-0.08-0.05-0.19-0.11-0.29-0.18c-0.2-0.15-0.4-0.29-0.4-0.29l0.98-1.32
	c0,0,0.17,0.13,0.34,0.25c0.08,0.07,0.18,0.11,0.25,0.16c0.07,0.04,0.11,0.07,0.11,0.07L14.5,32.67z"
    />
    <path
      style={{ fill: isActive ? '#385C3B' : inactiveColorDark }}
      d="M15.37,33.15c0,0-0.06-0.03-0.14-0.07c-0.08-0.04-0.2-0.09-0.3-0.16c-0.21-0.13-0.43-0.25-0.43-0.25l0.86-1.4
	c0,0,0.18,0.11,0.36,0.22c0.09,0.06,0.19,0.1,0.26,0.14c0.07,0.03,0.12,0.06,0.12,0.06L15.37,33.15z"
    />
    <path
      style={{ fill: isActive ? '#385C3B' : inactiveColorDark }}
      d="M16.27,33.56c0,0-0.06-0.02-0.15-0.05c-0.04-0.02-0.09-0.04-0.15-0.06c-0.05-0.03-0.11-0.05-0.16-0.08
	c-0.22-0.11-0.45-0.21-0.45-0.21l0.73-1.47c0,0,0.19,0.09,0.38,0.18c0.05,0.02,0.1,0.05,0.14,0.07c0.05,0.02,0.09,0.03,0.13,0.05
	c0.07,0.03,0.12,0.05,0.12,0.05L16.27,33.56z"
    />
    <path
      style={{ fill: isActive ? '#8BAD4E' : inactiveColor }}
      d="M17.21,33.88c0,0-0.06-0.02-0.15-0.04c-0.09-0.02-0.21-0.07-0.32-0.11c-0.23-0.09-0.47-0.17-0.47-0.17
	l0.6-1.53c0,0,0.79,0.3,0.8,0.28L17.21,33.88z"
    />
    <path
      style={{ fill: isActive ? '#385C3B' : inactiveColorDark }}
      d="M18.17,34.12c0,0-0.06-0.01-0.15-0.03c-0.09-0.02-0.21-0.05-0.33-0.09c-0.24-0.06-0.48-0.13-0.48-0.13
	l0.47-1.57c0,0,0.2,0.05,0.41,0.11c0.1,0.03,0.2,0.06,0.28,0.07c0.08,0.01,0.13,0.02,0.13,0.02L18.17,34.12z"
    />
    <path
      style={{ fill: isActive ? '#8BAD4E' : inactiveColor }}
      d="M19.15,34.28c0,0-0.02,0-0.04,0c-0.03,0-0.07,0-0.11-0.01c-0.09-0.02-0.21-0.04-0.34-0.06
	c-0.24-0.04-0.49-0.08-0.49-0.08l0.32-1.61c0,0,0.21,0.04,0.42,0.07c0.1,0.02,0.21,0.04,0.29,0.05c0.04,0.01,0.07,0.01,0.1,0.01
	c0.02,0,0.04,0,0.04,0L19.15,34.28z"
    />
    <path
      style={{ fill: isActive ? '#385C3B' : inactiveColorDark }}
      d="M20.14,34.35c0,0-0.06,0-0.15-0.01c-0.09-0.01-0.22-0.02-0.34-0.02c-0.25-0.02-0.5-0.04-0.5-0.04l0.18-1.63
	l0.85,0.06L20.14,34.35z"
    />
    <polygon
      style={{ fill: isActive ? '#8BAD4E' : inactiveColor }}
      points="21.13,34.32 20.14,34.35 20.18,32.7 21.03,32.68 "
    />
    <path
      style={{ fill: isActive ? '#385C3B' : inactiveColorDark }}
      d="M22.12,34.21c0,0-0.25,0.03-0.49,0.06c-0.12,0.01-0.25,0.03-0.34,0.04c-0.05,0.01-0.08,0.01-0.11,0.01
	c-0.03,0-0.04,0-0.04,0l-0.11-1.64c0,0,0.01,0,0.04,0c0.02,0,0.06,0,0.1-0.01c0.08-0.01,0.18-0.02,0.29-0.04
	c0.21-0.03,0.42-0.05,0.42-0.05L22.12,34.21z"
    />
    <path
      style={{ fill: isActive ? '#8BAD4E' : inactiveColor }}
      d="M22.97,34.07c0,0-0.86,0.15-0.89,0.14l-0.27-1.62c-0.02,0.02,0.87-0.14,0.87-0.14L22.97,34.07z"
    />
    <rect
      x="22.82"
      y="32.33"
      transform="matrix(0.9855 -0.1694 0.1694 0.9855 -5.2836 4.4312)"
      style={{ fill: isActive ? '#385C3B' : inactiveColorDark }}
      width="1.01"
      height="1.68"
    />
    <rect
      x="23.82"
      y="32.18"
      transform="matrix(0.9855 -0.1694 0.1694 0.9855 -5.2438 4.5836)"
      style={{ fill: isActive ? '#8BAD4E' : inactiveColor }}
      width="0.84"
      height="1.68"
    />
    <rect
      x="24.64"
      y="32.02"
      transform="matrix(0.9855 -0.1694 0.1694 0.9855 -5.204 4.7359)"
      style={{ fill: isActive ? '#385C3B' : inactiveColorDark }}
      width="1.01"
      height="1.68"
    />
    <rect
      x="25.64"
      y="31.86"
      transform="matrix(0.9855 -0.1694 0.1694 0.9855 -5.1643 4.8883)"
      style={{ fill: isActive ? '#8BAD4E' : inactiveColor }}
      width="0.84"
      height="1.68"
    />
    <rect
      x="26.47"
      y="31.71"
      transform="matrix(0.9855 -0.1694 0.1694 0.9855 -5.1245 5.0407)"
      style={{ fill: isActive ? '#385C3B' : inactiveColorDark }}
      width="1.01"
      height="1.68"
    />
    <rect
      x="27.47"
      y="31.55"
      transform="matrix(0.9855 -0.1694 0.1694 0.9855 -5.0847 5.193)"
      style={{ fill: isActive ? '#8BAD4E' : inactiveColor }}
      width="0.84"
      height="1.68"
    />
    <rect
      x="28.29"
      y="31.39"
      transform="matrix(0.9855 -0.1694 0.1694 0.9855 -5.0449 5.3454)"
      style={{ fill: isActive ? '#385C3B' : inactiveColorDark }}
      width="1.01"
      height="1.68"
    />
    <rect
      x="29.29"
      y="31.24"
      transform="matrix(0.9855 -0.1694 0.1694 0.9855 -5.0052 5.4977)"
      style={{ fill: isActive ? '#8BAD4E' : inactiveColor }}
      width="0.84"
      height="1.68"
    />
    <rect
      x="30.12"
      y="31.08"
      transform="matrix(0.9855 -0.1694 0.1694 0.9855 -4.9654 5.6501)"
      style={{ fill: isActive ? '#385C3B' : inactiveColorDark }}
      width="1.01"
      height="1.68"
    />
    <circle
      style={{ fill: isActive ? '#385C3B' : inactiveColorDark }}
      cx="10.12"
      cy="25.35"
      r="1.74"
    />
    <path
      style={{ fill: isActive ? '#385C3B' : inactiveColorDark }}
      d="M12.92,23.39l-1.11,1.54l-0.48-0.34l1.46-2.02l0,0C13.06,22.76,13.11,23.13,12.92,23.39z"
    />
    <polygon
      style={{ fill: isActive ? '#F7B471' : inactiveColorLight }}
      points="2.82,35.3 3,35.43 3.56,34.63 3.1,34.31 "
    />
    <polygon
      style={{ fill: isActive ? '#DE7F2E' : inactiveColorLight }}
      points="3.18,35.55 4.01,34.95 3.56,34.63 3,35.43 "
    />
    <polygon
      style={{ fill: '#4E4742' }}
      points="2.64,35.93 3.18,35.55 3,35.43 2.82,35.3 "
    />
    <path
      style={{ fill: isActive ? '#DE7F2E' : inactiveColorLight }}
      d="M17.16,16.36L4.09,34.87c-0.18,0.26-0.54,0.32-0.79,0.14h0c-0.26-0.18-0.32-0.54-0.14-0.79l13.08-18.5
	L17.16,16.36z"
    />
    <rect
      x="16.49"
      y="15.06"
      transform="matrix(0.5771 -0.8167 0.8167 0.5771 -5.582 20.4823)"
      style={{ fill: '#4E4742' }}
      width="0.99"
      height="1.14"
    />
    <path
      style={{ fill: isActive ? '#9F4C18' : '#333333' }}
      d="M3.3,35.01c0.26,0.18,0.61,0.12,0.79-0.14l13.08-18.5l-0.46-0.33L3.3,35.01z"
    />
    <polygon
      style={{ fill: '#3E3C3C' }}
      points="26.77,44.62 26.67,38.16 28.04,38.14 28.16,45.75 22.23,45.85 22.21,44.69 "
    />
    <polygon
      style={{ fill: '#3E3C3C' }}
      points="35.14,44.54 35.15,44.54 33.4,36.26 34.74,35.98 36.49,44.25 40.95,43.31 41.19,44.44 35.38,45.67 "
    />
    <path
      style={{ fill: '#754D34' }}
      d="M22.97,30.95c0,0,0.09,0.11,0.23,0.26c0.14,0.16,0.32,0.37,0.5,0.58c0.18,0.21,0.38,0.42,0.52,0.57
	c0.15,0.16,0.26,0.27,0.26,0.27l1.42-1.42c0.01,0.02-0.35-0.36-0.7-0.76c-0.18-0.2-0.35-0.4-0.48-0.55
	c-0.13-0.15-0.22-0.25-0.22-0.25L22.97,30.95z"
    />
    <polygon
      style={{ fill: '#32A1CA' }}
      points="29.17,16.78 47.02,39.1 31.34,40.53 15.66,41.96 "
    />
    <polygon
      style={{ fill: isActive ? '#385C3B' : inactiveColorDark }}
      points="29.17,16.78 47.02,39.1 31.34,40.53 15.66,41.96 "
    />
    <polygon
      style={{ fill: '#3E3C3C' }}
      points="29.17,16.78 31.34,40.53 15.66,41.96 "
    />
    <polygon
      style={{ fill: isActive ? '#779C48' : inactiveColor }}
      points="29.17,16.78 31.34,40.53 15.66,41.96 "
    />
    <polygon
      style={{ fill: '#F19441' }}
      points="20.38,38.85 20.38,38.85 20.38,38.85 "
    />
    <polygon
      style={{ fill: '#F19441' }}
      points="21.99,38.7 21.99,38.7 21.99,38.7 "
    />
    <circle style={{ fill: '#F2F9F6' }} cx="30.45" cy="29.55" r="3.05" />
    <path
      style={{ fill: '#3E3C3C' }}
      d="M32.66,29.35c-0.13-1.45-1.34-2.55-2.77-2.57c0.1-0.02,0.2-0.03,0.3-0.04c1.55-0.14,2.93,1,3.07,2.56
	c0.14,1.55-1,2.93-2.56,3.07c-0.1,0.01-0.2,0.01-0.3,0.01C31.8,32.09,32.79,30.8,32.66,29.35z"
    />
    <circle style={{ fill: '#3E3C3C' }} cx="31.4" cy="29.77" r="0.9" />
    <circle style={{ fill: '#F2F9F6' }} cx="33.1" cy="35.41" r="1.71" />
    <path
      style={{ fill: '#3E3C3C' }}
      d="M33.29,36.38c0.54-0.1,0.89-0.62,0.79-1.16c-0.1-0.54-0.62-0.89-1.16-0.79c-0.54,0.1-0.89,0.62-0.79,1.16
	C32.24,36.13,32.76,36.48,33.29,36.38z"
    />
    <path
      style={{ fill: '#3E3C3C' }}
      d="M33.53,34.47c0.27,0.13,0.48,0.38,0.54,0.7l-0.39,0.08L33.53,34.47z"
    />
    <path
      style={{ fill: '#779C48' }}
      d="M33.29,35.32l-0.18-0.95c0.15,0,0.29,0.04,0.42,0.1l0.15,0.77L33.29,35.32z"
    />
    <path
      style={{ fill: '#3E3C3C' }}
      d="M32.9,35.4l-0.18-0.95c0.06-0.02,0.12-0.04,0.19-0.06c0.07-0.01,0.13-0.02,0.2-0.02l0.18,0.95L32.9,35.4z"
    />
    <path
      style={{ fill: '#779C48' }}
      d="M32.51,35.47l-0.15-0.77c0.1-0.11,0.22-0.2,0.35-0.25l0.18,0.95L32.51,35.47z"
    />
    <path
      style={{ fill: '#3E3C3C' }}
      d="M32.12,35.55c-0.06-0.32,0.04-0.63,0.24-0.85l0.15,0.77L32.12,35.55z"
    />
    <path
      style={{ fill: '#4E4742' }}
      d="M50.94,42.16c-0.09,0.21-0.32,0.3-0.53,0.21l-12.3-5.19c-0.21-0.09-0.3-0.32-0.21-0.53l3.23-7.66
	c0.09-0.21,0.32-0.3,0.53-0.21l12.3,5.19c0.21,0.09,0.3,0.32,0.21,0.53L50.94,42.16z"
    />
    <path
      style={{ fill: '#FBF4EF' }}
      d="M44.44,39.34c0,0,0.3,0,0.66,0c0,0,2.42-0.02,4.98,2.69l3.54-8.41c-2.56-2.71-4.98-2.69-4.98-2.69
	c-0.36,0-0.65,0.01-0.66,0c0,0-0.21-0.21-0.46-0.47c0,0-1.67-1.75-5.4-1.69l-3.54,8.41c3.73-0.06,5.4,1.69,5.4,1.69
	C44.24,39.13,44.44,39.34,44.44,39.34z"
    />
    <g>
      <g>
        <rect
          x="41.73"
          y="34.93"
          transform="matrix(0.3885 -0.9215 0.9215 0.3885 -4.0672 64.0741)"
          style={{ fill: '#4E4742' }}
          width="9.03"
          height="0.34"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          style={{ fill: '#4E4742' }}
          d="M46.71,31.33c0.01-0.01,0.03-0.04,0.02-0.03l-0.04,0.04L46.71,31.33z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M46.75,31.23c-0.02,0.02-0.06,0.05-0.09,0.09c0.04-0.04,0.03-0.02,0.08-0.06c0.01,0,0,0.02-0.02,0.04
			c0.01-0.01,0,0.02,0.03-0.01c0.02-0.04,0.01-0.05-0.02-0.03C46.73,31.24,46.74,31.23,46.75,31.23z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M45.92,30.54C45.93,30.54,45.93,30.54,45.92,30.54C45.93,30.54,45.93,30.54,45.92,30.54z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M45.89,30.46C45.89,30.46,45.89,30.46,45.89,30.46C45.89,30.46,45.89,30.45,45.89,30.46z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M46.59,31.28c0,0,0.01,0,0.01-0.01C46.6,31.27,46.6,31.27,46.59,31.28L46.59,31.28z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M46.44,30.83L46.44,30.83c0.01,0,0.01-0.01,0.02-0.02L46.44,30.83z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M46.57,30.9c0,0-0.03,0.03-0.04,0.05c0.01-0.01,0.02-0.02,0.02-0.02C46.55,30.92,46.56,30.92,46.57,30.9z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M46.69,31.11L46.69,31.11C46.69,31.11,46.69,31.11,46.69,31.11z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M46.37,30.77c0-0.01,0.01-0.01,0.01-0.01C46.37,30.76,46.37,30.77,46.37,30.77z"
        />
        <polygon
          style={{ fill: '#4E4742' }}
          points="42.49,29.89 42.5,29.89 42.5,29.86 		"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M44.66,29.98c0,0.04-0.03,0.05-0.03,0.1C44.64,30.06,44.67,30.01,44.66,29.98z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M46.59,31.27C46.59,31.27,46.59,31.27,46.59,31.27C46.59,31.27,46.59,31.27,46.59,31.27z"
        />
        <polygon
          style={{ fill: '#4E4742' }}
          points="44.63,30.08 44.63,30.08 44.63,30.08 		"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M45.51,30.25c0,0.01,0,0.01,0,0.02C45.51,30.27,45.51,30.26,45.51,30.25z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M45.69,30.4L45.69,30.4C45.69,30.4,45.69,30.4,45.69,30.4z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M45.38,30.27C45.38,30.27,45.38,30.26,45.38,30.27C45.37,30.27,45.37,30.27,45.38,30.27
			C45.37,30.27,45.38,30.26,45.38,30.27z"
        />
        <polygon
          style={{ fill: '#4E4742' }}
          points="45.27,30.23 45.26,30.24 45.27,30.24 		"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M43,30.11c0-0.01,0-0.03,0-0.04C42.99,30.1,42.98,30.12,43,30.11z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M46.61,31.25c-0.01,0.01-0.01,0.01-0.02,0.01l0,0C46.6,31.26,46.61,31.26,46.61,31.25z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M45.24,30.62l-0.01-0.01C45.23,30.61,45.23,30.62,45.24,30.62z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M43.81,30.2L43.81,30.2C43.81,30.2,43.81,30.2,43.81,30.2z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M45.17,30.59c0-0.02,0-0.03,0-0.03c0,0,0,0.01-0.01,0.01L45.17,30.59z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M42.87,30.18c0.02,0,0.01-0.06,0.02-0.1c0.02-0.02,0.01,0.05,0.01,0.08c0.01-0.02,0.03-0.07,0.04-0.07
			c0,0.01,0,0.01,0,0.03c0.02-0.05,0.04,0,0.06-0.06c0,0.01,0,0.01,0,0.02c0-0.03,0-0.06,0-0.09c0.01,0,0.02-0.04,0.02,0
			c0,0-0.01,0.01-0.01,0.03L43.01,30c0,0.06-0.01,0.02-0.02,0.06c0.02-0.02,0.04-0.06,0.05-0.05c0,0.06-0.03,0.02-0.02,0.07
			l-0.02-0.02c0.01,0.09-0.01-0.01,0,0.09c0.02,0.02,0.03-0.03,0.05-0.01c0.01-0.03,0-0.05,0.01-0.08c0.04-0.05,0.02,0.15,0.05,0.07
			c0.01-0.04,0.02-0.11,0.03-0.07l-0.01,0.06c0.02,0.01,0.02-0.09,0.04-0.05c-0.01,0.02-0.02,0.02-0.02,0.03
			c0.01,0.05,0.01-0.04,0.03-0.03l0,0.06c0.03-0.01,0.06-0.05,0.09-0.05c0,0.02-0.01,0.03,0,0.05c0.04-0.03,0.08,0.01,0.11,0.05
			l0.02-0.05c0,0,0,0.02,0,0.03c0.01,0.02,0-0.03,0.01-0.05c0.01,0,0.01,0.03,0,0.04c0.03-0.04,0.08-0.08,0.1-0.03l-0.01,0.01
			c0.02,0.02,0.03-0.1,0.05-0.05l0,0.01c0.04,0.03,0.09-0.01,0.11,0.07c0.02-0.07,0.04,0.04,0.05-0.06
			c0.02,0.05,0.01,0.07,0.04,0.11c0.01,0.01,0.02-0.06,0.04-0.07l-0.01,0.05l0.02-0.05l0,0.06l0.01-0.01c0,0-0.01-0.02,0-0.04
			c0.02-0.02,0.03,0.04,0.03,0.07l0.05-0.04c0,0.02-0.01,0.03-0.02,0.04c0.03-0.05-0.01,0.05,0.02,0.01c0-0.01,0.01-0.03,0.01-0.04
			c0,0.03,0.03,0.04,0.04,0.03l-0.01,0.03c0.03,0.01,0.06-0.01,0.09,0c-0.01-0.02,0.01-0.08,0.02-0.08l-0.01,0.05
			c0.01-0.03-0.01-0.02,0.01-0.06c0.01,0.01,0,0.06-0.01,0.08l0.02-0.02c0,0.02-0.01,0.02-0.01,0.04c0.01-0.03,0.01,0.06,0.03,0
			l0,0.02c0.03,0.01,0.05-0.01,0.08-0.01c0.01-0.05,0.02-0.03,0.03-0.07c0.02,0.02,0.03,0.06,0.05,0.06
			c0.02-0.02,0.01-0.04,0.03-0.06c-0.01,0.04,0.02,0.04,0,0.07c0.03,0,0.04,0,0.08-0.03c0-0.02,0-0.03,0-0.04
			c0.01-0.01,0.01,0.02,0,0.04l0,0c0.05-0.05,0.06,0.14,0.11,0.06l0,0l0.04-0.04c0.01,0.01-0.02,0.03-0.01,0.06
			c0.05-0.06,0.05,0.02,0.09-0.02c-0.01,0.13,0.08,0.01,0.09,0.1l0.01-0.03c0.02-0.03,0,0.02,0.01,0.03c0.01-0.02,0-0.03,0.01-0.04
			c0.07-0.02,0.12,0.08,0.17,0.13c0.02,0.01,0.06-0.14,0.07-0.07l-0.03,0.05c0.07-0.05,0.1,0.03,0.16-0.01
			c-0.01,0.01-0.01,0.03-0.02,0.03c0,0.04,0.03,0.01,0.05-0.02c0.01,0.01-0.01,0.03-0.01,0.05c0,0.03,0.04-0.04,0.03,0
			c0,0.01-0.01,0.01-0.01,0.01c0.01-0.02,0.03,0.02,0.04-0.03c0.01,0.03,0.04-0.01,0.04,0.02c0.03-0.05,0.02,0.03,0.05-0.03
			c-0.03,0.07,0.01,0.07,0.03,0.04c-0.01,0.02,0,0.03-0.01,0.05c0.01,0,0.03-0.01,0.04-0.04c-0.01,0.09,0.07-0.05,0.04,0.06
			c0.05-0.02,0.09-0.03,0.13-0.04c-0.03,0.07-0.04-0.01-0.07,0.07c0.04-0.02,0.09-0.07,0.11-0.04c0,0.01-0.02,0.03-0.03,0.04
			c0.04-0.02,0.06,0.03,0.1,0.01c0,0.01-0.01,0.01-0.01,0.02c0.01,0,0.01,0.02,0.02-0.02l-0.01,0.01c0.01-0.05,0.06-0.09,0.08-0.11
			c-0.05,0.09,0.02,0.03-0.05,0.14c0.01,0,0.02-0.02,0.03-0.04c0,0.01,0,0.02-0.02,0.04c0.03-0.01,0.04-0.04,0.05-0.04
			c-0.01,0.02-0.01,0.02-0.02,0.04c0.02-0.03,0.05-0.05,0.05-0.03c-0.01,0.01-0.01,0.02-0.01,0.02c0.03,0.01,0.08-0.06,0.09-0.02
			c-0.04,0.06,0,0.03-0.03,0.08l0.02-0.01l0.01-0.03c0.01,0.01,0.04-0.03,0.05,0l-0.02,0.02l0.03,0l0-0.03
			c0.03-0.02,0.03-0.07,0.04-0.05c-0.01,0,0.01,0.01,0,0.04l0,0c0.02-0.01,0.03-0.03,0.03-0.01c-0.01,0.02-0.03,0.06-0.04,0.07
			c0.03-0.04,0.04,0,0.07-0.04c-0.01,0.01-0.02,0.04-0.03,0.05l0.03-0.03c0,0.02,0,0.02-0.02,0.04c0.01,0.02,0.03-0.07,0.04-0.03
			c0,0-0.01,0.01-0.01,0.01c0.01,0.03,0.05,0,0.06,0.03c-0.03,0.06-0.02,0-0.04,0.05c0.03,0,0.1-0.04,0.12-0.01l0-0.01
			c0.05-0.08,0,0.05,0.03,0.02c-0.01,0-0.03,0.04-0.02,0.03c0.01,0.02,0.05,0,0.09,0l-0.02,0.02c0,0.02,0.02,0.03,0.03,0.04
			c0.02-0.02,0.02-0.01,0.03-0.04c-0.04,0.13,0.1-0.02,0.08,0.1c0,0-0.02,0.01-0.02,0.01l0.04,0c0.02-0.03,0.01-0.02,0.02-0.05
			c0.02-0.02,0.03-0.03,0.04-0.02c-0.03,0.01-0.03,0.05-0.04,0.07c0.01-0.01,0.02-0.02,0.02-0.03c-0.03,0.07,0.05-0.06,0.04-0.01
			l-0.01,0.02c0.01,0,0.03-0.02,0.04-0.01c-0.02,0.04-0.02,0.01-0.04,0.04c0.01,0,0.07-0.06,0.05-0.01c-0.01,0.01,0,0-0.01,0.01
			c-0.02,0.05,0.01,0.04,0.01,0.05v0l-0.01,0.03l0-0.01c0.08-0.09,0.03,0,0.1-0.05c-0.06,0.05-0.03,0.03-0.06,0.07
			c0,0.01-0.01,0.03-0.02,0.04c0.03-0.03-0.01,0.04,0.04,0c-0.01,0.01,0.01,0.01-0.02,0.04c0.02,0,0.01-0.02,0.03-0.04
			c0.05-0.03,0,0.08,0.06,0.04c0.04-0.01,0.08-0.05,0.11-0.06c-0.01,0.03-0.04,0.04-0.04,0.04c-0.01,0.03,0.01,0.03,0.03,0.02
			c-0.01,0.01-0.02,0.02-0.03,0.04c0.01,0,0.04-0.04,0.04-0.02c-0.01,0.01-0.03,0.03-0.05,0.05c0.02,0,0.05-0.02,0.07-0.03
			c-0.02,0.02-0.03,0.03-0.05,0.04c0.02,0,0.04,0,0.08-0.03l0-0.02c0.01,0-0.02,0.03-0.03,0.04c-0.03,0.02,0-0.02,0.01-0.04l0.01,0
			c0.05-0.08-0.04-0.01-0.05-0.02l0.05-0.05l-0.05,0.03c0.01-0.02,0.03-0.05,0.04-0.05c0.01-0.03-0.02,0.01-0.01-0.02
			c0-0.01,0.01-0.01,0.01-0.01l-0.02,0.01c0.01-0.01,0.03-0.04,0.03-0.03c0.01-0.05-0.06,0.02-0.03-0.03l0,0
			c-0.04,0-0.03,0.04-0.09,0.07l0.07-0.11l-0.03,0.04c0,0,0-0.01,0.01-0.03c-0.03,0.02-0.01,0.02-0.04,0.04
			c0.02-0.05-0.05-0.01,0-0.08c-0.03,0.03-0.01,0.03-0.04,0.04c0.05-0.07-0.02,0-0.01-0.04c0.03-0.03,0.03-0.01,0.04-0.05
			c-0.05,0.06-0.01-0.03-0.03-0.03c-0.04,0.05-0.03,0-0.06,0.01c0.01-0.02-0.01-0.05-0.01-0.08l0.01-0.01
			c-0.04,0.05-0.05,0.03-0.07,0.03c0.02-0.03,0.03-0.04,0.05-0.05l-0.01-0.01c-0.01,0.01-0.02,0.02-0.03,0.02l0.03-0.04
			c-0.02-0.01-0.06,0.03-0.06-0.01c-0.02,0.04-0.05,0.11-0.09,0.12c0.01-0.02,0.03-0.05,0.04-0.06c0,0-0.02,0.01-0.01,0l0.03-0.03
			c0-0.02-0.02,0.02-0.03,0.02c0.02-0.03,0.01-0.02,0.02-0.04c0,0,0,0.02,0.01,0.01c0-0.01,0-0.03,0.01-0.06
			c-0.01,0.02-0.02,0.04-0.03,0.04c-0.02-0.01,0.02-0.04,0.02-0.06c-0.02,0.02-0.01-0.01-0.04,0.03c0,0,0.09-0.02,0.1-0.04
			c-0.02,0.01,0.02-0.02,0.02,0.03v0c0,0-0.16-0.07-0.16-0.07h0h0c-0.05,0-0.04-0.02-0.07-0.02l0.01,0c-0.02,0.01-0.04,0-0.07,0.02
			c0,0,0-0.03,0.02-0.04c-0.02,0-0.03,0.02-0.03-0.01c0.03-0.02,0.01-0.02,0.02-0.01c-0.01-0.02,0-0.04-0.04-0.01
			c0.03-0.03,0.01-0.05,0-0.05l0,0.03c-0.01,0-0.02-0.01-0.02-0.01l0.03-0.05c0-0.08-0.11,0.07-0.08-0.04
			c-0.03,0.03,0,0.03-0.03,0.07c-0.01-0.01,0.01-0.09,0.01-0.1c-0.01-0.04-0.06,0.06-0.07,0.03c-0.01,0.02-0.03,0.07-0.05,0.08
			c-0.01-0.01,0.02-0.08,0.04-0.09c0,0,0,0.01,0,0.01c0.01,0,0.02-0.01,0.02-0.03l-0.01,0.02c0-0.02-0.02-0.03,0-0.06
			c-0.02,0.01-0.02,0.03-0.02,0.04c-0.01-0.04-0.03-0.02-0.04-0.04c0,0.01-0.01,0.03-0.01,0.03c-0.02-0.04-0.03,0.03-0.05,0.03
			c0-0.03,0.04-0.06,0.01-0.05c0-0.02,0.02-0.05,0.03-0.06c-0.03,0.03-0.02-0.04-0.03-0.05l0.02-0.01c-0.01,0.01-0.01,0-0.02,0
			l-0.01,0.04c0-0.01-0.01-0.01,0-0.03c-0.02,0.02-0.02,0.06-0.05,0.09c0-0.02-0.03,0.01-0.01-0.04c0.02,0.03,0.02-0.05,0.04-0.05
			c-0.01,0-0.01,0-0.01-0.01c0-0.01,0.01-0.01,0.01-0.01c0-0.05-0.04,0.05-0.06,0.04c0.02-0.03,0.02-0.05,0.01-0.05
			c0.01,0-0.02,0.05-0.03,0.06c-0.01-0.03-0.02,0.01-0.03,0l0,0.01c-0.02,0.03-0.02,0.02-0.03,0.02c0.01-0.02,0.02-0.01,0.02-0.02
			c-0.03,0.04,0-0.06-0.03-0.04c0-0.02,0.02-0.05,0.02-0.07c-0.03,0.03,0-0.05-0.03-0.03c-0.01,0.04-0.01,0.03-0.03,0.06l0.04-0.01
			l-0.04,0.04c0.01,0,0.03-0.01,0.03-0.01c-0.02,0.03-0.02,0.02-0.03,0.05c0.01-0.06-0.04,0.02-0.04-0.02l0.04-0.09
			c0-0.05-0.06,0.03-0.08-0.01l0,0.02c-0.02,0.03-0.02,0-0.03,0.01c0.01-0.03,0.01-0.04,0.02-0.07c-0.01,0.02-0.02-0.01-0.05,0.05
			l0-0.05c-0.02-0.03-0.06,0.08-0.08,0.06c0.02-0.03,0.02-0.06,0.04-0.08c-0.01,0-0.01-0.01-0.02,0.01c0-0.02-0.04,0.03-0.03,0
			c-0.01,0.01-0.02,0.04-0.03,0.04c0-0.01,0.01-0.02,0.01-0.02c-0.01,0.02-0.02,0.02-0.03,0.05c0.01-0.11-0.07-0.02-0.06-0.11
			c-0.02,0-0.04,0.01-0.06,0c0-0.01,0.01-0.02,0.01-0.01c-0.01-0.04-0.04,0.09-0.06,0.04c0.01-0.06,0.02-0.02,0.02-0.04
			c0.03-0.1-0.03,0-0.03-0.06l0.01,0c0-0.04-0.04,0.03-0.06,0.01c0,0,0-0.01,0.01-0.02c-0.03,0.05-0.06,0.06-0.09,0.07
			c0.02-0.04,0.02-0.02,0.03-0.06c-0.01,0-0.02,0.07-0.04,0.07c0-0.02,0-0.05,0.01-0.09l0.02,0c0.01-0.06-0.02,0-0.03-0.02
			l0.01-0.02c-0.03,0-0.05,0.06-0.07,0.05l0,0.02c-0.03,0.06,0-0.04-0.03,0l0.01-0.06c-0.01,0.01-0.05,0.05-0.07,0.06
			c0.02-0.04,0.05-0.06,0.07-0.09c-0.01,0-0.03-0.01-0.05,0.02c0.01,0,0.02-0.02,0.02-0.01c-0.03,0.05-0.05,0.07-0.07,0.06
			c0.04-0.12-0.04-0.05-0.03-0.15c-0.05,0.03-0.06-0.04-0.1-0.04c-0.01,0.1-0.01,0-0.04,0.1c-0.01,0-0.01,0-0.01-0.01l-0.01-0.07
			c0-0.03,0.01-0.02,0.02-0.04c-0.02-0.01,0-0.04,0-0.07c-0.01,0.04-0.02,0.05-0.03,0.03l0,0.05c-0.04,0.05,0-0.09-0.05-0.04
			l0.02-0.03c-0.02,0.01-0.05-0.01-0.09,0.06c0-0.01,0-0.04,0.01-0.05c-0.03-0.02-0.1,0.07-0.11,0.01l0.01-0.02
			c-0.01,0.01-0.02,0.04-0.02,0.02c0-0.01,0.01-0.02,0.01-0.03c-0.01,0.01-0.03,0.04-0.03,0.02l0.01-0.03
			c-0.03-0.04-0.07,0.06-0.1,0.07c0.02-0.07-0.01-0.1-0.02-0.12l0-0.01c-0.04-0.02-0.08,0.05-0.12,0.07
			c-0.01-0.08-0.07-0.05-0.1-0.1c0,0.01-0.01,0.06-0.02,0.06c-0.01-0.02-0.03,0.05-0.02-0.02l0,0c0-0.07-0.02-0.05-0.03-0.06
			l-0.02,0.09c-0.02-0.13-0.1,0.03-0.11-0.09c-0.01,0.02-0.02,0.02-0.04,0.04l0.01-0.05c-0.02-0.02-0.03-0.02-0.07-0.02l0.01-0.03
			c-0.02-0.01-0.04,0.13-0.06,0.13l0-0.07c-0.03,0-0.05-0.07-0.09-0.01c0-0.01,0.01-0.03,0.01-0.03c-0.01,0-0.05-0.05-0.06,0.04
			c0.01-0.06-0.03-0.01-0.05,0.02l0.01-0.05c-0.02,0.06-0.02,0.07-0.04,0.09c0-0.01,0-0.04,0-0.03c-0.01-0.03-0.02,0.04-0.03,0.02
			l0.01-0.03c-0.02-0.01-0.02,0.01-0.03,0.04c-0.01,0.02-0.03-0.01-0.03-0.06c0,0.03-0.02,0.06-0.02,0.05c0-0.02,0-0.03,0.01-0.03
			c-0.02-0.03-0.04,0.05-0.06,0c0-0.02,0-0.02,0-0.03c0.02,0,0.04,0.01,0.04,0.01c0-0.04-0.01-0.05,0.01-0.11
			c-0.02,0.03-0.05,0.1-0.06,0.08c0,0,0-0.01,0-0.02l0,0c0,0-0.01,0-0.01,0.01c0-0.01-0.01-0.01-0.01-0.01
			c0-0.04-0.02-0.02-0.03-0.02c0,0.05-0.01,0.04-0.01,0.08c0,0,0-0.01,0.01-0.01c0,0,0,0,0,0l0,0c0.01,0,0.01,0,0.02,0
			c-0.01,0.02-0.02,0.04-0.02,0.02c-0.01,0.03-0.01,0.06-0.02,0.07c-0.01-0.07-0.02-0.1-0.02-0.15c-0.03,0.02-0.06,0.01-0.08,0.1
			c-0.01,0.01-0.01-0.03-0.01-0.04c0-0.01,0.01-0.01,0.01-0.02c-0.02,0.02-0.04,0.01-0.05,0.07c-0.02-0.03,0.01-0.06-0.01-0.05
			l0-0.02c-0.04-0.1-0.09,0.15-0.14,0.02c0,0.01,0.01,0.01,0.01,0.02c-0.06-0.06-0.13,0.05-0.19-0.04
			c-0.02-0.02-0.02,0.02-0.03,0.05l0-0.05l-0.02,0.06c-0.03,0.04-0.04-0.13-0.08-0.04l0,0.03c-0.01-0.01-0.04,0.02-0.04-0.02
			c0,0.01,0,0.04-0.01,0.02l0-0.03l-0.03,0.05c-0.02-0.02,0-0.11-0.03-0.11c-0.01,0.01-0.04,0.08-0.03,0.1
			c0.01,0,0.01,0.02,0.01,0.04l-0.01,0.01c0,0.08,0,0.16,0.02,0.21l0.02-0.05l0.01,0.04l-0.01,0c0.01,0.04,0.01,0,0.02-0.02
			c0,0.02,0,0.03,0.01,0.04l0.02-0.07c0,0.03-0.01,0.06,0,0.08c0.01-0.03-0.01-0.07,0.01-0.09c0.01,0.04,0.01,0.06,0.02,0.03
			c0,0.02,0,0.03,0,0.04c0.01-0.02,0.02-0.01,0.03-0.05c0.01,0.04,0.03-0.01,0.03,0.06c0-0.02,0.02-0.06,0.03-0.06
			c-0.02,0.09,0.03,0.01,0.03,0.1c0.01-0.08,0.04-0.04,0.06-0.08c0,0.01,0,0.02-0.01,0.01c0.01,0.01,0.01,0.03,0.02,0.05
			c0-0.07,0.02-0.08,0.03-0.12c0.01,0.06-0.01,0.08-0.01,0.14c0-0.05,0.03-0.05,0.02-0.1c0.01,0.04,0.01,0.02,0.01,0.07
			c0.01-0.08,0.01-0.01,0.02-0.06c0.01,0.04,0,0.08,0.02,0.05C42.88,30.15,42.87,30.18,42.87,30.18z M43.28,30.06L43.28,30.06
			C43.28,30.06,43.27,30.07,43.28,30.06C43.27,30.06,43.28,30.06,43.28,30.06z M43.31,30.07c0,0-0.01,0-0.01,0
			c0-0.02,0-0.03,0.01-0.03C43.3,30.04,43.31,30.05,43.31,30.07z M43.27,29.73c0.01-0.01,0.01,0,0.01,0.01c0,0-0.01,0-0.01,0l0,0.01
			C43.27,29.75,43.27,29.74,43.27,29.73z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M43.26,29.79C43.26,29.79,43.26,29.8,43.26,29.79c0,0.01,0,0.01,0,0.01L43.26,29.79L43.26,29.79z"
        />
        <polygon
          style={{ fill: '#4E4742' }}
          points="46.27,30.78 46.27,30.78 46.26,30.79 		"
        />
        <polygon
          style={{ fill: '#4E4742' }}
          points="43.77,30.21 43.77,30.2 43.77,30.21 		"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M46.34,31.02c0,0,0,0.01,0,0.01C46.34,31.03,46.34,31.03,46.34,31.02z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M46.44,31.13c0.01-0.01,0.01-0.01,0.01-0.02l0,0L46.44,31.13z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M45.56,30.69C45.56,30.69,45.56,30.69,45.56,30.69C45.55,30.7,45.55,30.7,45.56,30.69z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M45.87,30.72c-0.01,0.01-0.01,0.01-0.02,0.02C45.86,30.74,45.86,30.74,45.87,30.72z"
        />
        <polygon
          style={{ fill: '#4E4742' }}
          points="45.24,30.62 45.24,30.62 45.24,30.62 		"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M45.84,30.73c0,0.01,0.01,0.01,0.01,0.01C45.85,30.73,45.85,30.72,45.84,30.73z"
        />
        <polygon
          style={{ fill: '#4E4742' }}
          points="46.63,31.31 46.63,31.32 46.64,31.31 		"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M46.64,31.31L46.64,31.31C46.64,31.31,46.64,31.31,46.64,31.31z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M46.66,31.3l-0.01,0C46.65,31.31,46.65,31.31,46.66,31.3z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M46.58,31.25l-0.01,0.01C46.57,31.26,46.58,31.25,46.58,31.25z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M46.56,31.23c0,0.01-0.01,0.04,0,0.03l0.01-0.01C46.55,31.28,46.58,31.21,46.56,31.23z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M46.61,31l0.01,0.02c0.01-0.02,0.02-0.04,0.03-0.04C46.66,30.93,46.62,31.02,46.61,31z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M46.57,30.94c0.01,0,0.02-0.02,0.02-0.03C46.57,30.92,46.57,30.92,46.57,30.94z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M46.16,30.67l0.02-0.04C46.17,30.63,46.17,30.65,46.16,30.67z"
        />
        <polygon
          style={{ fill: '#4E4742' }}
          points="45.97,30.82 45.96,30.85 45.98,30.81 		"
        />
        <polygon
          style={{ fill: '#4E4742' }}
          points="46.07,30.52 46.07,30.52 46.05,30.53 		"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M45.3,30.2l-0.02,0.05C45.29,30.24,45.32,30.19,45.3,30.2z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M44.95,30.14c0-0.03,0-0.05-0.01-0.02C44.94,30.12,44.94,30.14,44.95,30.14z"
        />
        <polygon
          style={{ fill: '#4E4742' }}
          points="43.93,29.81 43.94,29.79 43.92,29.81 		"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          style={{ fill: '#4E4742' }}
          d="M46.38,32.73c0.01-0.01,0.03-0.03,0.02-0.03l-0.04,0.04L46.38,32.73z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M46.41,32.62c-0.02,0.02-0.06,0.05-0.09,0.09c0.04-0.04,0.03-0.02,0.08-0.06c0.01,0,0,0.02-0.02,0.04
			c0.01-0.01,0,0.02,0.03-0.01c0.02-0.04,0.01-0.05-0.02-0.03C46.39,32.64,46.4,32.63,46.41,32.62z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M45.87,32.2L45.87,32.2C45.87,32.21,45.87,32.2,45.87,32.2z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M45.49,32.01C45.5,32.01,45.5,32.01,45.49,32.01C45.5,32,45.49,32.01,45.49,32.01z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M45.45,31.93C45.45,31.93,45.45,31.93,45.45,31.93C45.45,31.93,45.45,31.92,45.45,31.93z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M45.87,32.2l0-0.02C45.86,32.2,45.87,32.2,45.87,32.2z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M46.25,32.67c0,0,0.01,0,0.01-0.01C46.26,32.67,46.26,32.67,46.25,32.67L46.25,32.67z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M46.06,32.24L46.06,32.24c0.01,0,0.01-0.01,0.02-0.02L46.06,32.24z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M46.19,32.31c0,0-0.03,0.04-0.04,0.05c0.01-0.02,0.02-0.02,0.02-0.02C46.18,32.33,46.19,32.32,46.19,32.31z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M46.34,32.5L46.34,32.5C46.35,32.5,46.35,32.5,46.34,32.5z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M45.97,32.19c0-0.01,0-0.01,0.01-0.02C45.97,32.18,45.97,32.18,45.97,32.19z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M42.08,31.46C42.08,31.46,42.08,31.45,42.08,31.46C42.08,31.46,42.08,31.46,42.08,31.46z"
        />
        <polygon
          style={{ fill: '#4E4742' }}
          points="41.96,31.2 41.97,31.2 41.97,31.17 		"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M44.17,31.5c0,0.04-0.04,0.05-0.03,0.1C44.15,31.58,44.18,31.53,44.17,31.5z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M46.26,32.67C46.25,32.67,46.25,32.67,46.26,32.67C46.25,32.67,46.25,32.67,46.26,32.67z"
        />
        <polygon
          style={{ fill: '#4E4742' }}
          points="44.14,31.6 44.14,31.6 44.14,31.6 		"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M45.04,31.75c0,0.01,0,0.01,0,0.02C45.04,31.77,45.04,31.76,45.04,31.75z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M45.24,31.89L45.24,31.89C45.23,31.89,45.24,31.89,45.24,31.89z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M44.91,31.78C44.91,31.78,44.91,31.77,44.91,31.78C44.9,31.78,44.9,31.78,44.91,31.78
			C44.91,31.77,44.91,31.77,44.91,31.78z"
        />
        <polygon
          style={{ fill: '#4E4742' }}
          points="44.79,31.74 44.79,31.75 44.8,31.75 		"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M42.44,31.51c0-0.01,0-0.03,0-0.04C42.44,31.5,42.42,31.51,42.44,31.51z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M46.27,32.65c-0.01,0.01-0.01,0.01-0.01,0.01l0,0C46.26,32.66,46.27,32.66,46.27,32.65z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M44.79,32.13l-0.02-0.01C44.78,32.13,44.79,32.13,44.79,32.13z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M43.29,31.68L43.29,31.68C43.28,31.69,43.28,31.69,43.29,31.68z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M44.72,32.11c0-0.02,0-0.03,0-0.03c0,0,0,0.01-0.01,0.01L44.72,32.11z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M42.3,31.55c0.02,0,0.02-0.06,0.03-0.09c0.02-0.01,0,0.05,0,0.08c0.02-0.01,0.04-0.07,0.05-0.06
			c0,0.01,0,0.01,0,0.03c0.02-0.05,0.04,0.01,0.07-0.05c0,0.01,0,0.01,0,0.02c0-0.03,0.01-0.06,0.01-0.08c0.01,0,0.02-0.04,0.02,0
			c0,0-0.01,0.01-0.01,0.03l0.01-0.02c0,0.06-0.02,0.02-0.02,0.06c0.02-0.02,0.05-0.06,0.06-0.04c-0.01,0.06-0.03,0.01-0.03,0.07
			l-0.02-0.03c0,0.09-0.01-0.01-0.02,0.09c0.02,0.02,0.03-0.02,0.06,0c0.01-0.03,0.01-0.05,0.02-0.08c0.05-0.05,0,0.15,0.04,0.08
			c0.01-0.04,0.03-0.11,0.04-0.06l-0.02,0.06c0.02,0.01,0.03-0.09,0.05-0.04c-0.01,0.02-0.02,0.02-0.02,0.03
			c0.01,0.05,0.02-0.03,0.03-0.03l-0.01,0.06c0.03,0,0.07-0.04,0.1-0.04c-0.01,0.02-0.01,0.03-0.01,0.05
			c0.04-0.02,0.08,0.02,0.1,0.06l0.02-0.05c0,0,0,0.02,0,0.03c0.01,0.02,0.01-0.03,0.02-0.05c0.01,0,0,0.03,0,0.04
			c0.03-0.04,0.09-0.07,0.1-0.02l-0.01,0.01c0.02,0.02,0.04-0.09,0.05-0.04l-0.01,0.01c0.04,0.03,0.1,0,0.11,0.08
			c0.02-0.07,0.04,0.04,0.06-0.06c0.01,0.05,0,0.07,0.03,0.11c0.01,0.01,0.03-0.06,0.04-0.06l-0.01,0.05l0.03-0.05l-0.01,0.06
			l0.01-0.01c0,0,0-0.02,0-0.04c0.02-0.02,0.03,0.04,0.03,0.07l0.05-0.03c-0.01,0.02-0.01,0.03-0.02,0.04
			c0.03-0.05-0.01,0.05,0.02,0.01c0-0.01,0.01-0.03,0.01-0.04c0,0.03,0.02,0.04,0.04,0.03l-0.01,0.03c0.03,0.02,0.07,0,0.1,0.01
			c-0.01-0.02,0.01-0.08,0.02-0.08l-0.01,0.05c0.01-0.03-0.01-0.02,0.02-0.06c0.01,0.01-0.01,0.06-0.02,0.08l0.02-0.01
			c0,0.02-0.01,0.02-0.01,0.04c0.01-0.03,0.01,0.06,0.04,0l0,0.02c0.03,0.01,0.05-0.01,0.08,0c0.01-0.05,0.02-0.03,0.04-0.07
			c0.02,0.02,0.03,0.06,0.05,0.06c0.02-0.02,0.01-0.04,0.03-0.06c-0.01,0.04,0.02,0.04,0,0.07c0.03,0,0.04,0,0.08-0.02
			c0-0.02,0-0.03,0-0.04c0.01-0.01,0.01,0.02,0,0.04l0,0c0.06-0.05,0.06,0.15,0.12,0.07l0,0l0.05-0.04
			c0.01,0.01-0.02,0.03-0.01,0.06c0.05-0.06,0.05,0.02,0.1-0.01c-0.01,0.13,0.08,0.01,0.09,0.1l0.01-0.03
			c0.02-0.03,0.01,0.02,0.01,0.03c0.01-0.02,0-0.03,0.01-0.04c0.08-0.02,0.13,0.08,0.19,0.13c0.02,0.01,0.06-0.14,0.07-0.07
			l-0.02,0.05c0.07-0.05,0.1,0.03,0.17-0.01c-0.01,0.01-0.01,0.03-0.02,0.03c0,0.04,0.04,0.01,0.05-0.02
			c0.01,0.01-0.01,0.03-0.01,0.05c0,0.03,0.04-0.04,0.03,0c0,0.01-0.01,0.01-0.01,0.01c0.01-0.02,0.03,0.01,0.04-0.03
			c0.01,0.03,0.04-0.01,0.05,0.02c0.03-0.05,0.02,0.03,0.05-0.04c-0.02,0.07,0.01,0.07,0.04,0.04c-0.01,0.02,0,0.03-0.01,0.05
			c0.01,0,0.03-0.01,0.04-0.04c0,0.09,0.07-0.05,0.05,0.06c0.05-0.03,0.1-0.04,0.14-0.05c-0.03,0.07-0.04-0.01-0.06,0.07
			c0.04-0.02,0.08-0.07,0.11-0.04c0.01,0.01-0.02,0.03-0.02,0.04c0.04-0.02,0.06,0.03,0.1,0c0,0.01-0.01,0.01-0.01,0.02
			c0.01,0,0.01,0.02,0.02-0.02l-0.01,0.01c0.01-0.05,0.06-0.09,0.07-0.12c-0.04,0.09,0.02,0.03-0.04,0.15
			c0.01,0,0.02-0.02,0.02-0.04c0,0.01,0,0.02-0.01,0.04c0.03-0.01,0.03-0.05,0.05-0.04c-0.01,0.02-0.01,0.02-0.02,0.04
			c0.02-0.03,0.04-0.05,0.05-0.04c0,0.01-0.01,0.02-0.01,0.02c0.03,0.01,0.08-0.07,0.1-0.02c-0.03,0.06,0,0.03-0.02,0.09l0.02-0.01
			l0-0.03c0.01,0.01,0.04-0.03,0.05-0.01l-0.02,0.02l0.03,0l-0.01-0.03c0.03-0.02,0.03-0.07,0.04-0.05c-0.01,0,0.01,0.01,0.01,0.04
			l0,0c0.01-0.02,0.03-0.03,0.03-0.01c0,0.02-0.03,0.06-0.03,0.08c0.03-0.05,0.04,0,0.07-0.05c-0.01,0.01-0.02,0.04-0.02,0.05
			l0.02-0.04c0,0.02,0,0.02-0.01,0.05c0.02,0.01,0.02-0.08,0.03-0.04c0,0,0,0.01-0.01,0.01c0.02,0.03,0.06-0.01,0.07,0.02
			c-0.02,0.06-0.02,0.01-0.04,0.06c0.03,0,0.09-0.05,0.12-0.02l0-0.01c0.04-0.08,0,0.05,0.04,0.02c-0.01,0-0.02,0.04-0.02,0.03
			c0.01,0.02,0.05,0,0.09-0.01l-0.01,0.02c0,0.02,0.03,0.02,0.03,0.04c0.01-0.02,0.02-0.01,0.03-0.04
			c-0.02,0.14,0.11-0.03,0.09,0.09c0,0-0.02,0.01-0.02,0.01l0.04,0c0.02-0.04,0.01-0.02,0.01-0.05c0.01-0.02,0.03-0.03,0.04-0.03
			c-0.02,0.02-0.03,0.06-0.03,0.08c0-0.01,0.01-0.02,0.02-0.03c-0.02,0.07,0.04-0.07,0.04-0.02l-0.01,0.02
			c0.01,0,0.03-0.02,0.04-0.01c-0.02,0.04-0.02,0.01-0.04,0.05c0.01,0,0.06-0.06,0.05-0.02c-0.01,0.01,0.01,0,0,0.01
			c-0.01,0.05,0.03,0.04,0.03,0.05v0l-0.03,0.03l-0.01-0.02c0.07-0.09,0.03,0,0.09-0.06c-0.06,0.05-0.03,0.03-0.06,0.08
			c0,0.01-0.01,0.03-0.01,0.04c0.03-0.03,0,0.04,0.04-0.01c-0.01,0.01,0.01,0.01-0.02,0.04c0.02,0,0.01-0.03,0.02-0.04
			c0.05-0.04,0.01,0.08,0.07,0.03c0.04-0.01,0.07-0.05,0.11-0.06c-0.01,0.03-0.04,0.04-0.04,0.04c0,0.03,0.01,0.03,0.03,0.02
			c-0.01,0.01-0.02,0.02-0.02,0.04c0.01,0,0.04-0.04,0.04-0.02c-0.01,0.01-0.03,0.03-0.05,0.05c0.02-0.01,0.05-0.02,0.06-0.03
			c-0.02,0.02-0.03,0.03-0.05,0.04c0.02,0,0.04,0,0.07-0.03l0-0.02c0.01,0-0.02,0.03-0.03,0.04c-0.03,0.02,0-0.02,0.01-0.04l0.01,0
			c0.05-0.08-0.04-0.01-0.05-0.02l0.05-0.05l-0.05,0.04c0.01-0.02,0.03-0.05,0.04-0.05c0.01-0.04-0.02,0.01-0.01-0.02
			c0-0.01,0-0.01,0.01-0.01l-0.02,0.01c0.01-0.01,0.03-0.04,0.03-0.03c0-0.05-0.06,0.02-0.04-0.03l0,0c-0.04,0-0.03,0.04-0.08,0.08
			l0.06-0.11l-0.03,0.04c0,0-0.01-0.01,0.01-0.03c-0.03,0.02-0.01,0.02-0.03,0.04c0.01-0.05-0.05,0-0.01-0.07
			c-0.03,0.03,0,0.03-0.03,0.05c0.04-0.08-0.02,0-0.02-0.04c0.03-0.04,0.03-0.01,0.03-0.05c-0.05,0.06-0.02-0.03-0.03-0.02
			c-0.03,0.05-0.04,0.01-0.06,0.02c0.01-0.02-0.02-0.05-0.02-0.08l0.01-0.01c-0.03,0.05-0.04,0.03-0.06,0.04
			c0.02-0.03,0.03-0.04,0.04-0.06l-0.01-0.01c-0.01,0.02-0.02,0.02-0.03,0.03l0.02-0.04c-0.02-0.01-0.06,0.03-0.06,0
			c-0.02,0.04-0.04,0.12-0.07,0.13c0.01-0.02,0.03-0.06,0.04-0.06c0,0-0.02,0.01-0.01,0l0.03-0.03c0-0.02-0.02,0.03-0.03,0.02
			c0.01-0.03,0-0.02,0.02-0.05c0,0,0,0.02,0.01,0c-0.01-0.01-0.01-0.03,0-0.06c-0.01,0.02-0.02,0.04-0.03,0.04
			c-0.02-0.01,0.01-0.04,0.01-0.06c-0.01,0.02-0.01-0.01-0.04,0.04c0,0,0-0.02,0.01-0.04c-0.02,0.02-0.04-0.01-0.05,0.04l0,0
			c0,0,0,0,0,0l0,0l0,0c-0.05,0.03-0.04-0.05-0.08-0.04l0.01-0.02c-0.02,0.01-0.04-0.01-0.07,0.02c0,0,0-0.03,0.01-0.05
			c-0.02,0.01-0.03,0.02-0.03-0.01c0.02-0.02,0.01-0.03,0.02-0.01c-0.01-0.02,0.04-0.05,0.01-0.01c0.02-0.04,0.04-0.05,0.04-0.05
			v0.03c0,0-0.07,0-0.07-0.01l0-0.05c-0.01-0.08-0.12,0.08-0.1-0.03c-0.02,0.03,0,0.03-0.02,0.07c-0.01-0.01,0-0.09-0.01-0.1
			c-0.01-0.04-0.05,0.07-0.07,0.04c0,0.02-0.02,0.07-0.04,0.08c-0.01-0.01,0.01-0.09,0.03-0.1c0,0,0,0.01,0,0.01
			c0.01,0,0.02-0.01,0.02-0.03l-0.01,0.02c0-0.02-0.02-0.03-0.01-0.06c-0.02,0.01-0.02,0.03-0.01,0.04
			c-0.01-0.03-0.03-0.01-0.04-0.04c0,0.01-0.01,0.03-0.01,0.03c-0.02-0.03-0.03,0.03-0.05,0.03c0-0.03,0.03-0.07,0-0.05
			c-0.01-0.02,0.02-0.05,0.03-0.06c-0.03,0.03-0.03-0.04-0.03-0.05l0.02-0.01c-0.01,0.01-0.01,0-0.02,0l-0.01,0.04
			c0-0.01-0.01-0.01,0-0.03c-0.01,0.02-0.02,0.06-0.04,0.09c0-0.02-0.03,0.01-0.02-0.04c0.02,0.03,0.02-0.05,0.04-0.05
			c-0.01,0-0.01,0-0.01-0.01c0-0.01,0-0.01,0.01-0.01c-0.01-0.05-0.03,0.05-0.05,0.04c0.01-0.03,0.01-0.06,0.01-0.05
			c0.01,0-0.01,0.05-0.03,0.07c-0.01-0.03-0.02,0.01-0.03,0.01l0.01,0.01c-0.01,0.03-0.02,0.02-0.03,0.03
			c0.01-0.02,0.02-0.01,0.01-0.02c-0.03,0.05-0.01-0.06-0.04-0.03c0-0.02,0.02-0.05,0.02-0.07c-0.03,0.03-0.01-0.05-0.04-0.02
			c-0.01,0.04,0,0.03-0.03,0.06l0.04-0.02l-0.04,0.05c0.01,0,0.03-0.02,0.03-0.01c-0.02,0.03-0.02,0.02-0.02,0.05
			c0.01-0.06-0.04,0.02-0.04-0.02l0.03-0.09c-0.01-0.05-0.06,0.03-0.08,0l0,0.02c-0.02,0.04-0.02,0-0.04,0.01
			c0.01-0.03-0.08-0.04-0.08-0.07c-0.01,0.02-0.14-0.01-0.14,0.05v-0.05c0-0.02,0.03,0.08,0.01,0.06c0.02-0.03,0.06-0.06,0.08-0.08
			c-0.01,0,0.01-0.01,0,0.01c0-0.02-0.02,0.04-0.02,0c-0.01,0.01-0.01,0.05-0.02,0.04c0-0.01,0.01-0.02,0.01-0.02
			c0,0.02-0.02,0.02-0.02,0.05c0.01-0.11-0.07-0.01-0.06-0.11c-0.02,0.01-0.04,0.01-0.06,0.01c0-0.01,0.01-0.02,0.01-0.01
			c-0.01-0.04-0.04,0.09-0.05,0.05c0-0.06,0.02-0.02,0.02-0.05c0.03-0.11-0.03,0-0.03-0.06l0.01-0.01c0-0.04-0.04,0.03-0.06,0.02
			c0,0,0-0.01,0-0.02c-0.03,0.05-0.06,0.06-0.09,0.07c0.02-0.04,0.02-0.02,0.02-0.06c-0.01,0-0.02,0.07-0.04,0.07
			c0-0.02-0.01-0.05,0-0.09l0.02,0c0.01-0.06-0.02,0-0.03-0.02l0.01-0.02c-0.03,0-0.05,0.06-0.07,0.05l0,0.02
			c-0.03,0.06,0-0.04-0.03,0l0.01-0.07c-0.01,0.01-0.05,0.05-0.07,0.06c0.02-0.04,0.05-0.06,0.07-0.09c-0.01,0-0.03,0-0.05,0.03
			c0.01-0.01,0.02-0.02,0.02-0.01c-0.03,0.05-0.05,0.07-0.07,0.06c0.04-0.12-0.04-0.05-0.03-0.15c-0.05,0.04-0.06-0.04-0.11-0.04
			c-0.01,0.1-0.01,0-0.04,0.1c-0.01,0-0.01,0-0.01-0.01l-0.01-0.07c0-0.03,0.01-0.02,0.02-0.04c-0.02-0.01,0-0.04,0-0.07
			c-0.01,0.04-0.02,0.05-0.03,0.03l0,0.05c-0.04,0.05,0-0.09-0.05-0.05l0.02-0.03c-0.02,0.01-0.06-0.02-0.09,0.06
			c0-0.01,0-0.04,0.01-0.05c-0.03-0.02-0.1,0.06-0.12,0.01l0.01-0.02c-0.01,0.01-0.02,0.04-0.03,0.02c0-0.01,0.01-0.02,0.01-0.03
			c-0.01,0.01-0.03,0.04-0.03,0.02l0.01-0.03c-0.03-0.04-0.07,0.05-0.1,0.06c0.02-0.07,0-0.1-0.01-0.12l0-0.01
			c-0.04-0.02-0.08,0.04-0.13,0.06c0-0.08-0.07-0.05-0.1-0.11c0,0.01-0.01,0.06-0.02,0.06c-0.01-0.02-0.04,0.04-0.02-0.02l0,0
			c0.01-0.07-0.01-0.05-0.03-0.06l-0.02,0.09c-0.01-0.13-0.11,0.02-0.1-0.09c-0.01,0.02-0.03,0.02-0.04,0.04l0.01-0.05
			c-0.02-0.02-0.03-0.03-0.07-0.02l0.01-0.03c-0.02-0.01-0.05,0.13-0.07,0.13l0.01-0.07c-0.03-0.01-0.05-0.07-0.09-0.02
			c0-0.01,0.01-0.03,0.01-0.03c-0.01,0-0.05-0.06-0.06,0.04c0.01-0.06-0.03-0.01-0.05,0.01l0.01-0.05
			c-0.03,0.06-0.03,0.07-0.05,0.08c0-0.01,0-0.04,0.01-0.02c-0.01-0.03-0.02,0.04-0.03,0.01l0.01-0.03
			c-0.01-0.01-0.02,0.01-0.03,0.04c-0.01,0.02-0.03-0.02-0.02-0.06c-0.01,0.02-0.02,0.06-0.03,0.04c0-0.02,0.01-0.02,0.01-0.03
			c-0.02-0.04-0.05,0.04-0.06,0c0-0.02,0-0.02,0-0.03c0.02,0.01,0.04,0.01,0.03,0.01c0.01-0.04-0.01-0.05,0.02-0.11
			c-0.02,0.03-0.06,0.09-0.07,0.07c0,0,0-0.01,0-0.02l0,0c0-0.01-0.01,0-0.01,0c0-0.01-0.01-0.01-0.01-0.02
			c0.01-0.04-0.02-0.02-0.02-0.03c-0.01,0.05-0.02,0.04-0.02,0.08c0,0,0-0.01,0.01-0.01c0,0,0,0,0,0l0,0c0.01,0,0.01,0,0.02,0
			c-0.01,0.02-0.02,0.04-0.03,0.01c-0.01,0.03-0.02,0.06-0.03,0.07c0-0.07-0.01-0.11-0.01-0.15c-0.03,0.02-0.06,0-0.1,0.09
			c-0.01,0.01,0-0.03,0-0.04c0.01-0.01,0.01-0.01,0.02-0.02c-0.02,0.02-0.05,0-0.06,0.06c-0.01-0.03,0.02-0.05-0.01-0.05l0.01-0.02
			c-0.03-0.11-0.11,0.14-0.14,0c0,0.01,0,0.01,0,0.02c-0.05-0.07-0.14,0.03-0.18-0.07c-0.01-0.02-0.02,0.02-0.04,0.05l0.01-0.05
			l-0.03,0.05c-0.03,0.04-0.02-0.13-0.07-0.06l0,0.03c-0.01-0.01-0.04,0.01-0.03-0.03c0,0.01-0.01,0.03-0.01,0.02l0-0.03l-0.04,0.04
			c-0.02-0.02,0.02-0.1-0.01-0.11c-0.01,0.01-0.05,0.07-0.05,0.09c0,0,0.01,0.02,0.01,0.04l-0.01,0.01
			c-0.02,0.08-0.04,0.15-0.02,0.21l0.03-0.04l0,0.04l-0.02,0c0,0.04,0.01,0.01,0.02-0.01c0,0.02,0,0.03,0,0.04l0.03-0.07
			c0,0.03-0.02,0.06-0.01,0.08c0.01-0.03,0-0.07,0.02-0.09c0,0.04-0.01,0.06,0.01,0.04c0,0.02-0.01,0.03-0.01,0.04
			c0.01-0.02,0.03-0.01,0.04-0.05c0,0.04,0.03-0.01,0.02,0.07c0.01-0.02,0.03-0.05,0.04-0.06c-0.03,0.08,0.03,0.01,0.01,0.1
			c0.03-0.08,0.05-0.03,0.08-0.07c0,0.01-0.01,0.02-0.01,0.01c0.01,0.01,0.01,0.03,0.01,0.05c0.01-0.06,0.04-0.08,0.05-0.11
			c0,0.07-0.02,0.07-0.03,0.14c0.01-0.05,0.04-0.05,0.04-0.09c0,0.04,0.01,0.02,0,0.08c0.02-0.08,0.01-0.01,0.03-0.06
			c0.01,0.04-0.01,0.08,0.01,0.06C42.32,31.52,42.3,31.55,42.3,31.55z M42.75,31.49L42.75,31.49C42.74,31.5,42.74,31.5,42.75,31.49
			C42.74,31.5,42.74,31.5,42.75,31.49z M42.78,31.5c0,0-0.01,0-0.01,0c0-0.02,0.01-0.03,0.01-0.03
			C42.77,31.48,42.78,31.49,42.78,31.5z M42.77,31.16c0.01-0.01,0.01,0,0.01,0.01c0,0-0.01,0-0.01,0l0,0.01
			C42.77,31.18,42.77,31.17,42.77,31.16z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M42.75,31.22C42.75,31.22,42.75,31.23,42.75,31.22c0,0.01,0,0.01,0,0.01L42.75,31.22L42.75,31.22z"
        />
        <polygon
          style={{ fill: '#4E4742' }}
          points="45.87,32.21 45.87,32.21 45.87,32.22 		"
        />
        <polygon
          style={{ fill: '#4E4742' }}
          points="43.24,31.7 43.25,31.68 43.24,31.69 		"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M45.98,32.44c0,0,0,0.01,0,0.01C45.98,32.45,45.98,32.45,45.98,32.44z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M46.04,32.49l0,0.02C46.05,32.5,46.05,32.5,46.04,32.49z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M46.09,32.54c0-0.01,0.01-0.01,0.01-0.02l0,0L46.09,32.54z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M45.14,32.19C45.13,32.19,45.13,32.19,45.14,32.19C45.12,32.2,45.13,32.2,45.14,32.19z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M45.46,32.19c-0.01,0.01-0.01,0.01-0.02,0.02C45.45,32.21,45.45,32.22,45.46,32.19z"
        />
        <polygon
          style={{ fill: '#4E4742' }}
          points="44.79,32.14 44.79,32.13 44.79,32.13 		"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M45.43,32.2c0,0.01,0.01,0.01,0.01,0.01C45.44,32.2,45.44,32.2,45.43,32.2z"
        />
        <polygon
          style={{ fill: '#4E4742' }}
          points="46.29,32.71 46.3,32.71 46.3,32.71 		"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M46.3,32.71L46.3,32.71C46.31,32.7,46.3,32.7,46.3,32.71z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M46.32,32.7l-0.01,0.01C46.31,32.71,46.31,32.71,46.32,32.7z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M46.24,32.64l-0.01,0.01C46.24,32.66,46.24,32.65,46.24,32.64z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M46.22,32.63c0,0.01-0.01,0.04,0,0.03l0.01-0.01C46.22,32.68,46.24,32.61,46.22,32.63z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M46.25,32.4l0.01,0.02c0.01-0.02,0.01-0.04,0.03-0.04C46.29,32.32,46.27,32.42,46.25,32.4z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M46.2,32.34c0.02,0,0.01-0.02,0.02-0.03C46.2,32.32,46.2,32.32,46.2,32.34z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M45.74,32.11l0.02-0.04C45.75,32.07,45.76,32.09,45.74,32.11z"
        />
        <polygon
          style={{ fill: '#4E4742' }}
          points="45.57,32.28 45.57,32.31 45.59,32.27 		"
        />
        <polygon
          style={{ fill: '#4E4742' }}
          points="45.63,31.98 45.64,31.97 45.62,31.98 		"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M44.83,31.71l-0.01,0.05C44.82,31.75,44.84,31.7,44.83,31.71z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M44.46,31.66c0-0.03,0-0.05-0.01-0.02C44.46,31.64,44.46,31.66,44.46,31.66z"
        />
        <polygon
          style={{ fill: '#4E4742' }}
          points="43.43,31.3 43.44,31.29 43.43,31.3 		"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          style={{ fill: '#4E4742' }}
          d="M45.43,34.32c0.01-0.01,0.03-0.04,0.02-0.03l-0.04,0.04L45.43,34.32z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M45.45,34.22c-0.02,0.02-0.06,0.05-0.09,0.09c0.04-0.04,0.03-0.02,0.07-0.06c0.01,0,0,0.02-0.02,0.04
			c0.01-0.01,0,0.02,0.03-0.01c0.02-0.04,0.01-0.05-0.02-0.02C45.44,34.23,45.45,34.22,45.45,34.22z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M44.92,33.81L44.92,33.81C44.92,33.81,44.92,33.81,44.92,33.81z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M44.55,33.62C44.55,33.62,44.56,33.62,44.55,33.62C44.55,33.62,44.55,33.62,44.55,33.62z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M44.5,33.54C44.51,33.54,44.51,33.54,44.5,33.54C44.51,33.54,44.51,33.54,44.5,33.54z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M44.92,33.81l0-0.02C44.91,33.81,44.92,33.8,44.92,33.81z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M45.3,34.27c0,0,0.01,0,0.01-0.01C45.31,34.27,45.31,34.27,45.3,34.27L45.3,34.27z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M45.11,33.85L45.11,33.85c0.01,0,0.01-0.01,0.02-0.02L45.11,33.85z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M45.24,33.91c0,0-0.03,0.04-0.04,0.05c0.01-0.02,0.02-0.02,0.02-0.02C45.23,33.93,45.23,33.92,45.24,33.91z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M45.39,34.1L45.39,34.1C45.39,34.1,45.39,34.1,45.39,34.1z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M45.02,33.79c0-0.01,0-0.01,0.01-0.02C45.02,33.78,45.02,33.79,45.02,33.79z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M41.19,33.18C41.19,33.17,41.19,33.17,41.19,33.18C41.19,33.17,41.19,33.18,41.19,33.18z"
        />
        <polygon
          style={{ fill: '#4E4742' }}
          points="41.07,32.92 41.07,32.92 41.07,32.89 		"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M43.24,33.14c0,0.04-0.03,0.05-0.03,0.1C43.22,33.22,43.25,33.17,43.24,33.14z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M45.3,34.27C45.3,34.27,45.3,34.27,45.3,34.27C45.3,34.27,45.3,34.27,45.3,34.27z"
        />
        <polygon
          style={{ fill: '#4E4742' }}
          points="43.22,33.24 43.22,33.24 43.22,33.25 		"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M44.11,33.37c0,0.01,0,0.01,0,0.02C44.1,33.39,44.1,33.38,44.11,33.37z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M44.3,33.5L44.3,33.5C44.29,33.5,44.3,33.5,44.3,33.5z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M43.98,33.4C43.97,33.4,43.97,33.39,43.98,33.4C43.97,33.4,43.97,33.4,43.98,33.4
			C43.97,33.4,43.97,33.39,43.98,33.4z"
        />
        <polygon
          style={{ fill: '#4E4742' }}
          points="43.86,33.37 43.86,33.38 43.86,33.38 		"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M41.55,33.21c0-0.01,0-0.03,0-0.04C41.55,33.2,41.53,33.21,41.55,33.21z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M45.32,34.25c-0.01,0.01-0.01,0.01-0.01,0.01l0,0C45.31,34.26,45.32,34.26,45.32,34.25z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M43.86,33.76l-0.02-0.01C43.85,33.75,43.86,33.76,43.86,33.76z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M42.38,33.35L42.38,33.35C42.38,33.36,42.38,33.36,42.38,33.35z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M43.79,33.74c0-0.02,0-0.03,0-0.03c0,0,0,0.01-0.01,0.01L43.79,33.74z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M41.41,33.26c0.02,0,0.02-0.06,0.03-0.09c0.02-0.01,0,0.05,0,0.08c0.02-0.01,0.03-0.07,0.04-0.07
			c0,0.01,0,0.01,0,0.03c0.02-0.05,0.04,0,0.07-0.05c0,0.01,0,0.01,0,0.02c0-0.03,0.01-0.06,0.01-0.08c0.01,0,0.02-0.04,0.02,0
			c0,0-0.01,0.01-0.01,0.03l0.01-0.02c0,0.06-0.01,0.02-0.02,0.06c0.02-0.02,0.05-0.06,0.06-0.04c-0.01,0.06-0.03,0.01-0.02,0.07
			l-0.02-0.02c0,0.09-0.01-0.01-0.01,0.09c0.02,0.02,0.03-0.02,0.06,0c0.01-0.03,0.01-0.05,0.02-0.08c0.05-0.05,0,0.15,0.04,0.08
			c0.01-0.04,0.03-0.11,0.04-0.07l-0.02,0.06c0.02,0.01,0.03-0.09,0.05-0.04c-0.01,0.02-0.02,0.02-0.02,0.03
			c0.01,0.05,0.02-0.03,0.03-0.03l-0.01,0.06c0.03,0,0.06-0.04,0.09-0.05c0,0.02-0.01,0.03-0.01,0.05c0.04-0.02,0.08,0.02,0.1,0.06
			l0.02-0.05c0,0,0,0.02,0,0.03c0.01,0.02,0.01-0.03,0.02-0.05c0.01,0,0.01,0.03,0,0.04c0.03-0.04,0.08-0.08,0.1-0.02l-0.01,0.01
			c0.02,0.02,0.04-0.1,0.05-0.05l-0.01,0.01c0.04,0.03,0.09-0.01,0.11,0.07c0.02-0.07,0.04,0.04,0.06-0.06
			c0.01,0.05,0,0.07,0.03,0.11c0.01,0.01,0.03-0.06,0.04-0.07l-0.01,0.05l0.03-0.05l0,0.06l0.01-0.01c0,0,0-0.02,0-0.04
			c0.02-0.02,0.03,0.04,0.03,0.07l0.05-0.04c0,0.02-0.01,0.03-0.02,0.04c0.03-0.05-0.01,0.05,0.02,0.01c0-0.01,0.01-0.03,0.01-0.04
			c0,0.03,0.02,0.04,0.04,0.03l-0.01,0.03c0.03,0.02,0.06,0,0.1,0c-0.01-0.02,0.01-0.08,0.02-0.08L42.6,33.4
			c0.01-0.03-0.01-0.02,0.02-0.06c0.01,0.01,0,0.06-0.02,0.08l0.02-0.02c0,0.02-0.01,0.02-0.01,0.04c0.01-0.03,0.01,0.06,0.03,0
			l0,0.02c0.03,0.01,0.05-0.01,0.08-0.01c0.01-0.05,0.02-0.03,0.03-0.07c0.02,0.02,0.03,0.06,0.05,0.06
			c0.02-0.02,0.01-0.04,0.03-0.06c-0.01,0.04,0.02,0.04,0,0.07c0.03,0,0.04,0,0.08-0.03c0-0.02,0-0.03,0-0.04
			c0.01-0.01,0.01,0.02,0.01,0.04l0,0c0.05-0.05,0.06,0.14,0.11,0.06l0,0l0.04-0.04c0.01,0.01-0.02,0.03-0.01,0.06
			c0.05-0.06,0.05,0.02,0.09-0.02c-0.01,0.13,0.08,0.01,0.09,0.1l0.01-0.03c0.01-0.03,0.01,0.02,0.01,0.03c0-0.02,0-0.03,0.01-0.04
			c0.07-0.02,0.13,0.08,0.19,0.12c0.02,0.01,0.05-0.15,0.07-0.07l-0.02,0.05c0.07-0.05,0.1,0.03,0.16-0.01
			c-0.01,0.01-0.01,0.03-0.02,0.03c0,0.04,0.04,0.01,0.05-0.02c0.01,0.01-0.01,0.03-0.01,0.05c0,0.03,0.04-0.04,0.03,0
			c0,0.01-0.01,0.01-0.01,0.01c0.01-0.02,0.03,0.01,0.04-0.03c0.01,0.03,0.04-0.01,0.05,0.02c0.02-0.05,0.02,0.03,0.05-0.04
			c-0.02,0.07,0.01,0.07,0.04,0.04c-0.01,0.02,0,0.03-0.01,0.05c0.01,0,0.03-0.01,0.04-0.05c0,0.09,0.07-0.06,0.05,0.06
			c0.05-0.03,0.09-0.04,0.13-0.05c-0.02,0.07-0.04-0.01-0.06,0.07c0.04-0.02,0.08-0.08,0.11-0.05c0.01,0.01-0.02,0.03-0.02,0.04
			c0.04-0.02,0.06,0.03,0.1,0c0,0.01-0.01,0.01-0.01,0.02c0.01,0,0.01,0.01,0.02-0.02l-0.01,0.01c0.01-0.05,0.06-0.09,0.07-0.12
			c-0.04,0.09,0.02,0.03-0.04,0.15c0.01,0,0.02-0.02,0.02-0.04c0,0.01,0,0.02-0.01,0.04c0.03-0.01,0.03-0.05,0.05-0.04
			c-0.01,0.02-0.01,0.02-0.02,0.04c0.02-0.03,0.04-0.06,0.05-0.04c0,0.01-0.01,0.02-0.01,0.02c0.03,0.01,0.07-0.06,0.09-0.02
			c-0.03,0.06,0,0.03-0.02,0.09l0.02-0.01l0-0.03c0.01,0.01,0.04-0.03,0.05-0.01l-0.02,0.02l0.03,0l-0.01-0.03
			c0.03-0.03,0.03-0.08,0.04-0.05c-0.01,0,0.01,0.01,0.01,0.04l0,0c0.01-0.02,0.03-0.03,0.03-0.01c0,0.02-0.03,0.06-0.03,0.08
			c0.03-0.05,0.04,0,0.07-0.05c-0.01,0.01-0.02,0.04-0.02,0.05l0.02-0.04c0,0.02,0,0.02-0.01,0.05c0.02,0.01,0.02-0.08,0.03-0.04
			c0,0,0,0.01-0.01,0.01c0.02,0.03,0.05-0.01,0.07,0.02c-0.02,0.06-0.02,0.01-0.04,0.06c0.03,0,0.09-0.04,0.11-0.02l0-0.01
			c0.04-0.08,0,0.05,0.03,0.02c-0.01,0-0.02,0.04-0.02,0.03c0.01,0.02,0.05,0,0.09-0.01l-0.01,0.02c0,0.02,0.03,0.02,0.03,0.04
			c0.01-0.02,0.02-0.01,0.03-0.04c-0.02,0.14,0.11-0.03,0.09,0.09c0,0-0.02,0.01-0.02,0.01l0.04,0c0.02-0.04,0.01-0.02,0.02-0.05
			c0.01-0.02,0.03-0.03,0.04-0.03c-0.02,0.02-0.03,0.06-0.03,0.08c0-0.01,0.01-0.02,0.02-0.03c-0.02,0.07,0.04-0.07,0.04-0.02
			l-0.01,0.02c0.01-0.01,0.03-0.03,0.04-0.02c-0.02,0.04-0.02,0.01-0.04,0.05c0.01,0,0.06-0.06,0.05-0.02c-0.01,0.01,0,0-0.01,0.01
			c-0.01,0.05,0.02,0.04,0.02,0.05v0l-0.01,0.03l-0.01-0.02c0.07-0.09,0.03,0,0.09-0.06c-0.06,0.05-0.03,0.03-0.05,0.08
			c0,0.01,0,0.03-0.01,0.04c0.03-0.03,0,0.04,0.04-0.01c-0.01,0.01,0.01,0.01-0.01,0.04c0.02,0,0.01-0.03,0.02-0.04
			c0.05-0.04,0,0.08,0.07,0.03c0.04-0.01,0.07-0.06,0.11-0.06c-0.01,0.03-0.04,0.05-0.04,0.05c0,0.03,0.01,0.03,0.03,0.02
			c-0.01,0.01-0.02,0.02-0.02,0.04c0.01,0,0.04-0.05,0.04-0.02c-0.01,0.01-0.03,0.04-0.05,0.05c0.02-0.01,0.04-0.02,0.06-0.03
			c-0.01,0.02-0.03,0.03-0.05,0.04c0.02,0,0.04,0,0.07-0.04l0-0.02c0.01,0-0.02,0.03-0.03,0.04c-0.03,0.02,0-0.02,0.01-0.04l0.01,0
			c0.05-0.08-0.04-0.01-0.05-0.02l0.05-0.05l-0.05,0.04c0.01-0.02,0.03-0.05,0.04-0.05c0.01-0.04-0.02,0.01-0.01-0.02
			c0-0.01,0-0.01,0.01-0.01l-0.02,0.01c0.01-0.01,0.03-0.04,0.03-0.03c0-0.05-0.06,0.02-0.04-0.03l0,0c-0.04,0-0.03,0.04-0.08,0.08
			l0.05-0.12l-0.03,0.04c0,0,0-0.01,0.01-0.03c-0.03,0.02-0.01,0.02-0.03,0.04c0.01-0.05-0.05,0-0.01-0.07
			c-0.02,0.03,0,0.03-0.03,0.05c0.04-0.08-0.02,0-0.02-0.04c0.03-0.04,0.03-0.01,0.03-0.05c-0.05,0.06-0.02-0.03-0.03-0.02
			c-0.03,0.05-0.04,0.01-0.06,0.02c0.01-0.02-0.02-0.05-0.02-0.08l0.01-0.01c-0.03,0.05-0.04,0.03-0.06,0.04
			c0.02-0.03,0.03-0.04,0.04-0.06l-0.01-0.01c-0.01,0.02-0.02,0.02-0.03,0.03l0.02-0.04c-0.02-0.01-0.06,0.03-0.06-0.01
			c-0.02,0.04-0.04,0.11-0.07,0.13c0.01-0.02,0.03-0.06,0.04-0.06c0,0-0.01,0.01-0.01,0l0.03-0.03c0-0.02-0.02,0.03-0.02,0.02
			c0.01-0.03,0-0.02,0.02-0.05c0,0,0,0.02,0.01,0c-0.01-0.01-0.01-0.03,0-0.06c-0.01,0.02-0.02,0.04-0.03,0.04
			c-0.02,0,0.01-0.04,0.01-0.06c-0.01,0.02-0.01-0.01-0.04,0.04c0,0,0-0.02,0.01-0.04c-0.02,0.02-0.04-0.01-0.05,0.04l0,0
			c0,0,0,0,0,0l0,0l0,0c-0.05,0.03-0.04-0.05-0.08-0.04l0.01-0.02c-0.02,0.01-0.04-0.01-0.07,0.02c0,0,0-0.03,0.01-0.05
			c-0.02,0.01-0.03,0.02-0.03-0.01c0.02-0.02,0.01-0.03,0.02-0.01c-0.01-0.02,0.03-0.05,0-0.01c0.02-0.04,0.03-0.06,0.03-0.05v0.03
			c0,0-0.05,0-0.06-0.01l0.01-0.05c-0.01-0.08-0.11,0.09-0.09-0.03c-0.02,0.03,0,0.03-0.02,0.07c-0.01-0.01,0-0.09-0.01-0.1
			c-0.01-0.04-0.05,0.07-0.06,0.04c0,0.02-0.02,0.07-0.04,0.08c-0.01-0.01,0.01-0.09,0.03-0.1c0,0,0,0.01,0,0.01
			c0.01,0,0.02-0.01,0.02-0.03l-0.01,0.02c0-0.02-0.02-0.03-0.01-0.06c-0.02,0.01-0.02,0.03-0.01,0.04
			c-0.01-0.03-0.03-0.01-0.04-0.04c0,0.01-0.01,0.03-0.01,0.03c-0.02-0.03-0.03,0.03-0.05,0.03c0-0.03,0.03-0.07,0-0.05
			c-0.01-0.02,0.02-0.05,0.03-0.06c-0.03,0.03-0.03-0.04-0.03-0.05l0.02-0.01c-0.01,0.01-0.01,0-0.02,0l-0.01,0.04
			c0-0.01-0.01-0.01,0-0.03c-0.01,0.02-0.02,0.06-0.04,0.09c0-0.02-0.03,0.01-0.02-0.04c0.02,0.02,0.01-0.05,0.04-0.05
			c-0.01,0-0.01,0-0.01-0.01c0-0.01,0-0.01,0.01-0.01c-0.01-0.05-0.03,0.05-0.05,0.04c0.01-0.03,0.01-0.06,0.01-0.05
			c0.01,0-0.01,0.05-0.03,0.07c-0.01-0.03-0.02,0.01-0.03,0.01l0.01,0.01c-0.01,0.03-0.02,0.02-0.03,0.03
			c0.01-0.02,0.01-0.01,0.01-0.02c-0.03,0.05-0.01-0.06-0.04-0.03c0-0.02,0.02-0.05,0.01-0.07c-0.03,0.03-0.01-0.05-0.04-0.02
			c-0.01,0.04,0,0.03-0.03,0.06l0.04-0.02L44.2,33.5c0.01,0,0.03-0.02,0.03-0.01c-0.02,0.03-0.02,0.02-0.02,0.05
			c0.01-0.06-0.04,0.02-0.04-0.02l0.03-0.09c-0.01-0.05-0.06,0.04-0.08,0l0,0.02c-0.02,0.04-0.02,0-0.03,0.01
			c0.01-0.03,0.01-0.04,0.01-0.07c-0.01,0.02-0.02-0.01-0.04,0.05l0-0.05c-0.02-0.03-0.06,0.08-0.07,0.06
			c0.02-0.03,0.01-0.06,0.04-0.08c-0.01,0-0.01-0.01-0.02,0.01c0-0.02-0.03,0.04-0.03,0c-0.01,0.01-0.02,0.05-0.03,0.04
			c0-0.01,0-0.02,0-0.02c0,0.02-0.02,0.02-0.02,0.05c0.01-0.11-0.07-0.01-0.06-0.1c-0.02,0.01-0.04,0.01-0.06,0.01
			c0-0.01,0-0.02,0.01-0.01c-0.01-0.04-0.04,0.09-0.05,0.05c0-0.06,0.02-0.02,0.02-0.05c0.03-0.11-0.03,0.01-0.03-0.06l0.01-0.01
			c0-0.04-0.04,0.03-0.06,0.02c0,0,0-0.01,0-0.02c-0.03,0.05-0.06,0.06-0.08,0.07c0.02-0.04,0.01-0.02,0.02-0.06
			c-0.01,0-0.02,0.07-0.04,0.08c0-0.02-0.01-0.05,0-0.09l0.02,0c0.01-0.06-0.02,0-0.03-0.02l0.01-0.03c-0.03,0-0.04,0.07-0.07,0.05
			l0,0.02c-0.03,0.06,0-0.04-0.03,0l0.01-0.07c-0.01,0.01-0.05,0.05-0.07,0.06c0.02-0.05,0.05-0.06,0.07-0.09
			c-0.01,0-0.03,0-0.05,0.03c0.01-0.01,0.01-0.02,0.02-0.01c-0.03,0.05-0.05,0.07-0.07,0.06c0.04-0.12-0.04-0.04-0.03-0.15
			c-0.05,0.04-0.06-0.04-0.11-0.04c-0.01,0.1-0.01,0-0.03,0.1c-0.01,0-0.01,0-0.01,0l-0.01-0.07c0-0.03,0.01-0.02,0.02-0.04
			c-0.02-0.01,0-0.04-0.01-0.07c-0.01,0.04-0.02,0.05-0.03,0.03l0.01,0.05c-0.04,0.05,0-0.09-0.05-0.04l0.02-0.03
			c-0.02,0.02-0.05-0.01-0.09,0.06c0-0.01,0-0.04,0.01-0.05c-0.03-0.02-0.1,0.07-0.11,0.01l0.01-0.02
			c-0.01,0.01-0.02,0.04-0.02,0.02c0-0.01,0.01-0.02,0.01-0.03c-0.01,0.01-0.03,0.04-0.03,0.02l0.01-0.03
			c-0.03-0.04-0.07,0.06-0.1,0.07c0.02-0.07-0.01-0.1-0.02-0.12l0-0.01c-0.04-0.02-0.08,0.05-0.13,0.07
			c-0.01-0.08-0.07-0.05-0.1-0.1c0,0.01-0.01,0.06-0.02,0.06c-0.01-0.02-0.03,0.05-0.02-0.02l0,0c0.01-0.07-0.01-0.05-0.03-0.06
			l-0.02,0.09c-0.01-0.13-0.11,0.03-0.1-0.09c-0.01,0.02-0.02,0.02-0.04,0.04l0.01-0.05c-0.02-0.02-0.03-0.03-0.07-0.02l0.01-0.03
			c-0.02-0.01-0.05,0.13-0.06,0.13l0.01-0.07c-0.03,0-0.05-0.07-0.09-0.01c0-0.01,0.01-0.03,0.01-0.03c-0.01,0-0.05-0.06-0.06,0.04
			c0.01-0.06-0.03-0.01-0.05,0.01l0.01-0.05c-0.02,0.06-0.03,0.07-0.05,0.09c0-0.01,0-0.04,0.01-0.02
			c-0.01-0.03-0.02,0.04-0.03,0.01l0.01-0.03c-0.01-0.01-0.02,0.01-0.03,0.04c-0.01,0.02-0.03-0.02-0.02-0.06
			c-0.01,0.03-0.02,0.06-0.03,0.04c0-0.02,0.01-0.03,0.01-0.03c-0.02-0.04-0.05,0.05-0.06,0c0-0.02,0-0.02,0-0.03
			c0.02,0,0.04,0.01,0.03,0.01c0.01-0.04-0.01-0.05,0.02-0.11c-0.02,0.03-0.05,0.1-0.07,0.07c0,0,0-0.01,0-0.02l0,0
			c0,0-0.01,0-0.01,0.01c0-0.01-0.01-0.01-0.01-0.02c0-0.04-0.02-0.02-0.02-0.03c-0.01,0.05-0.02,0.04-0.01,0.08
			c0,0,0-0.01,0.01-0.01c0,0,0,0,0,0l0,0c0.01,0,0.01,0,0.02,0c-0.01,0.02-0.02,0.04-0.03,0.01c-0.01,0.03-0.02,0.06-0.02,0.07
			c0-0.07-0.02-0.11-0.01-0.15c-0.03,0.02-0.06,0.01-0.09,0.09c-0.01,0.01,0-0.03,0-0.04c0.01-0.01,0.01-0.01,0.01-0.02
			c-0.02,0.02-0.04,0-0.06,0.06c-0.01-0.03,0.02-0.05-0.01-0.05l0.01-0.02c-0.03-0.11-0.11,0.14-0.14,0.01c0,0.01,0,0.01,0,0.02
			c-0.05-0.07-0.14,0.03-0.18-0.07c-0.01-0.02-0.02,0.02-0.04,0.05l0-0.05l-0.03,0.06c-0.03,0.04-0.03-0.13-0.07-0.05l0,0.03
			c-0.01-0.01-0.04,0.01-0.03-0.02c0,0.01-0.01,0.03-0.01,0.02l0-0.03l-0.04,0.04c-0.02-0.02,0.02-0.1-0.01-0.11
			c-0.01,0.01-0.05,0.07-0.04,0.09c0,0,0.01,0.02,0.01,0.04l-0.01,0.01c-0.01,0.08-0.03,0.16-0.01,0.21l0.02-0.12l0-0.03h-0.02
			c0,0.32,0.01,0.08,0.02,0.06c0,0.02,0,0.07,0,0.08l0.03-0.05c0,0.03-0.02,0.07-0.01,0.09c0.01-0.03,0-0.06,0.02-0.09
			c0,0.04,0,0.07,0.01,0.04c0,0.02-0.01,0.03-0.01,0.04c0.01-0.02,0.03-0.01,0.04-0.05c0,0.04,0.03-0.01,0.02,0.07
			c0.01-0.02,0.02-0.06,0.03-0.06c-0.03,0.09,0.03,0.01,0.01,0.1c0.02-0.08,0.05-0.03,0.07-0.07c0,0.01-0.01,0.02-0.01,0.01
			c0.01,0.01,0.01,0.03,0.02,0.05c0.01-0.06,0.03-0.08,0.05-0.12c0,0.07-0.02,0.08-0.03,0.14c0.01-0.05,0.03-0.05,0.03-0.09
			c0,0.04,0.01,0.02,0,0.08c0.01-0.08,0.01-0.01,0.03-0.06c0.01,0.04-0.01,0.08,0.01,0.05C41.43,33.23,41.41,33.26,41.41,33.26z
			 M41.84,33.18L41.84,33.18C41.84,33.18,41.84,33.19,41.84,33.18C41.84,33.19,41.84,33.18,41.84,33.18z M41.88,33.19
			c0,0-0.01,0-0.01,0c0-0.02,0.01-0.03,0.01-0.03C41.87,33.16,41.88,33.17,41.88,33.19z M41.86,32.85c0.01-0.01,0.01,0,0.01,0.01
			c0,0-0.01,0-0.01,0l0,0.01C41.86,32.87,41.86,32.86,41.86,32.85z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M41.84,32.91C41.84,32.91,41.84,32.92,41.84,32.91c0,0.01,0,0.01,0,0.01L41.84,32.91L41.84,32.91z"
        />
        <polygon
          style={{ fill: '#4E4742' }}
          points="44.92,33.82 44.92,33.82 44.92,33.82 		"
        />
        <polygon
          style={{ fill: '#4E4742' }}
          points="42.34,33.37 42.34,33.35 42.33,33.36 		"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M45.03,34.05c0,0,0,0.01,0,0.01C45.02,34.06,45.03,34.05,45.03,34.05z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M45.09,34.1l0,0.02C45.09,34.11,45.09,34.1,45.09,34.1z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M45.14,34.15c0-0.01,0.01-0.01,0.01-0.02l0,0L45.14,34.15z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M44.2,33.81C44.2,33.81,44.2,33.81,44.2,33.81C44.19,33.81,44.19,33.81,44.2,33.81z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M44.52,33.8c-0.01,0.01-0.01,0.01-0.02,0.02C44.51,33.83,44.51,33.83,44.52,33.8z"
        />
        <polygon
          style={{ fill: '#4E4742' }}
          points="43.87,33.76 43.87,33.76 43.86,33.76 		"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M44.49,33.82c0,0.01,0.01,0.01,0.01,0.01C44.5,33.82,44.5,33.81,44.49,33.82z"
        />
        <polygon
          style={{ fill: '#4E4742' }}
          points="45.34,34.31 45.35,34.31 45.35,34.31 		"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M45.35,34.31L45.35,34.31C45.35,34.3,45.35,34.3,45.35,34.31z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M45.37,34.3l-0.01,0.01C45.36,34.3,45.36,34.31,45.37,34.3z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M45.29,34.25l-0.01,0.01C45.28,34.26,45.29,34.25,45.29,34.25z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M45.27,34.24c0,0.01-0.01,0.04,0,0.03l0.01-0.01C45.26,34.28,45.29,34.21,45.27,34.24z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M45.29,34l0.01,0.02c0.01-0.02,0.01-0.04,0.03-0.04C45.34,33.92,45.32,34.02,45.29,34z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M45.25,33.94c0.01,0,0.01-0.02,0.02-0.03C45.25,33.92,45.25,33.92,45.25,33.94z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M44.8,33.72l0.02-0.04C44.8,33.68,44.81,33.7,44.8,33.72z"
        />
        <polygon
          style={{ fill: '#4E4742' }}
          points="44.63,33.89 44.62,33.92 44.64,33.88 		"
        />
        <polygon
          style={{ fill: '#4E4742' }}
          points="44.69,33.59 44.69,33.58 44.67,33.59 		"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M43.89,33.33l-0.01,0.05C43.89,33.38,43.91,33.32,43.89,33.33z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M43.53,33.29c0-0.03,0-0.05-0.02-0.02C43.53,33.27,43.53,33.3,43.53,33.29z"
        />
        <polygon
          style={{ fill: '#4E4742' }}
          points="42.51,32.97 42.52,32.95 42.51,32.96 		"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          style={{ fill: '#4E4742' }}
          d="M44.62,35.9c0.01-0.01,0.03-0.04,0.02-0.04l-0.04,0.04L44.62,35.9z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M44.64,35.79c-0.02,0.02-0.06,0.05-0.08,0.1c0.04-0.04,0.03-0.02,0.07-0.07c0.01,0,0,0.02-0.01,0.04
			c0.01-0.01,0,0.02,0.02-0.01c0.02-0.04,0.01-0.05-0.02-0.02C44.63,35.81,44.64,35.8,44.64,35.79z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M44.14,35.41L44.14,35.41C44.14,35.42,44.14,35.41,44.14,35.41z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M43.8,35.23C43.8,35.23,43.8,35.23,43.8,35.23C43.8,35.23,43.8,35.23,43.8,35.23z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M43.76,35.15C43.76,35.16,43.76,35.16,43.76,35.15C43.76,35.15,43.76,35.15,43.76,35.15z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M44.14,35.41l0-0.02C44.13,35.41,44.14,35.41,44.14,35.41z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M44.5,35.86c0,0,0.01,0,0.01-0.01C44.51,35.85,44.5,35.85,44.5,35.86L44.5,35.86z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M44.32,35.45L44.32,35.45c0.01,0,0.01-0.01,0.02-0.02L44.32,35.45z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M44.44,35.5c0,0-0.03,0.04-0.04,0.06c0.01-0.02,0.02-0.02,0.02-0.03C44.43,35.53,44.43,35.52,44.44,35.5z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M44.58,35.68L44.58,35.68C44.58,35.68,44.58,35.68,44.58,35.68z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M44.24,35.4c0-0.01,0-0.01,0.01-0.02C44.24,35.39,44.24,35.39,44.24,35.4z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M40.65,34.81C40.66,34.81,40.66,34.81,40.65,34.81C40.65,34.81,40.65,34.81,40.65,34.81z"
        />
        <polygon
          style={{ fill: '#4E4742' }}
          points="40.54,34.56 40.54,34.56 40.55,34.53 		"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M42.58,34.76c0,0.04-0.03,0.05-0.03,0.1C42.56,34.84,42.59,34.79,42.58,34.76z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M44.5,35.85C44.5,35.85,44.49,35.85,44.5,35.85C44.49,35.85,44.5,35.85,44.5,35.85z"
        />
        <polygon
          style={{ fill: '#4E4742' }}
          points="42.55,34.86 42.55,34.86 42.55,34.87 		"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M43.39,34.99c0,0.01,0,0.01,0,0.02C43.38,35,43.38,35,43.39,34.99z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M43.56,35.12L43.56,35.12C43.56,35.12,43.56,35.12,43.56,35.12z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M43.26,35.02C43.26,35.01,43.26,35.01,43.26,35.02C43.26,35.01,43.26,35.02,43.26,35.02
			C43.26,35.01,43.26,35.01,43.26,35.02z"
        />
        <polygon
          style={{ fill: '#4E4742' }}
          points="43.15,34.99 43.15,35 43.16,34.99 		"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M40.99,34.84c0-0.01,0-0.03,0-0.04C40.99,34.83,40.97,34.84,40.99,34.84z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M44.51,35.84c-0.01,0.01-0.01,0.01-0.01,0.02l0,0C44.51,35.85,44.51,35.84,44.51,35.84z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M43.15,35.37l-0.01-0.01C43.13,35.37,43.14,35.37,43.15,35.37z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M41.76,34.98L41.76,34.98C41.76,34.98,41.76,34.98,41.76,34.98z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M43.08,35.35c0-0.02,0-0.03,0-0.03c0,0,0,0.01-0.01,0.01L43.08,35.35z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M40.86,34.89c0.02,0,0.02-0.06,0.03-0.09c0.02-0.01,0,0.05,0,0.08c0.01-0.01,0.03-0.07,0.04-0.07
			c0,0.01,0,0.01,0,0.03c0.02-0.05,0.04,0,0.06-0.06c0,0.01,0,0.01,0,0.02c0-0.03,0.01-0.06,0.01-0.08c0.01,0,0.02-0.04,0.02,0
			c0,0-0.01,0.01-0.01,0.03l0.01-0.02c0,0.06-0.01,0.02-0.02,0.06c0.02-0.02,0.04-0.06,0.05-0.04c-0.01,0.06-0.03,0.01-0.02,0.07
			l-0.01-0.02c0,0.09-0.01-0.01-0.01,0.09c0.02,0.02,0.03-0.02,0.05,0c0.01-0.03,0.01-0.05,0.02-0.08c0.04-0.05,0,0.15,0.04,0.08
			c0.01-0.04,0.03-0.11,0.04-0.07l-0.02,0.06c0.02,0.01,0.03-0.09,0.05-0.04c-0.01,0.02-0.02,0.02-0.02,0.03
			c0.01,0.05,0.02-0.03,0.03-0.03l-0.01,0.06c0.03,0,0.06-0.04,0.09-0.05c0,0.02-0.01,0.03-0.01,0.05c0.04-0.02,0.07,0.02,0.1,0.06
			l0.02-0.05c0,0,0,0.02,0,0.03c0.01,0.02,0.01-0.03,0.02-0.05c0.01,0,0,0.03,0,0.04c0.03-0.04,0.08-0.08,0.09-0.02l-0.01,0.01
			c0.01,0.02,0.04-0.1,0.05-0.05l0,0.01c0.04,0.03,0.09-0.01,0.1,0.07c0.02-0.07,0.03,0.04,0.05-0.06c0.01,0.05,0,0.07,0.03,0.11
			c0.01,0.01,0.02-0.06,0.04-0.07l-0.01,0.05l0.03-0.05l0,0.06l0.01-0.01c0,0,0-0.02,0-0.04c0.02-0.02,0.03,0.04,0.03,0.07
			l0.05-0.04C41.82,35,41.82,35,41.81,35.01c0.03-0.05-0.01,0.05,0.02,0.01c0-0.01,0.01-0.03,0.01-0.04c0,0.03,0.02,0.04,0.04,0.03
			l-0.01,0.03c0.02,0.01,0.06,0,0.09,0c-0.01-0.02,0.01-0.08,0.02-0.08l-0.01,0.05c0.01-0.03,0-0.02,0.02-0.06
			c0.01,0.01-0.01,0.06-0.02,0.08l0.02-0.02c0,0.02-0.01,0.02-0.01,0.04c0.01-0.03,0.01,0.06,0.03,0l0,0.02
			c0.03,0.01,0.05-0.01,0.07-0.01c0.01-0.05,0.02-0.03,0.03-0.07c0.01,0.02,0.03,0.06,0.05,0.06c0.02-0.02,0.01-0.04,0.03-0.06
			c-0.01,0.04,0.01,0.04,0,0.07c0.03,0,0.04,0,0.08-0.03c0-0.02,0-0.03,0-0.04c0.01-0.01,0.01,0.02,0,0.04l0,0
			c0.05-0.05,0.06,0.14,0.11,0.06l0,0l0.04-0.04c0.01,0.01-0.02,0.03-0.01,0.06c0.05-0.06,0.05,0.02,0.09-0.02
			c-0.01,0.13,0.08,0.01,0.08,0.1l0.01-0.03c0.01-0.03,0,0.02,0.01,0.03c0.01-0.02,0-0.03,0.01-0.04c0.07-0.02,0.12,0.08,0.17,0.12
			c0.02,0.01,0.05-0.15,0.06-0.07l-0.02,0.05c0.06-0.05,0.09,0.03,0.15-0.01c-0.01,0.01-0.01,0.03-0.02,0.03
			c0,0.04,0.03,0.01,0.04-0.02c0.01,0.01-0.01,0.03-0.01,0.05c0,0.03,0.04-0.04,0.03,0c0,0.01-0.01,0.01-0.01,0.01
			c0.01-0.02,0.03,0.01,0.03-0.03c0.01,0.03,0.04-0.01,0.04,0.02c0.02-0.05,0.02,0.03,0.05-0.04c-0.02,0.07,0.01,0.07,0.03,0.04
			c-0.01,0.02,0,0.03-0.01,0.05c0.01,0,0.03-0.01,0.04-0.04c-0.01,0.09,0.07-0.06,0.04,0.06c0.05-0.03,0.09-0.04,0.13-0.05
			c-0.03,0.07-0.04,0-0.06,0.07c0.04-0.02,0.08-0.08,0.1-0.05c0,0.01-0.02,0.03-0.02,0.04c0.04-0.02,0.06,0.03,0.1,0
			c0,0.01-0.01,0.01-0.01,0.02c0.01,0,0.01,0.01,0.02-0.02l-0.01,0.01c0.01-0.05,0.06-0.09,0.07-0.12
			c-0.04,0.09,0.02,0.03-0.04,0.15c0.01,0,0.02-0.02,0.02-0.04c0,0.01,0,0.02-0.01,0.04c0.03-0.01,0.03-0.05,0.05-0.04
			c-0.01,0.02-0.01,0.02-0.02,0.04c0.02-0.03,0.04-0.05,0.05-0.04c0,0.01-0.01,0.02-0.01,0.02c0.03,0,0.07-0.07,0.09-0.03
			c-0.03,0.06,0,0.03-0.02,0.09l0.02-0.01l0.01-0.03c0.01,0.01,0.04-0.03,0.04-0.01l-0.02,0.02l0.03,0l-0.01-0.03
			c0.02-0.03,0.03-0.07,0.04-0.05c-0.01,0,0.01,0.01,0.01,0.04l0,0c0.01-0.02,0.03-0.03,0.03-0.02c0,0.02-0.03,0.06-0.03,0.08
			c0.03-0.05,0.04,0,0.06-0.05c-0.01,0.01-0.02,0.04-0.02,0.05l0.02-0.04c0,0.02,0,0.02-0.01,0.05c0.01,0.01,0.02-0.08,0.03-0.04
			c0,0,0,0.01-0.01,0.01c0.01,0.03,0.05-0.01,0.06,0.02c-0.02,0.06-0.02,0.01-0.04,0.06c0.03,0,0.09-0.04,0.11-0.02l0-0.01
			c0.04-0.08,0,0.05,0.03,0.02c-0.01,0-0.02,0.04-0.02,0.03c0.01,0.02,0.05,0,0.09-0.02l-0.01,0.02c0,0.02,0.02,0.02,0.03,0.04
			c0.01-0.02,0.02-0.01,0.03-0.04c-0.02,0.13,0.1-0.03,0.08,0.08c0,0-0.02,0.01-0.01,0.01l0.04-0.01c0.02-0.04,0.01-0.02,0.02-0.05
			c0.01-0.02,0.03-0.03,0.04-0.03c-0.02,0.02-0.03,0.06-0.03,0.08c0-0.01,0.01-0.02,0.02-0.03c-0.02,0.07,0.04-0.07,0.04-0.02
			l-0.01,0.02c0.01-0.01,0.03-0.03,0.04-0.02c-0.02,0.04-0.02,0.01-0.04,0.05c0.01,0,0.06-0.06,0.05-0.02
			c-0.01,0.01-0.08,0.01-0.09,0.01c-0.01,0.05-0.07,0.04-0.07,0.05v0l0.07,0.03l0.04-0.01c0.07-0.1,0.05,0,0.11-0.06
			c-0.05,0.05-0.02,0.04-0.04,0.08c0,0.01,0,0.03-0.01,0.04c0.03-0.03,0,0.04,0.04-0.01c-0.01,0.01,0.01,0.01-0.01,0.04
			c0.02,0,0.01-0.03,0.02-0.04c0.04-0.04,0,0.08,0.06,0.03c0.04-0.01,0.07-0.06,0.1-0.07c-0.01,0.03-0.04,0.05-0.04,0.05
			c0,0.03,0.01,0.03,0.03,0.02c-0.01,0.01-0.01,0.02-0.02,0.04c0.01,0,0.04-0.05,0.03-0.02c-0.01,0.01-0.03,0.04-0.04,0.05
			c0.02-0.01,0.04-0.03,0.06-0.03c-0.01,0.03-0.03,0.03-0.04,0.05c0.02,0,0.04,0,0.07-0.04l0-0.02c0.01,0-0.02,0.03-0.03,0.04
			c-0.03,0.02,0-0.02,0-0.04l0.01,0c0.05-0.08-0.04,0-0.05-0.02l0.04-0.05l-0.05,0.04c0.01-0.02,0.03-0.05,0.04-0.06
			c0.01-0.04-0.02,0.02-0.01-0.02c0-0.01,0-0.01,0.01-0.01l-0.02,0.01c0.01-0.01,0.02-0.04,0.03-0.03c0-0.05-0.06,0.03-0.03-0.02
			l0,0c-0.04,0-0.03,0.04-0.08,0.08l0.05-0.12l-0.03,0.04c0,0,0-0.01,0.01-0.03c-0.03,0.02-0.01,0.02-0.03,0.05
			c0.01-0.05-0.04,0-0.01-0.07c-0.02,0.03,0,0.03-0.03,0.05c0.04-0.08-0.02,0.01-0.01-0.04c0.03-0.04,0.03-0.01,0.03-0.05
			c-0.04,0.06-0.01-0.03-0.03-0.02c-0.03,0.05-0.03,0.01-0.06,0.02c0.01-0.02-0.01-0.05-0.02-0.07l0.01-0.01
			c-0.03,0.05-0.04,0.03-0.06,0.04c0.02-0.03,0.03-0.04,0.04-0.06l-0.01-0.01c-0.01,0.02-0.02,0.02-0.03,0.03l0.02-0.04
			c-0.02-0.01-0.05,0.03-0.06,0c-0.02,0.04-0.04,0.12-0.07,0.13c0.01-0.02,0.03-0.06,0.04-0.06c0,0-0.01,0.01-0.01,0l0.02-0.03
			c0-0.02-0.02,0.03-0.02,0.02c0.01-0.03,0.01-0.02,0.02-0.05c0,0,0,0.02,0.01,0.01c0-0.01-0.01-0.03,0-0.06
			c-0.01,0.02-0.02,0.04-0.03,0.04c-0.02-0.01,0.01-0.04,0.01-0.06c-0.01,0.02-0.01-0.01-0.03,0.04c0,0,0-0.02,0.01-0.04
			c-0.02,0.02-0.04-0.01-0.05,0.04l0,0c0,0,0,0,0,0l0,0l0,0c-0.05,0.03-0.04-0.05-0.07-0.04l0.01-0.02c-0.02,0.01-0.04,0-0.06,0.02
			c0,0,0-0.03,0.01-0.05c-0.02,0.01-0.02,0.02-0.03-0.01c0.02-0.02,0.01-0.03,0.02-0.01c-0.01-0.02,0-0.05-0.04-0.01
			c0.02-0.04,0.01-0.05,0-0.05l0,0.03c-0.01,0-0.02,0-0.02-0.01l0.03-0.05c0-0.08-0.1,0.08-0.08-0.03
			c-0.02,0.03,0.01,0.03-0.02,0.07c-0.01-0.01,0-0.09,0-0.09c-0.01-0.04-0.05,0.07-0.06,0.04c0,0.02-0.02,0.07-0.04,0.08
			c-0.01-0.01,0.01-0.08,0.03-0.1c0,0,0,0.01,0,0.01c0.01-0.01,0.02-0.01,0.02-0.03l-0.01,0.02c0-0.02-0.02-0.03,0-0.06
			c-0.02,0.01-0.02,0.03-0.01,0.04c-0.01-0.03-0.03-0.01-0.04-0.04c0,0.01-0.01,0.03-0.01,0.03c-0.02-0.03-0.03,0.03-0.05,0.03
			c0-0.03,0.03-0.07,0-0.05c0-0.02,0.02-0.05,0.03-0.06c-0.03,0.03-0.02-0.04-0.03-0.05l0.01-0.01c-0.01,0.01-0.01,0-0.02,0
			l-0.01,0.04c0-0.01-0.01-0.01,0-0.03c-0.01,0.02-0.02,0.06-0.04,0.09c0-0.02-0.03,0.01-0.01-0.04c0.02,0.03,0.02-0.05,0.04-0.05
			c-0.01,0-0.01,0-0.01-0.01c0-0.01,0-0.01,0.01-0.01c-0.01-0.05-0.03,0.05-0.05,0.04c0.01-0.03,0.01-0.06,0.01-0.05
			c0.01,0-0.01,0.05-0.03,0.07c-0.01-0.03-0.02,0.01-0.03,0.01l0,0.01c-0.01,0.03-0.02,0.02-0.03,0.03
			c0.01-0.02,0.01-0.01,0.01-0.02c-0.03,0.05-0.01-0.06-0.03-0.03c0-0.02,0.02-0.05,0.02-0.07c-0.03,0.03-0.01-0.04-0.03-0.02
			c-0.01,0.04,0,0.03-0.03,0.06l0.04-0.02l-0.04,0.05c0.01,0,0.03-0.02,0.03-0.01c-0.02,0.03-0.02,0.02-0.02,0.05
			c0.01-0.06-0.04,0.03-0.04-0.02l0.03-0.09c-0.01-0.05-0.05,0.04-0.07,0l0,0.02c-0.02,0.04-0.02,0-0.03,0.01
			c0.01-0.03,0.01-0.04,0.01-0.07c-0.01,0.02-0.02-0.01-0.04,0.05l0-0.05c-0.02-0.02-0.05,0.08-0.07,0.06
			c0.02-0.03,0.01-0.06,0.04-0.08c-0.01,0-0.01-0.01-0.02,0.01c0-0.02-0.03,0.04-0.03,0c-0.01,0.01-0.02,0.05-0.03,0.04
			c0-0.01,0-0.02,0-0.02c0,0.02-0.02,0.02-0.02,0.05c0.01-0.11-0.06-0.01-0.06-0.1C43.13,35,43.11,35.01,43.1,35
			c0-0.01,0-0.02,0.01-0.01c-0.01-0.04-0.04,0.09-0.05,0.05c0-0.05,0.02-0.02,0.02-0.05c0.03-0.11-0.03,0.01-0.03-0.06l0.01-0.01
			c0-0.04-0.04,0.03-0.06,0.02c0,0,0-0.01,0-0.02c-0.03,0.05-0.06,0.06-0.08,0.07c0.02-0.04,0.01-0.02,0.02-0.06
			c-0.01,0-0.02,0.07-0.04,0.07c0-0.02-0.01-0.05,0.01-0.09l0.02,0c0.01-0.06-0.02,0-0.03-0.02l0.01-0.02
			c-0.03,0-0.04,0.07-0.07,0.05l0,0.02c-0.03,0.06,0-0.04-0.03,0l0.01-0.07c-0.01,0.01-0.05,0.05-0.07,0.06
			c0.02-0.05,0.05-0.06,0.07-0.09c-0.01,0-0.03,0-0.05,0.03c0.01-0.01,0.01-0.02,0.02-0.01c-0.02,0.05-0.05,0.07-0.06,0.06
			c0.04-0.12-0.04-0.04-0.03-0.14c-0.04,0.04-0.06-0.04-0.1-0.04c-0.01,0.1-0.01,0-0.03,0.1c-0.01,0-0.01,0-0.01-0.01l-0.01-0.07
			c0-0.03,0.01-0.02,0.02-0.04c-0.02-0.01,0-0.04,0-0.07c-0.01,0.04-0.02,0.05-0.03,0.03l0,0.05c-0.04,0.05,0-0.09-0.04-0.04
			l0.02-0.03c-0.02,0.02-0.05-0.01-0.08,0.06c0-0.01,0-0.04,0.01-0.05c-0.03-0.02-0.09,0.07-0.11,0.01l0.01-0.02
			c-0.01,0.01-0.02,0.04-0.02,0.02c0-0.01,0.01-0.02,0.01-0.03c-0.01,0.01-0.03,0.04-0.03,0.02l0.01-0.03
			c-0.03-0.04-0.07,0.06-0.1,0.07c0.02-0.07,0-0.1-0.01-0.12l0-0.01c-0.04-0.02-0.08,0.05-0.12,0.07c0-0.08-0.07-0.05-0.09-0.1
			c0,0.01-0.01,0.06-0.02,0.06c-0.01-0.02-0.03,0.05-0.02-0.02l0,0c0.01-0.07-0.01-0.05-0.03-0.06l-0.02,0.09
			c-0.01-0.13-0.1,0.03-0.1-0.09c-0.01,0.02-0.02,0.02-0.04,0.04l0.01-0.05c-0.02-0.02-0.03-0.02-0.06-0.02l0.01-0.03
			c-0.02-0.01-0.05,0.13-0.06,0.13l0.01-0.07c-0.03,0-0.04-0.07-0.08-0.01c0-0.01,0.01-0.03,0.01-0.03c-0.01,0-0.04-0.06-0.06,0.04
			c0.01-0.06-0.03-0.01-0.05,0.01l0.01-0.05c-0.02,0.06-0.03,0.07-0.05,0.09c0-0.01,0-0.04,0.01-0.02
			c-0.01-0.03-0.02,0.04-0.03,0.01l0.01-0.03c-0.01-0.01-0.02,0.01-0.03,0.04c-0.01,0.02-0.03-0.02-0.02-0.06
			c-0.01,0.03-0.02,0.06-0.03,0.04c0-0.02,0.01-0.03,0.01-0.03c-0.02-0.04-0.05,0.05-0.05,0c0-0.02,0-0.02,0-0.03
			c0.02,0,0.03,0.01,0.03,0.01c0.01-0.04-0.01-0.05,0.02-0.11c-0.02,0.03-0.05,0.1-0.06,0.07c0,0,0-0.01,0-0.02l0,0
			c0,0-0.01,0-0.01,0.01c0-0.01-0.01-0.01-0.01-0.02c0-0.04-0.01-0.02-0.02-0.03c-0.01,0.05-0.02,0.04-0.01,0.08
			c0,0,0-0.01,0.01-0.01c0,0,0,0,0,0l0,0c0.01,0,0.01,0,0.02,0c-0.01,0.02-0.02,0.04-0.02,0.01c-0.01,0.03-0.02,0.06-0.02,0.07
			c0-0.07-0.01-0.11-0.01-0.15c-0.03,0.02-0.06,0.01-0.09,0.09c-0.01,0.01,0-0.03,0-0.04c0.01-0.01,0.01-0.01,0.01-0.02
			c-0.02,0.02-0.04,0-0.06,0.06c-0.01-0.03,0.02-0.05-0.01-0.05l0.01-0.02c-0.03-0.11-0.1,0.14-0.13,0.01c0,0.01,0,0.01,0,0.02
			c-0.05-0.06-0.13,0.03-0.17-0.06c-0.01-0.02-0.02,0.02-0.04,0.05l0-0.05l-0.03,0.06c-0.03,0.04-0.02-0.13-0.07-0.05l0,0.03
			c-0.01-0.01-0.04,0.02-0.03-0.02c0,0.01-0.01,0.03-0.01,0.02l0-0.03l-0.03,0.04c-0.02-0.02,0.02-0.1-0.01-0.11
			c-0.01,0.01-0.04,0.07-0.04,0.09c0,0,0.01,0.02,0.01,0.04l-0.01,0.01c-0.01,0.08-0.03,0.16-0.01,0.21l0.02-0.05l0,0.04l-0.01,0
			c0,0.04,0.01,0.01,0.02-0.01c0,0.02,0,0.03,0,0.04l0.03-0.07c0,0.03-0.02,0.06-0.01,0.08c0.01-0.03,0-0.07,0.02-0.09
			c0,0.04,0,0.06,0.01,0.04c0,0.02-0.01,0.03-0.01,0.04c0.01-0.02,0.02-0.01,0.03-0.05c0,0.04,0.03-0.01,0.02,0.07
			c0.01-0.02,0.02-0.06,0.03-0.06c-0.03,0.09,0.03,0.01,0.01,0.1c0.02-0.08,0.04-0.03,0.07-0.07c0,0.01-0.01,0.02-0.01,0.01
			c0.01,0.01,0.01,0.03,0.01,0.05c0.01-0.07,0.03-0.08,0.05-0.12c0,0.07-0.02,0.08-0.02,0.14c0.01-0.05,0.03-0.05,0.03-0.09
			c0,0.04,0.01,0.02,0,0.08c0.01-0.08,0.01-0.01,0.03-0.06c0.01,0.04-0.01,0.08,0.01,0.05C40.88,34.86,40.86,34.89,40.86,34.89z
			 M41.27,34.81L41.27,34.81C41.26,34.82,41.26,34.82,41.27,34.81C41.26,34.82,41.26,34.82,41.27,34.81z M41.3,34.82
			c0,0-0.01,0-0.01,0c0-0.02,0.01-0.03,0.01-0.03C41.29,34.79,41.3,34.8,41.3,34.82z M41.29,34.48c0.01-0.01,0.01,0,0.01,0.01
			c0,0-0.01,0-0.01,0l0,0.01C41.28,34.5,41.28,34.49,41.29,34.48z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M41.27,34.54C41.26,34.54,41.26,34.55,41.27,34.54c0,0.01,0,0.01,0,0.01L41.27,34.54L41.27,34.54z"
        />
        <polygon
          style={{ fill: '#4E4742' }}
          points="44.14,35.42 44.14,35.42 44.14,35.43 		"
        />
        <polygon
          style={{ fill: '#4E4742' }}
          points="41.72,34.99 41.73,34.98 41.72,34.99 		"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M44.23,35.65c0,0,0,0.01,0,0.01C44.23,35.66,44.23,35.65,44.23,35.65z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M44.3,35.69l0,0.02C44.3,35.7,44.3,35.7,44.3,35.69z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M44.34,35.74c0-0.01,0.01-0.01,0.01-0.02l0,0L44.34,35.74z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M43.46,35.42C43.46,35.42,43.46,35.42,43.46,35.42C43.45,35.43,43.46,35.42,43.46,35.42z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M43.76,35.41c-0.01,0.01-0.01,0.01-0.02,0.02C43.75,35.44,43.75,35.44,43.76,35.41z"
        />
        <polygon
          style={{ fill: '#4E4742' }}
          points="43.15,35.37 43.15,35.37 43.15,35.37 		"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M43.73,35.43c0,0.01,0.01,0.01,0.01,0.01C43.74,35.43,43.74,35.42,43.73,35.43z"
        />
        <polygon
          style={{ fill: '#4E4742' }}
          points="44.54,35.89 44.54,35.89 44.54,35.89 		"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M44.54,35.89L44.54,35.89C44.55,35.88,44.55,35.88,44.54,35.89z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M44.56,35.88l-0.01,0.01C44.55,35.89,44.55,35.89,44.56,35.88z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M44.49,35.83l-0.01,0.01C44.48,35.84,44.48,35.84,44.49,35.83z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M44.46,35.82c0,0.01-0.01,0.04,0,0.03l0.01-0.01C44.46,35.87,44.48,35.8,44.46,35.82z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M44.49,35.59l0.01,0.02c0.01-0.02,0.01-0.04,0.02-0.04C44.53,35.51,44.51,35.61,44.49,35.59z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M44.45,35.53c0.01,0,0.01-0.02,0.02-0.03C44.45,35.51,44.45,35.52,44.45,35.53z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M44.03,35.33l0.02-0.04C44.03,35.29,44.04,35.31,44.03,35.33z"
        />
        <polygon
          style={{ fill: '#4E4742' }}
          points="43.86,35.5 43.86,35.53 43.88,35.49 		"
        />
        <polygon
          style={{ fill: '#4E4742' }}
          points="43.93,35.19 43.93,35.19 43.92,35.2 		"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M43.19,34.95L43.17,35C43.18,35,43.2,34.94,43.19,34.95z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M42.85,34.91c0-0.03,0-0.05-0.01-0.02C42.84,34.89,42.84,34.92,42.85,34.91z"
        />
        <polygon
          style={{ fill: '#4E4742' }}
          points="41.9,34.59 41.91,34.58 41.89,34.59 		"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          style={{ fill: '#4E4742' }}
          d="M41.96,36.5c0.01-0.01,0.02-0.04,0.02-0.04l-0.03,0.05L41.96,36.5z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M41.99,36.4c-0.01,0.02-0.04,0.06-0.06,0.11c0.03-0.05,0.02-0.03,0.05-0.08c0.01,0,0,0.02-0.01,0.04
			c0.01-0.01,0,0.01,0.02-0.02c0.02-0.04,0.01-0.04-0.01-0.01C41.98,36.42,41.99,36.41,41.99,36.4z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M41.59,36.15c0,0,0-0.01,0-0.01C41.59,36.15,41.59,36.15,41.59,36.15z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M41.58,36.09C41.58,36.09,41.58,36.09,41.58,36.09C41.58,36.09,41.58,36.08,41.58,36.09z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M41.9,36.51c0,0,0-0.01,0.01-0.01C41.91,36.49,41.91,36.5,41.9,36.51L41.9,36.51z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M41.84,36.2L41.84,36.2c0-0.01,0.01-0.01,0.01-0.02L41.84,36.2z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M41.9,36.21c0,0-0.02,0.04-0.02,0.06c0.01-0.02,0.01-0.03,0.01-0.03C41.9,36.24,41.9,36.23,41.9,36.21z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M41.97,36.32L41.97,36.32C41.97,36.32,41.97,36.32,41.97,36.32z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M41.8,36.18c0-0.01,0-0.01,0-0.02L41.8,36.18z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M40.14,36.31C40.14,36.3,40.14,36.3,40.14,36.31C40.14,36.3,40.14,36.31,40.14,36.31z"
        />
        <polygon
          style={{ fill: '#4E4742' }}
          points="40.12,36.06 40.13,36.06 40.14,36.04 		"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M41.03,35.98c0,0.04-0.02,0.05-0.01,0.1C41.02,36.06,41.03,36.01,41.03,35.98z"
        />
        <polygon
          style={{ fill: '#4E4742' }}
          points="41.02,36.08 41.02,36.08 41.02,36.08 		"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M41.4,36.02c0,0.01,0,0.01,0,0.02C41.4,36.04,41.4,36.03,41.4,36.02z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M41.48,36.11L41.48,36.11C41.48,36.1,41.48,36.1,41.48,36.11z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M41.34,36.08C41.34,36.08,41.34,36.07,41.34,36.08C41.34,36.08,41.34,36.08,41.34,36.08
			C41.34,36.08,41.34,36.07,41.34,36.08z"
        />
        <polygon
          style={{ fill: '#4E4742' }}
          points="41.29,36.07 41.29,36.08 41.29,36.08 		"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M40.31,36.31c0-0.01,0-0.03,0-0.04C40.31,36.3,40.3,36.31,40.31,36.31z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M41.91,36.48c0,0.01-0.01,0.02-0.01,0.02c0,0,0,0,0,0C41.91,36.49,41.91,36.48,41.91,36.48z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M41.28,36.45L41.28,36.45C41.28,36.45,41.28,36.45,41.28,36.45z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M40.66,36.33L40.66,36.33C40.66,36.34,40.66,36.34,40.66,36.33z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M41.25,36.45c0-0.02,0-0.03,0-0.03c0,0,0,0.01,0,0.01L41.25,36.45z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M40.24,36.37c0.01,0,0.01-0.06,0.02-0.1c0.01-0.02,0,0.05,0,0.08c0.01-0.02,0.02-0.07,0.02-0.07
			c0,0.01,0,0.01,0,0.03c0.01-0.05,0.02,0,0.03-0.06c0,0.01,0,0.01,0,0.02c0-0.03,0.01-0.06,0.01-0.09c0,0,0.01-0.04,0.01,0
			c0,0,0,0.01-0.01,0.03l0.01-0.02c0,0.06-0.01,0.02-0.01,0.06c0.01-0.02,0.02-0.07,0.03-0.05c0,0.06-0.01,0.02-0.01,0.07
			l-0.01-0.02c0,0.09,0-0.01-0.01,0.09c0.01,0.02,0.02-0.03,0.02-0.01c0-0.03,0.01-0.05,0.01-0.08c0.02-0.06,0,0.14,0.02,0.07
			c0.01-0.04,0.01-0.11,0.02-0.07l-0.01,0.06c0.01,0.01,0.01-0.09,0.02-0.05c0,0.03-0.01,0.02-0.01,0.03c0,0.05,0.01-0.04,0.01-0.04
			l0,0.06c0.01-0.01,0.03-0.05,0.04-0.06c0,0.02-0.01,0.03,0,0.05c0.02-0.03,0.03,0.01,0.04,0.05l0.01-0.05c0,0,0,0.02,0,0.03
			c0,0.02,0-0.03,0.01-0.05c0,0,0,0.03,0,0.04c0.01-0.04,0.04-0.09,0.04-0.03l0,0.01c0.01,0.02,0.02-0.1,0.02-0.05l0,0.01
			c0.02,0.02,0.04-0.02,0.05,0.06c0.01-0.07,0.01,0.03,0.03-0.07c0.01,0.05,0,0.07,0.01,0.1c0,0,0.01-0.06,0.02-0.07l0,0.05
			l0.01-0.05l0,0.06l0.01-0.01c0,0,0-0.02,0-0.04c0.01-0.02,0.01,0.04,0.01,0.06l0.02-0.04c0,0.02-0.01,0.03-0.01,0.04
			c0.01-0.05,0,0.05,0.01,0.01c0-0.01,0-0.03,0-0.04c0,0.03,0.01,0.03,0.02,0.02l0,0.04c0.01,0.01,0.03-0.01,0.04-0.01
			c0-0.02,0-0.08,0.01-0.08l0,0.05c0.01-0.03,0-0.02,0.01-0.06c0,0.01,0,0.06-0.01,0.08l0.01-0.02c0,0.02,0,0.02-0.01,0.05
			c0.01-0.03,0.01,0.06,0.01,0l0,0.02c0.01,0,0.02-0.02,0.03-0.02c0-0.05,0.01-0.04,0.02-0.07c0.01,0.02,0.01,0.05,0.02,0.05
			c0.01-0.02,0.01-0.04,0.01-0.07c0,0.04,0.01,0.04,0,0.07c0.01-0.01,0.02-0.01,0.03-0.04c0-0.02,0-0.03,0-0.04
			c0-0.01,0,0.02,0,0.04l0,0c0.02-0.06,0.03,0.13,0.05,0.04l0,0l0.02-0.04c0.01,0.01-0.01,0.03-0.01,0.06
			c0.02-0.07,0.02,0.01,0.04-0.03c-0.01,0.13,0.03-0.01,0.04,0.08l0-0.03c0.01-0.03,0,0.02,0,0.02c0-0.02,0-0.03,0-0.04
			c0.03-0.03,0.05,0.05,0.08,0.08c0.01,0.01,0.03-0.15,0.03-0.08l-0.01,0.05c0.03-0.06,0.04,0.01,0.07-0.05
			c0,0.01-0.01,0.03-0.01,0.03c0,0.04,0.02,0,0.02-0.03c0,0,0,0.03,0,0.05c0,0.02,0.02-0.05,0.01-0.01c0,0.01,0,0.01,0,0.02
			c0.01-0.02,0.01,0.01,0.02-0.04c0,0.02,0.02-0.02,0.02,0.01c0.01-0.05,0.01,0.03,0.02-0.05c-0.01,0.08,0,0.06,0.01,0.03
			c-0.01,0.02,0,0.02-0.01,0.06c0.01,0,0.01-0.01,0.02-0.05c0,0.09,0.03-0.07,0.02,0.05c0.02-0.04,0.04-0.06,0.06-0.08
			c-0.01,0.07-0.02,0-0.03,0.08c0.02-0.03,0.04-0.09,0.05-0.07c0,0.01-0.01,0.03-0.01,0.04c0.02-0.03,0.03,0.01,0.04-0.03
			c0,0.01,0,0.01-0.01,0.02c0,0,0,0.01,0.01-0.02l0,0.01c0.01-0.05,0.03-0.1,0.03-0.13c-0.02,0.1,0.01,0.03-0.02,0.15
			c0-0.01,0.01-0.02,0.01-0.04c0,0.01,0,0.02-0.01,0.05c0.01-0.02,0.02-0.05,0.02-0.05c0,0.02-0.01,0.02-0.01,0.04
			c0.01-0.03,0.02-0.06,0.02-0.05c0,0.01,0,0.02-0.01,0.02c0.01,0,0.04-0.08,0.04-0.05c-0.02,0.07,0,0.02-0.01,0.09l0.01-0.02
			l0-0.03c0.01,0,0.02-0.04,0.02-0.02l-0.01,0.02l0.01-0.01l0-0.02c0.01-0.03,0.02-0.08,0.02-0.06c0,0.01,0.01,0,0,0.04l0,0
			c0.01-0.02,0.01-0.04,0.02-0.02c0,0.02-0.01,0.07-0.02,0.08c0.01-0.05,0.02-0.01,0.03-0.07c0,0.01-0.01,0.04-0.01,0.05l0.01-0.04
			c0,0.01,0,0.02-0.01,0.05c0.01,0.01,0.01-0.08,0.02-0.04c0,0,0,0.01-0.01,0.02c0.01,0.02,0.03-0.02,0.03,0
			c-0.01,0.06-0.01,0.01-0.02,0.07c0.02-0.01,0.04-0.07,0.05-0.05l0-0.01c0.02-0.09,0,0.05,0.02,0.01c0,0.01-0.01,0.05-0.01,0.04
			c0,0.02,0.02-0.02,0.04-0.04l-0.01,0.02c0,0.02,0.01,0.01,0.01,0.03c0.01-0.02,0.01-0.02,0.02-0.05
			c-0.02,0.13,0.05-0.06,0.04,0.05c0,0-0.01,0.02-0.01,0.02l0.02-0.02c0.01-0.04,0.01-0.02,0.01-0.05c0.01-0.02,0.02-0.04,0.02-0.04
			c-0.01,0.02-0.02,0.06-0.02,0.08c0-0.01,0.01-0.02,0.01-0.04c-0.01,0.07,0.02-0.07,0.02-0.03l-0.01,0.02
			c0.01-0.01,0.02-0.03,0.02-0.03c-0.01,0.04-0.01,0.02-0.02,0.05c0.01,0,0.04-0.08,0.03-0.03c-0.01,0.01-0.13,0.01-0.14,0.02
			c-0.01,0.05-0.13,0.04-0.13,0.05v0l0.12,0.03l0.06-0.01c0.04-0.11,0.05-0.02,0.08-0.09c-0.03,0.07,0,0.04-0.02,0.09
			c0,0.01,0,0.03,0,0.04c0.02-0.04,0,0.03,0.02-0.02c-0.01,0.02,0.01,0-0.01,0.04c0.01-0.01,0.01-0.03,0.02-0.05
			c0.03-0.05-0.01,0.07,0.03,0c0.02-0.03,0.04-0.08,0.06-0.1c-0.01,0.03-0.03,0.05-0.03,0.05c0,0.02,0,0.02,0.01,0.01
			c-0.01,0.01-0.01,0.02-0.02,0.04c0.01,0,0.03-0.05,0.02-0.03c-0.01,0.02-0.02,0.04-0.03,0.06c0.01-0.01,0.03-0.04,0.04-0.05
			c-0.01,0.03-0.02,0.04-0.03,0.05c0.01-0.01,0.02-0.02,0.04-0.06l0-0.02c0,0-0.02,0.04-0.02,0.04c-0.02,0.03,0-0.02,0.01-0.03
			l0.01-0.01c0.04-0.09-0.02,0.01-0.02,0l0.03-0.06l-0.03,0.05c0.01-0.02,0.02-0.05,0.03-0.06c0.01-0.03-0.01,0.02,0-0.02
			c0-0.01,0-0.01,0-0.01l-0.01,0.02c0.01-0.01,0.02-0.05,0.02-0.04c0.01-0.04-0.03,0.04-0.02-0.01l0,0
			c-0.02,0.02-0.02,0.05-0.05,0.1l0.04-0.12l-0.02,0.05c0,0,0-0.01,0.01-0.03c-0.02,0.03-0.01,0.02-0.02,0.05
			c0.01-0.05-0.02,0.01,0-0.06c-0.02,0.04,0,0.03-0.02,0.05c0.03-0.08-0.01,0.01,0-0.03c0.02-0.04,0.01-0.02,0.02-0.06
			c-0.03,0.07-0.01-0.02-0.02-0.01c-0.02,0.06-0.02,0.02-0.03,0.04c0.01-0.02,0-0.04-0.01-0.06l0-0.01
			c-0.02,0.06-0.02,0.05-0.03,0.06c0.01-0.04,0.02-0.05,0.02-0.06l-0.01,0c-0.01,0.02-0.01,0.03-0.02,0.03l0.01-0.04
			c-0.01,0-0.03,0.05-0.03,0.02c-0.01,0.04-0.03,0.12-0.04,0.14c0.01-0.02,0.02-0.06,0.02-0.07c0,0-0.01,0.02-0.01,0.01l0.01-0.04
			c0-0.02-0.01,0.03-0.01,0.03c0.01-0.03,0-0.02,0.01-0.05c0,0,0,0.01,0,0c0-0.01,0-0.03,0-0.05c0,0.02-0.01,0.04-0.02,0.05
			c-0.01,0,0.01-0.04,0.01-0.06c-0.01,0.02-0.01,0-0.02,0.05c0,0-0.09-0.02-0.08-0.04c-0.01,0.02-0.12,0-0.12,0.05v0
			c0,0,0.17-0.01,0.17-0.01h0h0c-0.02,0-0.02-0.03-0.03-0.01l0-0.01c-0.01,0.02-0.02,0.01-0.03,0.04c0,0,0-0.03,0.01-0.05
			c-0.01,0.01-0.01,0.03-0.01,0c0.01-0.03,0-0.03,0.01-0.02c0-0.02,0-0.04-0.02,0c0.01-0.04,0.01-0.05,0-0.04l0,0.03
			c-0.01,0.01-0.01,0-0.01,0l0.01-0.05c0-0.07-0.05,0.11-0.04-0.01c-0.01,0.04,0,0.03-0.01,0.07c0-0.01,0.01-0.09,0-0.09
			c0-0.03-0.03,0.08-0.03,0.06c0,0.02-0.01,0.07-0.02,0.09c0-0.01,0.01-0.08,0.02-0.1c0,0,0,0.01,0,0.01c0-0.01,0.01-0.01,0.01-0.04
			l0,0.02c0-0.02-0.01-0.02,0-0.05c-0.01,0.02-0.01,0.03-0.01,0.04c0-0.03-0.01,0-0.02-0.02c0,0.01,0,0.03-0.01,0.03
			c-0.01-0.03-0.01,0.04-0.02,0.04c0-0.03,0.02-0.07,0-0.05c0-0.02,0.01-0.05,0.01-0.07c-0.02,0.04-0.01-0.03-0.01-0.04L41.56,36
			c0,0.01-0.01,0.01-0.01,0.01l-0.01,0.04c0-0.01,0-0.01,0-0.02c-0.01,0.03-0.01,0.07-0.02,0.1c0-0.02-0.01,0.02-0.01-0.03
			c0.01,0.02,0.01-0.05,0.02-0.06c0,0.01,0,0.01-0.01,0c0-0.01,0-0.01,0-0.01c0-0.05-0.02,0.06-0.03,0.06
			c0.01-0.03,0.01-0.06,0-0.05c0,0-0.01,0.05-0.01,0.07c0-0.02-0.01,0.02-0.01,0.01l0,0.01c-0.01,0.03-0.01,0.02-0.02,0.03
			c0-0.02,0.01-0.01,0.01-0.03c-0.01,0.05,0-0.06-0.02-0.02c0-0.02,0.01-0.06,0.01-0.07c-0.01,0.04,0-0.04-0.02-0.01
			c0,0.04,0,0.03-0.01,0.07l0.02-0.03l-0.02,0.06c0,0,0.01-0.02,0.01-0.02c-0.01,0.03-0.01,0.02-0.01,0.05
			c0-0.06-0.02,0.03-0.02-0.01l0.02-0.1c0-0.05-0.03,0.05-0.04,0.02l0,0.02c-0.01,0.04-0.01,0-0.02,0.02
			c0.01-0.03,0.01-0.05,0.01-0.07c0,0.02-0.01,0-0.02,0.06l0-0.04c-0.01-0.02-0.03,0.09-0.03,0.08c0.01-0.04,0.01-0.06,0.02-0.08
			c0,0-0.01-0.01-0.01,0.02c0-0.02-0.02,0.04-0.01,0.01c0,0.01-0.01,0.05-0.01,0.05c0-0.01,0-0.02,0-0.02
			c0,0.02-0.01,0.02-0.01,0.06c0.01-0.1-0.03,0-0.03-0.09c-0.01,0.01-0.02,0.02-0.03,0.02c0-0.01,0-0.02,0-0.01
			c0-0.03-0.02,0.09-0.02,0.06c0-0.05,0.01-0.03,0.01-0.05c0.01-0.11-0.01,0.01-0.01-0.05l0-0.01c0-0.04-0.02,0.04-0.03,0.03
			c0,0,0-0.01,0-0.02c-0.01,0.05-0.03,0.07-0.04,0.09c0.01-0.04,0.01-0.02,0.01-0.07c0,0.01-0.01,0.07-0.02,0.08
			c0-0.02,0-0.04,0-0.09l0.01,0c0-0.06-0.01,0-0.01-0.01l0-0.03c-0.01,0.01-0.02,0.07-0.03,0.07l0,0.02c-0.01,0.06,0-0.04-0.01,0.01
			l0.01-0.07c-0.01,0.02-0.02,0.06-0.03,0.07c0.01-0.05,0.02-0.07,0.03-0.1c0,0-0.01,0-0.02,0.04c0-0.01,0.01-0.02,0.01-0.01
			c-0.01,0.05-0.02,0.08-0.03,0.07C41.11,36,41.08,36.1,41.08,36c-0.02,0.05-0.03-0.02-0.04-0.02c-0.01,0.1-0.01,0-0.02,0.1
			c0,0.01-0.01,0-0.01,0l-0.01-0.06c0-0.03,0.01-0.02,0.01-0.05c-0.01-0.01,0-0.04,0-0.07c0,0.04-0.01,0.05-0.01,0.04l0,0.05
			c-0.02,0.05,0-0.09-0.02-0.03L41,35.92c-0.01,0.02-0.02,0-0.04,0.08c0-0.01,0-0.04,0.01-0.05c-0.01-0.01-0.04,0.08-0.05,0.03
			l0-0.02c0,0.01-0.01,0.05-0.01,0.02c0-0.01,0-0.02,0-0.03c0,0.01-0.01,0.04-0.01,0.03l0.01-0.03c-0.01-0.03-0.03,0.07-0.04,0.08
			c0.01-0.07,0-0.1-0.01-0.11l0-0.01c-0.02-0.01-0.04,0.06-0.05,0.09c0-0.08-0.03-0.04-0.04-0.08c0,0.01-0.01,0.06-0.01,0.06
			c0-0.02-0.02,0.05-0.01-0.02l0,0c0-0.07-0.01-0.05-0.01-0.05L40.72,36c0-0.13-0.04,0.04-0.04-0.07c-0.01,0.02-0.01,0.02-0.02,0.04
			l0-0.05c-0.01-0.01-0.01-0.02-0.03-0.01l0.01-0.03c-0.01,0-0.02,0.14-0.03,0.14l0-0.07c-0.01,0-0.02-0.06-0.04,0
			c0-0.01,0-0.03,0.01-0.03c-0.01,0-0.02-0.05-0.03,0.05c0.01-0.06-0.01-0.01-0.02,0.02l0.01-0.05c-0.01,0.06-0.01,0.08-0.02,0.09
			c0-0.01,0-0.04,0-0.03c0-0.03-0.01,0.04-0.01,0.02l0.01-0.03c-0.01,0-0.01,0.01-0.01,0.05c-0.01,0.02-0.01-0.01-0.01-0.06
			c0,0.03-0.01,0.06-0.01,0.05c0-0.02,0-0.03,0-0.03c-0.01-0.03-0.02,0.05-0.02,0.01c0-0.02,0-0.02,0-0.03
			c0.01,0,0.02,0.01,0.01,0.01c0-0.04,0-0.05,0.01-0.11c-0.01,0.03-0.02,0.1-0.03,0.08c0,0,0-0.01,0-0.01l0,0c0,0,0,0,0,0.01
			c0-0.01,0-0.01,0-0.01c0-0.04-0.01-0.02-0.01-0.02c0,0.05-0.01,0.04-0.01,0.08c0,0,0-0.01,0-0.01c0,0,0,0,0,0l0,0
			c0,0,0.01,0,0.01,0c0,0.02-0.01,0.04-0.01,0.02c0,0.03-0.01,0.06-0.01,0.08c0-0.07,0-0.1,0-0.14c-0.01,0.03-0.03,0.02-0.04,0.1
			c0,0.01,0-0.03,0-0.04c0-0.01,0-0.01,0.01-0.02c-0.01,0.02-0.02,0.01-0.03,0.07c0-0.03,0.01-0.06,0-0.05l0-0.02
			c-0.01-0.1-0.05,0.15-0.06,0.03c0,0.01,0,0.01,0,0.02c-0.02-0.06-0.06,0.05-0.07-0.05c0-0.02-0.01,0.02-0.02,0.05l0.01-0.05
			l-0.02,0.06c-0.02,0.04,0-0.13-0.02-0.05l-0.01,0.03c0-0.01-0.02,0.02-0.01-0.02c0,0.01-0.01,0.03-0.01,0.02l0.01-0.03l-0.02,0.04
			c0-0.01,0.01-0.04,0.01-0.05l0.05,0l0.06,0v0l-0.04-0.03l-0.03,0l-0.01-0.01c0.01-0.01,0.01-0.01,0.01-0.01
			c0.01,0.02-0.04,0.07-0.05,0.08c0,0-0.01,0.02-0.02,0.04l-0.01,0.01c-0.05,0.06-0.1,0.12-0.12,0.18c0,0,0.06-0.02,0.06-0.02
			l0,0.04l-0.03-0.01c0,0.04,0.03,0.02,0.05,0c-0.01,0.02-0.01,0.03-0.01,0.04l0.04-0.07c-0.01,0.03-0.02,0.06-0.02,0.08
			c0.02-0.03,0.01-0.07,0.03-0.09c0,0.04-0.01,0.06,0,0.04c0,0.02-0.01,0.03-0.01,0.04c0.01-0.02,0.02-0.01,0.03-0.05
			c0,0.04,0.02-0.01,0.01,0.07c0-0.02,0.02-0.06,0.02-0.06c-0.02,0.09,0.01,0.01,0,0.1c0.02-0.08,0.03-0.03,0.04-0.07
			c0,0.01,0,0.02,0,0.01c0,0.01,0,0.03,0,0.05c0.01-0.07,0.02-0.08,0.03-0.12c0,0.06-0.01,0.08-0.02,0.14
			c0.01-0.05,0.02-0.05,0.02-0.1c0,0.04,0,0.02,0,0.07c0.01-0.08,0.01-0.01,0.02-0.06c0,0.04-0.01,0.08,0,0.05
			C40.25,36.34,40.24,36.37,40.24,36.37z M40.43,36.24L40.43,36.24C40.43,36.25,40.43,36.25,40.43,36.24
			C40.43,36.25,40.43,36.25,40.43,36.24z M40.45,36.25C40.45,36.24,40.45,36.24,40.45,36.25c-0.01-0.02,0-0.04,0-0.03
			C40.45,36.22,40.45,36.23,40.45,36.25z M40.45,35.92c0-0.01,0,0,0,0.01c0,0,0,0,0,0.01l0,0.01
			C40.45,35.93,40.45,35.92,40.45,35.92z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M40.44,35.98C40.44,35.98,40.44,35.98,40.44,35.98c0,0.01,0,0.01,0,0.01L40.44,35.98L40.44,35.98z"
        />
        <polygon
          style={{ fill: '#4E4742' }}
          points="41.76,36.23 41.76,36.23 41.75,36.24 		"
        />
        <polygon
          style={{ fill: '#4E4742' }}
          points="40.64,36.35 40.64,36.34 40.64,36.35 		"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M41.79,36.41c0,0,0,0.01,0,0.01C41.79,36.42,41.79,36.41,41.79,36.41z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M41.82,36.43l0,0.02C41.82,36.44,41.82,36.43,41.82,36.43z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M41.83,36.46c0-0.01,0.01-0.01,0.01-0.02l0,0L41.83,36.46z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M41.43,36.42C41.43,36.42,41.43,36.42,41.43,36.42C41.42,36.43,41.43,36.42,41.43,36.42z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M41.57,36.33c0,0.01-0.01,0.02-0.01,0.02C41.56,36.36,41.56,36.36,41.57,36.33z"
        />
        <polygon
          style={{ fill: '#4E4742' }}
          points="41.29,36.45 41.29,36.45 41.28,36.45 		"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M41.56,36.35C41.56,36.36,41.56,36.36,41.56,36.35C41.56,36.35,41.56,36.34,41.56,36.35z"
        />
        <polygon
          style={{ fill: '#4E4742' }}
          points="41.92,36.52 41.92,36.52 41.92,36.51 		"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M41.92,36.51L41.92,36.51C41.93,36.51,41.92,36.51,41.92,36.51z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M41.93,36.5L41.93,36.5C41.93,36.51,41.93,36.51,41.93,36.5z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M41.9,36.49l-0.01,0.02C41.89,36.5,41.9,36.49,41.9,36.49z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M41.89,36.49c0,0-0.01,0.04,0,0.03l0.01-0.01C41.88,36.52,41.9,36.46,41.89,36.49z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M41.93,36.27l0,0.02c0-0.02,0.01-0.04,0.02-0.04C41.95,36.19,41.93,36.28,41.93,36.27z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M41.91,36.24c0.01-0.01,0.01-0.03,0.01-0.03C41.91,36.22,41.91,36.22,41.91,36.24z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M41.7,36.18l0.01-0.04C41.71,36.14,41.71,36.15,41.7,36.18z"
        />
        <polygon
          style={{ fill: '#4E4742' }}
          points="41.61,36.39 41.61,36.41 41.62,36.37 		"
        />
        <polygon
          style={{ fill: '#4E4742' }}
          points="41.66,36.08 41.66,36.08 41.65,36.09 		"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M41.31,36.03l-0.01,0.05C41.31,36.08,41.32,36.02,41.31,36.03z"
        />
        <path
          style={{ fill: '#4E4742' }}
          d="M41.15,36.07c0-0.03,0-0.04-0.01-0.02C41.15,36.05,41.15,36.07,41.15,36.07z"
        />
        <polygon
          style={{ fill: '#4E4742' }}
          points="40.72,35.93 40.72,35.92 40.72,35.93 		"
        />
      </g>
    </g>
  </svg>
);

export default FunkyBadge;
