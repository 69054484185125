import React from 'react';
import { IconButton, Typography, Theme, makeStyles, createStyles, Tooltip } from '@material-ui/core';
import { Settings as SettingsIcon } from '@material-ui/icons';
import { Colors } from '../../themes';
import { getStringHash } from '../../helpers/numberHelpers';
import IUserSummary from '../../models/IUserSummary';
import IUser from '../../models/IUser';

interface UserCogProps {
  user: IUserSummary;
  isHighlighted: boolean | undefined;
  onClick: () => void;
}

interface StylingProps {
  textSize: number;
  rotation: number;
  userCogs: number;
  user: IUser;
  isHighlighted: boolean | undefined;
}

const magicNumberSpanScalar = 2.3;
const magicNumberSpanOffset = 2.5;

const spanCalc = (cogs: number) => {
  const span = Math.ceil(magicNumberSpanOffset + Math.sqrt(Math.max(cogs, 1) / Math.PI) * magicNumberSpanScalar);
  return span;
};

const calculateSize = (number: number, min: number, multiplier: number): number =>
  Math.sqrt(Math.max(number, min) / Math.PI) * multiplier;

const getRandomColor = (user: IUser) => {
  const noOfOptions = Object.keys(Colors).length;
  const hash = user.UserIdAsDecimal;
  const lastDigit = Math.floor(hash * noOfOptions);
  const colorKey = Object.keys(Colors)[lastDigit];

  return Colors[colorKey];
};

const useStyles = (props: StylingProps) =>
  makeStyles((theme: Theme) =>
    createStyles({
      iconContainer: {
        position: 'relative',
        textAlign: 'center',
        color: theme.palette.text.primary,
        transition: '0.15s ease-in-out',
        '&:hover': {
          transform: 'scale(1.3, 1.3) '
        }
      },
      name: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        fontWeight: 'bolder',
        fontSize: `${props.textSize}em`
      },
      settingsIcon: {
        transform: `rotate(${props.rotation}deg)`,
        transition: '1s ease-in-out',
        '&:hover': {
          transform: `rotate(${props.rotation + 180}deg)`
        },
        fontSize: calculateSize(props.userCogs, 10, 40),
        color: props.isHighlighted === false ? '#DDDDDD' : getRandomColor(props.user),
        stroke: 'black',
        strokeWidth: '1.5%'
      },
      userCogContainer: {
        gridColumn: `auto / span ${spanCalc(props.userCogs)}`,
        gridRow: `auto / span ${spanCalc(props.userCogs)}`
      }
    })
  );

const UserCog: React.FC<UserCogProps> = ({ user, isHighlighted, onClick }) => {
  const classes = useStyles({
    textSize: calculateSize(user.UserCogs.Cogs, 5, 0.35),
    rotation: 100 * Number(getStringHash(user.User.UserId).toString().slice(-1)),
    userCogs: user.UserCogs.Cogs,
    user: user.User,
    isHighlighted: isHighlighted
  })();

  return (
    <div key={user.User.UserId} className={classes.userCogContainer}>
      <Tooltip title={user.User.Name} arrow>
        <IconButton className={classes.iconContainer} onClick={onClick}>
          <Typography className={classes.name} variant="h6" component="p">
            {user.User.Initials}
          </Typography>
          <SettingsIcon className={classes.settingsIcon} />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default UserCog;
