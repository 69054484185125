import React, { useState } from 'react';
import Snowfall from 'react-snowfall';
import clsx from 'clsx';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { IS_IT_CHRISTMAS, IS_IT_EASTER } from '../../environment-variables';
import { drawerWidth, getDrawerHeaderStyle } from './styleHelpers';
import Santa from '../seasonal/santa';
import EasterScene from '../seasonal/easterscene';
import AppBar from './AppBar';
import Drawer from './Drawer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex'
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      marginLeft: -drawerWidth
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: 0
    },
    drawerHeader: getDrawerHeaderStyle(theme)
  })
);

const Layout: React.FC = ({ children }) => {
  const classes = useStyles();
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar isDrawerOpen={isDrawerOpen} onDrawerOpen={() => setIsDrawerOpen(true)} />
        <Drawer isDrawerOpen={isDrawerOpen} onDrawerClose={() => setIsDrawerOpen(false)} />
        <Container>
          <main
            className={clsx(classes.content, {
              [classes.contentShift]: isDrawerOpen
            })}
          >
            <div className={classes.drawerHeader} />
            {children}
            {IS_IT_CHRISTMAS && <Snowfall />}
          </main>
        </Container>
      </div>
      {IS_IT_CHRISTMAS && <Santa />}
      {IS_IT_EASTER && <EasterScene />}
    </>
  );
};

export default Layout;
