import React, { useState } from 'react';
import Bounce from 'react-reveal/Bounce';
import Wobble from 'react-reveal/Wobble';
import bunny from './images/bunny.png';
import sheep from './images/sheep.png';
import egg1 from './images/egg1.png';
import egg2 from './images/egg2.png';
import egg3 from './images/egg3.png';

const randomiseLocation = () => Math.floor(Math.random() * 80) + 15;

const EasterScene: React.FC = () => {
  const [location, setLocation] = useState(randomiseLocation);
  const [showBunny, setShowBunny] = useState(true);
  const [showBunnyGoingUp, setShowBunnyGoingUp] = useState(false);
  const [showSheep, setShowSheep] = useState(true);

  const eggLocations = [15, 20, 32, 45, 56, 67, 85, 90];

  const handleBunnyClick = async () => {
    await setShowBunnyGoingUp(false);
    await setShowBunny(false);
    setLocation(randomiseLocation);
    await new Promise(r => setTimeout(r, 1100));
    await setShowBunny(true);
  };

  const handleSheepClick = async () => {
    await setShowSheep(false);
    await setShowSheep(true);
  };

  return (
    <div>
      {eggLocations.map(x => (
        <div key={x} style={{ paddingTop: '2em' }}>
          <img
            alt="Easter Egg"
            src={egg1}
            style={{
              position: 'fixed',
              left: `${x}%`,
              bottom: '0%',
              right: '0'
            }}
          />
          <img
            alt="Easter Egg"
            src={egg2}
            style={{
              position: 'fixed',
              left: `${x - 10 * 1.3}%`,
              bottom: '0',
              right: '0'
            }}
          />
          <img
            alt="Easter Egg"
            src={egg3}
            style={{
              position: 'fixed',
              left: `${x - 10 * 1.6}%`,
              bottom: '0',
              right: '0'
            }}
          />
        </div>
      ))}

      {showBunny ? (
        <Bounce bottom when={!showBunnyGoingUp}>
          <img
            src={bunny}
            alt="Easter Bunny"
            onClick={async () => {
              setShowBunnyGoingUp(true);
              await new Promise(r => setTimeout(r, 1100));
              handleBunnyClick();
            }}
            style={{
              position: 'fixed',
              left: `${location}%`,
              bottom: '0',
              right: '0'
            }}
          />
        </Bounce>
      ) : (
        <Bounce top>
          <img
            alt="Easter Bunny"
            src={bunny}
            style={{
              position: 'fixed',
              left: `${location}%`,
              bottom: '0',
              right: '0'
            }}
          />
        </Bounce>
      )}
      {showSheep && (
        <Wobble>
          <img
            alt="A Sheep"
            src={sheep}
            onClick={() => {
              handleSheepClick();
            }}
            style={{
              position: 'fixed',
              left: '10%',
              bottom: '0',
              right: '0'
            }}
          />
        </Wobble>
      )}
    </div>
  );
};

export default EasterScene;
