import React, { useContext, useEffect } from 'react';
import { useGet } from './useApi';
import useApiErrorHandler from './useApiErrorHandler';
import { getAllUserMessageStatuses, getUserMeSummary, getAvatar } from '../api/endpoints';
import IAvatar from '../models/IAvatar';
import IMe from '../models/IMe';
import IMessageStatusesForUser from '../models/IMessageStatusesForUser';

interface AppBarContextValue {
  messageStatuses: IMessageStatusesForUser | undefined;
  refreshMessageStatuses: () => Promise<boolean>;
  areMessageStatusesLoading: boolean;
  avatar: IAvatar | undefined;
  refreshAvatar: () => Promise<boolean>;
}

interface AppBarProviderProps {
  children?: React.ReactNode;
}

const defaultAppBarContextValue: AppBarContextValue = {
  messageStatuses: undefined,
  refreshMessageStatuses: () => Promise.resolve(false),
  areMessageStatusesLoading: false,
  avatar: undefined,
  refreshAvatar: () => Promise.resolve(false)
};

const AppBarContext = React.createContext<AppBarContextValue>(defaultAppBarContextValue);

const AppBarProvider: React.FunctionComponent<AppBarProviderProps> = ({ children }: AppBarProviderProps) => {
  const { onApiError } = useApiErrorHandler();

  const [me] = useGet<IMe>(getUserMeSummary(), {
    onError: onApiError('me summary')
  });

  const [messageStatuses, areMessageStatusesLoading, refreshMessageStatuses] = useGet<IMessageStatusesForUser>(
    getAllUserMessageStatuses(me?.UserId || ''),
    {
      onError: onApiError('messages'),
      shouldGetImmediately: false
    }
  );

  const [avatar, , refreshAvatar] = useGet<IAvatar>(getAvatar(), {
    onError: onApiError('avatar')
  });

  useEffect(() => {
    const refresh = async () => await refreshMessageStatuses();

    if (me?.UserId) {
      refresh();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me?.UserId]);

  const contextValue: AppBarContextValue = {
    messageStatuses: messageStatuses,
    refreshMessageStatuses: refreshMessageStatuses,
    areMessageStatusesLoading: areMessageStatusesLoading,
    avatar: avatar,
    refreshAvatar: refreshAvatar
  };

  return <AppBarContext.Provider value={contextValue}>{children}</AppBarContext.Provider>;
};

const useAppBarContext = () => useContext(AppBarContext);

export { AppBarProvider };
export default useAppBarContext;
