import React, { FunctionComponent, useState, useEffect, useContext, ReactNode } from 'react';
import { IPublicClientApplication, AccountInfo, EventType, EventMessage } from '@azure/msal-browser';
import { MsalContext, IMsalContext } from './MsalContext';

export type MsalProviderProps = {
  instance: IPublicClientApplication;
  children?: ReactNode;
};

export const MsalProvider: FunctionComponent<MsalProviderProps> = ({ instance, children }: MsalProviderProps) => {
  const [accounts, setAccounts] = useState<AccountInfo[]>(instance.getAllAccounts());
  const [loginInProgress, setLoginInProgress] = useState<boolean>(false);

  useEffect(() => {
    instance.addEventCallback((message: EventMessage) => {
      switch (message.eventType) {
        case EventType.LOGIN_START:
        case EventType.SSO_SILENT_START:
        case EventType.HANDLE_REDIRECT_START:
          setLoginInProgress(true);
          break;
        case EventType.LOGIN_SUCCESS:
        case EventType.SSO_SILENT_SUCCESS:
          setAccounts(instance.getAllAccounts());
          setLoginInProgress(false);
          break;
        case EventType.LOGIN_FAILURE:
        case EventType.SSO_SILENT_FAILURE:
        case EventType.HANDLE_REDIRECT_END:
          setLoginInProgress(false);
          break;
        case EventType.ACQUIRE_TOKEN_FAILURE:
          //Detects session timeout
          if (message.error?.message !== undefined && message.error.message.indexOf('AADB2C90077') !== -1) {
            setLoginInProgress(true);
            instance.logout();
          } else {
            setLoginInProgress(false);
          }
          break;
        case EventType.ACQUIRE_TOKEN_SUCCESS:
        case EventType.LOGOUT_SUCCESS:
          setAccounts(instance.getAllAccounts());
          break;
      }
    });
  }, [instance]);

  const contextValue: IMsalContext = {
    instance,
    loginInProgress,
    accounts,
    primaryAccount: accounts && accounts[0]
  };

  return <MsalContext.Provider value={contextValue}>{children}</MsalContext.Provider>;
};

export const useMsal = () => useContext(MsalContext);
