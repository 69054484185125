import { API_URI } from '../environment-variables';

const getUser = (userId: string) => `${API_URI}/users/id/${userId}`;

const getUserMeSummary = () => `${API_URI}/users/me/summary`;

const getUserSummaries = () => `${API_URI}/users/summaries`;

const getUsers = () => `${API_URI}/users`;

const postUserCogsToPayee = (userId: string, payeeId: string, cogs: number) =>
  `${API_URI}/users/id/${userId}/ledgers/payable/to/${payeeId}/cogs/${cogs}`;

const getAllMyMessages = () => `${API_URI}/users/me/messages`;

const getAllUserMessageStatuses = (userId: string) => `${API_URI}/users/${userId}/all/message/statuses`;

const sendMessageFromPayerToPayee = (userId: string, payeeId: string, transactionId: string) =>
  `${API_URI}/users/${userId}/message/to/${payeeId}/transaction/${transactionId}`;

const updateMessageStatusUnread = (userId: string, messageId: string) =>
  `${API_URI}/users/${userId}/messages/id/${messageId}/unread`;

const updateMessageStatusRead = (userId: string, messageId: string) =>
  `${API_URI}/users/${userId}/messages/id/${messageId}/read`;

const getAllMyCogsSentHistory = () => `${API_URI}/users/me/cog/sent/history`;

const getAllBadges = () => `${API_URI}/badges/all`;

const getAvailableBadgesForUser = (userId: string) => `${API_URI}/badges/available/user/${userId}`;

const getBadge = (badgeId: string) => `${API_URI}/badges/id/${badgeId}`;

const assignBadge = (userId: string, badgeId: string) => `${API_URI}/users/${userId}/badges/${badgeId}`;

const getDepartments = () => `${API_URI}/departments/all`;

const getAvatar = () => `${API_URI}/users/me/avatar`;

const saveAvatar = (userId: string) => `${API_URI}/users/id/${userId}/avatar/save`;

export {
  getUser,
  getUserMeSummary,
  getUserSummaries,
  getUsers,
  postUserCogsToPayee,
  getAllMyMessages,
  getAllUserMessageStatuses,
  sendMessageFromPayerToPayee,
  updateMessageStatusUnread,
  updateMessageStatusRead,
  getAllMyCogsSentHistory,
  getAllBadges,
  getAvailableBadgesForUser,
  getBadge,
  assignBadge,
  getDepartments,
  getAvatar,
  saveAvatar
};
