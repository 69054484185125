import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  Avatar,
  AppBar as MaterialUiAppBar,
  FormGroup,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  Switch,
  Toolbar,
  Typography,
  Badge,
  Tooltip
} from '@material-ui/core';
import { Mail as MailIcon, Menu as MenuIcon, Error as ErrorIcon, AccountCircle } from '@material-ui/icons';
import { drawerWidth } from './styleHelpers';
import { Logo } from '../common';
import { useGet } from '../../hooks/useApi';
import { useThemeContext, useApiErrorHandler, useAppBarContext } from '../../hooks';
import { getUserMeSummary } from '../../api/endpoints';
import { useMsal } from '../../authentication/MsalProvider';
import IMe from '../../models/IMe';

export interface AppBarProps {
  isDrawerOpen: boolean;
  onDrawerOpen(): void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    username: {
      marginRight: theme.spacing(2)
    },
    menuButton: {
      marginRight: theme.spacing(2)
    },
    menuItem: {
      minHeight: '3.125em'
    },
    logoLink: {
      marginRight: 'auto',
      maxWidth: '20em'
    },
    hide: {
      display: 'none'
    }
  })
);

const AppBar: React.FC<AppBarProps> = ({ isDrawerOpen, onDrawerOpen }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { onApiError } = useApiErrorHandler();
  const { isDarkTheme, setIsDarkTheme } = useThemeContext();
  const { messageStatuses, avatar } = useAppBarContext();
  const { primaryAccount } = useMsal();
  const classes = useStyles();

  const [me] = useGet<IMe>(getUserMeSummary(), {
    onError: onApiError('me summary')
  });

  const handleMenuChange = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  return (
    <MaterialUiAppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: isDrawerOpen
      })}
    >
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          onClick={onDrawerOpen}
          className={clsx(classes.menuButton, isDrawerOpen && classes.hide)}
        >
          <MenuIcon />
        </IconButton>
        <Link to="/" className={classes.logoLink}>
          <Logo height="100%" width="60%" />
        </Link>
        <div>
          {me?.UserSummary.User.DepartmentName === null && (
            <Tooltip title="Please check your user details.">
              <IconButton component={Link} to="/my-details">
                <ErrorIcon color="error" />
              </IconButton>
            </Tooltip>
          )}
          <IconButton onClick={handleMenuChange} color="inherit">
            {primaryAccount && <Typography className={classes.username}>{primaryAccount.name}</Typography>}
            {avatar && avatar.Base64String ? (
              <Avatar src={`data:image/png;base64,${avatar?.Base64String}`} alt="Current user's avatar" />
            ) : (
              <AccountCircle />
            )}
          </IconButton>
          <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            <MenuItem className={classes.menuItem} onClick={handleClose} component={Link} to="/my-messages">
              My Messages
            </MenuItem>
            <MenuItem className={classes.menuItem} onClick={handleClose} component={Link} to="/my-details">
              My Details
            </MenuItem>
            <MenuItem className={classes.menuItem} onClick={handleClose} component={Link} to="/my-cog-sent-history">
              My Cog History
            </MenuItem>
            <MenuItem className={classes.menuItem}>
              <FormGroup>
                <FormControlLabel
                  control={<Switch checked={isDarkTheme} onChange={() => setIsDarkTheme(!isDarkTheme)} />}
                  label="Dark Mode"
                />
              </FormGroup>
            </MenuItem>
          </Menu>
          <IconButton component={Link} to="/my-messages" color="inherit">
            <Badge badgeContent={messageStatuses?.NumberOfUnreadMessages} max={99} color="error">
              <MailIcon />
            </Badge>
          </IconButton>
        </div>
      </Toolbar>
    </MaterialUiAppBar>
  );
};

export default AppBar;
