import { useState, useEffect } from 'react';

const useDebounce = <T>(value: T, delay: number): [T] => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const debounce = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(debounce);
    };

    // Only re-call effect if value changes.
    // You could also add the "delay" var to inputs array if you
    // need to be able to change that dynamically.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return [debouncedValue];
};

export default useDebounce;
