import React from 'react';
import { createStyles, Grid, makeStyles, TextField, Typography, Theme } from '@material-ui/core';
import DepartmentFilter from './DepartmentFilter';
import { Title } from '../typography';
import { CogsBadge } from '../common';
import IDepartment from '../../models/IDepartment';

interface HeaderGridProps {
  noOfUsers: number;
  departments: IDepartment[] | null;
  searchName: string;
  noOfCogs: number | undefined;
  isMeLoading: boolean;
  areDepartmentsLoading: boolean;
  onSearchNameChanged: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onDepartmentChange: (currentDepartment: string | null) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputLabel: {
      '& label': {
        color: theme.palette.primary.main
      }
    }
  })
);

const HeaderGrid: React.FC<HeaderGridProps> = ({
  noOfUsers,
  departments,
  searchName,
  noOfCogs,
  isMeLoading,
  areDepartmentsLoading,
  onSearchNameChanged,
  onDepartmentChange
}) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid container item xs={10}>
        <Grid container item xs={12}>
          <Grid item xs={4}>
            <Title text="Give Cogs" />
          </Grid>
          <Grid item xs={4}>
            {noOfUsers > 0 && (
              <TextField
                id="searchName"
                label="Find somebody"
                type="search"
                onChange={onSearchNameChanged}
                value={searchName}
                className={classes.inputLabel}
              />
            )}
          </Grid>
          <Grid item xs={4}>
            {noOfUsers > 0 && (
              <DepartmentFilter
                departments={departments}
                areDepartmentsLoading={areDepartmentsLoading}
                onCurrentDepartment={onDepartmentChange}
              />
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography gutterBottom>Make somebody's day by sending them a Cog to say "thank you!"</Typography>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <CogsBadge
          noOfCogs={noOfCogs}
          isLoading={isMeLoading}
          tooltipTitle="You've got this many Cogs left to give out this month! You'll get some more next month."
        />
      </Grid>
    </Grid>
  );
};

export default HeaderGrid;
