import React from 'react';
import { BadgeProps, inactiveColor, inactiveColorDark } from './badgeHelpers';

const CogKarmaBadge: React.FC<BadgeProps> = ({ isActive }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 56.69 56.69"
    xmlSpace="preserve"
  >
    <g>
      <circle
        style={{ fill: isActive ? '#E66459' : inactiveColorDark }}
        cx="29"
        cy="48.8"
        r="5.58"
      />
      <circle
        style={{
          fill: 'none',
          stroke: '#3E3C3C',
          strokeWidth: 0.5,
          strokeMiterlimit: 10
        }}
        cx="29"
        cy="48.8"
        r="5.58"
      />
      <path
        style={{ fill: isActive ? '#FFD740' : inactiveColor }}
        d="M28.86,2.39c-10.11,0-18.31,8.2-18.31,18.31c0,5.78,2.68,10.93,6.86,14.29l0.52,0.4
		c3.12,2.52,5.39,6.04,6.33,10.07h9.25c0.97-4.18,3.38-7.81,6.67-10.35l0.41-0.35c4.02-3.36,6.58-8.41,6.58-14.06
		C47.18,10.59,38.98,2.39,28.86,2.39z"
      />
      <path
        style={{
          fill: 'none',
          stroke: '#3E3C3C',
          strokeWidth: 0.5,
          strokeMiterlimit: 10
        }}
        d="M28.86,2.39c-10.11,0-18.31,8.2-18.31,18.31c0,5.78,2.68,10.93,6.86,14.29l0.52,0.4
		c3.12,2.52,5.39,6.04,6.33,10.07h9.25c0.97-4.18,3.38-7.81,6.67-10.35l0.41-0.35c4.02-3.36,6.58-8.41,6.58-14.06
		C47.18,10.59,38.98,2.39,28.86,2.39z"
      />
      <path
        style={{ fill: '#3E3C3C' }}
        d="M34.25,46.56H23.47c-0.92,0-1.67-0.75-1.67-1.67v0c0-0.92,0.75-1.67,1.67-1.67h10.78
		c0.92,0,1.67,0.75,1.67,1.67v0C35.92,45.81,35.17,46.56,34.25,46.56z"
      />
      <path
        style={{ fill: '#C6C7C6' }}
        d="M34.25,49.89H23.47c-0.92,0-1.67-0.75-1.67-1.67v0c0-0.92,0.75-1.67,1.67-1.67h10.78
		c0.92,0,1.67,0.75,1.67,1.67v0C35.92,49.14,35.17,49.89,34.25,49.89z"
      />
      <path
        style={{
          fill: 'none',
          stroke: '#3E3C3C',
          strokeWidth: 0.5,
          strokeMiterlimit: 10
        }}
        d="M34.25,46.56H23.47c-0.92,0-1.67-0.75-1.67-1.67v0c0-0.92,0.75-1.67,1.67-1.67h10.78
		c0.92,0,1.67,0.75,1.67,1.67v0C35.92,45.81,35.17,46.56,34.25,46.56z"
      />
      <path
        style={{
          fill: 'none',
          stroke: '#3E3C3C',
          strokeWidth: 0.5,
          strokeMiterlimit: 10
        }}
        d="M34.25,49.89H23.47c-0.92,0-1.67-0.75-1.67-1.67v0c0-0.92,0.75-1.67,1.67-1.67h10.78
		c0.92,0,1.67,0.75,1.67,1.67v0C35.92,49.14,35.17,49.89,34.25,49.89z"
      />
      <ellipse
        transform="matrix(0.7907 -0.6122 0.6122 0.7907 -1.603 23.9345)"
        style={{ fill: '#FDF8F6' }}
        cx="34.21"
        cy="14.31"
        rx="6.43"
        ry="7.36"
      />
    </g>
  </svg>
);

export default CogKarmaBadge;
