import React from 'react';
import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import { useGet, useGetAndUpdate } from '../hooks/useApi';
import { useApiErrorHandler } from '../hooks';
import * as uris from '../api/endpoints';
import IBadge from '../models/IBadge';

const BadgeManagement = () => {
  const { onApiError } = useApiErrorHandler();

  const [allBadges, isLoading] = useGet<IBadge[]>(uris.getAllBadges(), {
    onError: onApiError('badges')
  });

  return (
    <>
      <Typography variant="h2">Add or Edit Badge Definitions</Typography>
      <Typography>
        For an Admin to create new badges, or change the threshold at which they
        are achieved.
      </Typography>

      {isLoading && <CircularProgress />}
      {!isLoading &&
        allBadges &&
        allBadges.map(badge => (
          <Grid key={badge.BadgeId}>
            <Typography variant="h4">{badge.Name}</Typography>
            <Typography variant="h5">{badge.Description}</Typography>
          </Grid>
        ))}
    </>
  );
};

interface EditBadgeProps {
  badgeId: string;
}

const EditBadge: React.FC<EditBadgeProps> = ({ badgeId }) => {
  const { onApiError } = useApiErrorHandler();

  const [
    badge,
    setBadge,
    isLoading,
    ,
    putBadge
  ] = useGetAndUpdate<IBadge | null>(uris.getBadge(badgeId), {
    onError: onApiError('badges')
  });

  if (isLoading || !badge) return <CircularProgress />;

  return (
    <>
      <TextField
        label="A short name"
        value={badge.Name}
        onChange={e => setBadge({ ...badge, Name: e.target.value })}
      />
      <TextField
        label="A nice long description of the badge"
        value={badge.Description}
        onChange={e => setBadge({ ...badge, Description: e.target.value })}
      />
      <TextField
        label="Automatically apply when somebody achieves this many Cogs..."
        value={badge.MinCogsThreshold}
        onChange={e =>
          setBadge({ ...badge, MinCogsThreshold: parseInt(e.target.value) })
        }
      />

      <Button onClick={async () => putBadge}>Update</Button>
    </>
  );
};

export default BadgeManagement;
