import React, { useState } from 'react';
import { createStyles, makeStyles, FormControl, InputLabel, Select, MenuItem, Theme } from '@material-ui/core';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '../services/appInsights';
import { useGet } from '../hooks/useApi';
import { useApiErrorHandler } from '../hooks';
import { getAllMyMessages } from '../api/endpoints';
import { LoadingIndicator } from '../features/common';
import { Title } from '../features/typography';
import MessageTable from '../features/messages/MessageTable';
import IMessagesForUser from '../models/IMessagesForUser';

const selectOptions = [
  { value: 10, label: 'Ten' },
  { value: 20, label: 'Twenty' },
  { value: 32768, label: 'All' }
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageContent: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    formControl: {
      width: '10%'
    },
    formLabel: {
      color: theme.palette.primary.main
    }
  })
);

const MyMessages = () => {
  const classes = useStyles();
  const { onApiError } = useApiErrorHandler();
  const [maxRows, setMaxRows] = useState<number>(10);
  const [messages, isLoadingMessages] = useGet<IMessagesForUser>(getAllMyMessages(), { onError: onApiError('messages') });

  return (
    <div className={classes.pageContent}>
      <LoadingIndicator isLoading={isLoadingMessages} />
      <Title text="My Messages" />
      {messages && (
        <>
          <FormControl className={classes.formControl}>
            <InputLabel id="rowsLabel" className={classes.formLabel}>
              Rows Per Page
            </InputLabel>
            <Select labelId="rowsLabel" value={maxRows} onChange={e => setMaxRows(e.target.value as number)}>
              {selectOptions.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <MessageTable messages={messages?.Messages || []} maxRows={maxRows} />
        </>
      )}
    </div>
  );
};

export default withAITracking(reactPlugin, MyMessages, 'My Messages Page');
