import React from 'react';
import { createStyles, makeStyles, FormControl, InputLabel, MenuItem, Select, Theme } from '@material-ui/core';
import IBadge, { BadgeType } from '../../models/IBadge';
import IUserSummary from '../../models/IUserSummary';
import { SmallLoadingIndicator } from '../../features/common';

interface AssignBadgeFormProps {
  payee: IUserSummary;
  areAvailableBadgesLoading: boolean;
  availableBadges: IBadge[];
  currentBadge: IBadge | undefined;
  onBadgeSelect: (badge: IBadge | undefined) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: '1em',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    formControl: {
      width: '85%'
    },
    formLabel: {
      color: theme.palette.primary.main
    }
  })
);

const AssignBadgeForm: React.FC<AssignBadgeFormProps> = ({
  payee,
  areAvailableBadgesLoading,
  availableBadges,
  currentBadge,
  onBadgeSelect
}) => {
  const classes = useStyles();

  const handleSelect = (event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) =>
    onBadgeSelect(availableBadges?.find(badge => badge.BadgeId === event.target.value));

  const filteredBadges = availableBadges.filter(
    badge => badge.BadgeType === BadgeType.AdHoc && !payee.User.BadgeIds.some(badgeId => badgeId === badge.BadgeId)
  );

  return (
    <div className={classes.container}>
      <FormControl className={classes.formControl}>
        <InputLabel id="badgeLabel" className={classes.formLabel}>
          Assign a badge (optional)
        </InputLabel>
        <Select
          labelId="badgeLabel"
          value={currentBadge?.BadgeId || ''}
          onChange={handleSelect}
          disabled={areAvailableBadgesLoading || filteredBadges.length === 0}
        >
          {filteredBadges.map(badge => (
            <MenuItem key={badge.BadgeId} value={badge.BadgeId}>
              {badge.Name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <SmallLoadingIndicator isLoading={areAvailableBadgesLoading} />
    </div>
  );
};

export default AssignBadgeForm;
