import React from 'react';
import { createStyles, makeStyles, TableCell, TableRow, Typography } from '@material-ui/core';
import ICogSentHistory from '../../models/ICogSentHistory';

interface CogSentHistoryRowProps {
  cogSentHistory: ICogSentHistory;
}

const useStyles = makeStyles(() =>
  createStyles({
    noMessage: {
      fontStyle: 'italic',
      fontSize: '1em'
    }
  })
);

const CogSentHistoryRow: React.FC<CogSentHistoryRowProps> = ({ cogSentHistory }) => {
  const classes = useStyles();

  const getMessageRowText = () => {
    if (cogSentHistory.Body !== null) {
      return <Typography>{cogSentHistory.Body}</Typography>;
    }
    return <Typography className={classes.noMessage}>No message sent with cog</Typography>;
  };

  return (
    <TableRow>
      <TableCell>{new Date(cogSentHistory.DateSent).toLocaleDateString('en-GB', { timeZone: 'UTC' })}</TableCell>
      <TableCell>{cogSentHistory.PayeeName}</TableCell>
      <TableCell>{getMessageRowText()}</TableCell>
    </TableRow>
  );
};

export default CogSentHistoryRow;
