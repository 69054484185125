import { useSnackbar } from 'notistack';
import { appInsights } from '../services/appInsights';

const useApiErrorHandler = () => {
  const { enqueueSnackbar } = useSnackbar();

  const onApiError = (operationName: string) => {
    const onError = (httpStatusCode: number, resultBody?: string) => {
      appInsights.trackException({
        error: new Error(`API error for ${operationName}, HTTP status code ${httpStatusCode} and result body ${resultBody}.`)
      });

      switch (httpStatusCode) {
        case -1:
          enqueueSnackbar(`Couldn't contact the server for ${operationName}`, {
            variant: 'error'
          });
          break;
        case 400:
          enqueueSnackbar(`Sorry the server could not accept the request for ${operationName}`, {
            variant: 'error'
          });
          break;
        case 401:
          enqueueSnackbar(`Sorry you are not logged in. Please log in to access ${operationName}`, {
            variant: 'error'
          });
          break;
        case 403:
          enqueueSnackbar(`Sorry you do not have the correct permissions for ${operationName}`, {
            variant: 'error'
          });
          break;
        case 404:
          enqueueSnackbar(`Sorry we couldn't find the information for ${operationName}`, {
            variant: 'error'
          });
          break;
        case 409:
          enqueueSnackbar(`Somebody else has already updated that information. Please can you try again?`, {
            variant: 'warning'
          });
          break;
        case 422:
          enqueueSnackbar(`${operationName} can't be updated because some fields aren't valid`, { variant: 'error' });
          break;
        case 500:
          enqueueSnackbar(`The server was unable to handle the situation`, {
            variant: 'error'
          });
          break;
        default:
          enqueueSnackbar(`Something went wrong when trying to access ${operationName}`, { variant: 'error' });
          break;
      }
    };

    return onError;
  };

  return { onApiError };
};

export default useApiErrorHandler;
