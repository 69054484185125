declare global {
  interface Window {
    __gearbox_env: {
      TENANT_ID: string;
      CLIENT_ID: string;
      REDIRECT_URI: string;
      API_URI: string;
      API_SCOPE: string;
      APPINSIGHTS_KEY: string;
      IS_IT_CHRISTMAS: boolean;
      IS_IT_EASTER: boolean;
    };
  }
}

const TENANT_ID = window.__gearbox_env.TENANT_ID;
const CLIENT_ID = window.__gearbox_env.CLIENT_ID;
const REDIRECT_URI = window.__gearbox_env.REDIRECT_URI;
const API_URI = window.__gearbox_env.API_URI;
const API_SCOPE = window.__gearbox_env.API_SCOPE;
const APPINSIGHTS_KEY = window.__gearbox_env.APPINSIGHTS_KEY;
const IS_IT_CHRISTMAS = window.__gearbox_env.IS_IT_CHRISTMAS;
const IS_IT_EASTER = window.__gearbox_env.IS_IT_EASTER;

export { TENANT_ID, CLIENT_ID, REDIRECT_URI, API_URI, API_SCOPE, APPINSIGHTS_KEY, IS_IT_CHRISTMAS, IS_IT_EASTER };
