import React from 'react';
import { BadgeProps, inactiveColor, inactiveColorDark } from './badgeHelpers';

const FirstCogBadge: React.FC<BadgeProps> = ({ isActive }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 56.69 56.69"
    xmlSpace="preserve"
  >
    <polygon
      style={{ fill: isActive ? '#FFD82C' : inactiveColor }}
      points="33.33,54.94 28.29,47.71 23.09,54.94 23.09,30.31 33.33,30.31 "
    />
    <polygon
      style={{ fill: isActive ? '#FFCE00' : inactiveColor }}
      points="28.57,30.6 28.57,48.45 28.6,47.71 33.14,54.94 33.14,30.6 "
    />
    <path
      style={{ fill: isActive ? '#799D49' : inactiveColorDark }}
      d="M44,20.09c-0.23-0.2-0.5-0.36-0.8-0.49c0-0.13,0.01-0.26,0.01-0.39c0-0.13-0.01-0.26-0.01-0.39
	c0.29-0.13,0.56-0.29,0.8-0.49c0.34-0.29,0.64-0.65,0.79-1.05c0.15-0.4,0.19-0.84,0.12-1.28c-0.02-0.11-0.04-0.22-0.07-0.32
	c-0.04-0.1-0.09-0.2-0.14-0.3c-0.1-0.19-0.23-0.37-0.38-0.53c-0.3-0.31-0.64-0.56-1.09-0.7c-0.29-0.1-0.6-0.15-0.93-0.16
	c-0.09-0.25-0.18-0.49-0.28-0.73c0.23-0.23,0.43-0.48,0.57-0.75c0.21-0.39,0.33-0.83,0.34-1.26c0.02-0.44-0.13-0.85-0.35-1.23
	c-0.12-0.19-0.25-0.36-0.41-0.51c-0.16-0.14-0.35-0.26-0.55-0.35c-0.39-0.19-0.82-0.27-1.27-0.26c-0.31,0.01-0.62,0.08-0.93,0.18
	c-0.17-0.2-0.35-0.39-0.52-0.58c0.13-0.3,0.23-0.6,0.27-0.91c0.05-0.44,0.01-0.88-0.14-1.29c-0.08-0.2-0.17-0.4-0.3-0.57
	c-0.13-0.17-0.29-0.32-0.47-0.45c-0.7-0.56-1.66-0.59-2.48-0.24c-0.28,0.11-0.55,0.29-0.8,0.5c-0.23-0.12-0.46-0.24-0.7-0.35
	c0.01-0.32-0.01-0.64-0.08-0.94c-0.1-0.45-0.31-0.82-0.6-1.15c-0.29-0.32-0.65-0.59-1.09-0.69c-0.43-0.11-0.87-0.11-1.28,0
	c-0.41,0.13-0.8,0.38-1.12,0.69c-0.22,0.21-0.4,0.47-0.56,0.75c-0.26-0.03-0.52-0.05-0.78-0.07c-0.1-0.3-0.24-0.59-0.41-0.84
	c-0.51-0.78-1.34-1.17-2.23-1.12c-0.44,0.03-0.85,0.23-1.19,0.48c-0.34,0.26-0.62,0.59-0.8,1.02c-0.12,0.28-0.2,0.58-0.25,0.9
	c-0.25,0.06-0.5,0.14-0.75,0.21c-0.21-0.25-0.44-0.47-0.69-0.63c-0.37-0.25-0.79-0.41-1.22-0.46c-0.43-0.05-0.85,0.05-1.26,0.24
	c-0.2,0.1-0.39,0.21-0.54,0.36c-0.15,0.15-0.29,0.33-0.4,0.51c-0.22,0.37-0.35,0.79-0.38,1.24c-0.01,0.31,0.03,0.63,0.1,0.95
	c-0.21,0.15-0.42,0.31-0.62,0.47c-0.29-0.16-0.58-0.28-0.88-0.35c-0.86-0.18-1.81,0.01-2.39,0.69c-0.63,0.64-0.73,1.6-0.47,2.44
	c0.09,0.29,0.24,0.57,0.42,0.84c-0.14,0.22-0.28,0.44-0.41,0.66c-0.32-0.05-0.64-0.05-0.95-0.01c-0.45,0.07-0.85,0.24-1.2,0.49
	c-0.35,0.25-0.64,0.59-0.78,1.02c-0.15,0.42-0.2,0.86-0.12,1.27c0.1,0.43,0.3,0.83,0.58,1.18c0.19,0.24,0.43,0.45,0.69,0.63
	c-0.05,0.25-0.1,0.51-0.14,0.77c-0.31,0.08-0.61,0.18-0.87,0.33c-0.2,0.11-0.39,0.24-0.55,0.39c-0.16,0.15-0.29,0.32-0.4,0.5
	c-0.22,0.36-0.36,0.79-0.38,1.23c0.02,0.44,0.15,0.87,0.38,1.23c0.11,0.18,0.24,0.35,0.4,0.5c0.17,0.15,0.35,0.28,0.55,0.39
	c0.27,0.15,0.56,0.26,0.87,0.33c0.04,0.26,0.09,0.51,0.14,0.77c-0.27,0.18-0.5,0.39-0.69,0.63c-0.28,0.35-0.48,0.75-0.58,1.18
	c-0.08,0.42-0.03,0.86,0.12,1.27c0.14,0.42,0.43,0.76,0.78,1.02c0.35,0.25,0.75,0.43,1.2,0.49c0.31,0.04,0.63,0.03,0.95-0.01
	c0.13,0.22,0.27,0.45,0.41,0.66c-0.19,0.27-0.33,0.55-0.42,0.84c-0.27,0.84-0.16,1.8,0.47,2.44c0.58,0.68,1.53,0.87,2.39,0.69
	c0.3-0.07,0.59-0.19,0.88-0.35c0.2,0.16,0.41,0.32,0.62,0.47c-0.07,0.32-0.11,0.63-0.1,0.95c0.03,0.44,0.16,0.86,0.38,1.24
	c0.11,0.19,0.24,0.36,0.4,0.51c0.16,0.15,0.34,0.26,0.54,0.36c0.4,0.19,0.83,0.29,1.26,0.24c0.43-0.06,0.85-0.21,1.22-0.46
	c0.25-0.16,0.48-0.38,0.69-0.63c0.25,0.08,0.5,0.15,0.75,0.21c0.05,0.32,0.13,0.62,0.25,0.9c0.18,0.43,0.46,0.77,0.8,1.02
	c0.34,0.25,0.75,0.45,1.19,0.48c0.89,0.05,1.72-0.34,2.23-1.12c0.17-0.25,0.31-0.53,0.41-0.84c0.26-0.02,0.52-0.04,0.78-0.07
	c0.16,0.28,0.34,0.53,0.56,0.75c0.32,0.32,0.7,0.56,1.12,0.69c0.41,0.11,0.85,0.11,1.28,0c0.44-0.1,0.8-0.37,1.09-0.69
	c0.28-0.33,0.5-0.7,0.6-1.15c0.07-0.3,0.09-0.62,0.08-0.94c0.24-0.11,0.47-0.23,0.7-0.35c0.25,0.21,0.52,0.38,0.8,0.5
	c0.82,0.35,1.78,0.32,2.48-0.24c0.18-0.13,0.33-0.29,0.47-0.45c0.13-0.17,0.22-0.37,0.3-0.57c0.15-0.41,0.2-0.85,0.14-1.29
	c-0.04-0.3-0.13-0.61-0.27-0.91c0.18-0.19,0.35-0.38,0.52-0.58c0.31,0.1,0.62,0.17,0.93,0.18c0.44,0.01,0.88-0.08,1.27-0.26
	c0.19-0.09,0.38-0.21,0.55-0.35c0.15-0.15,0.29-0.32,0.41-0.51c0.22-0.39,0.37-0.79,0.35-1.23c-0.01-0.44-0.13-0.87-0.34-1.26
	c-0.14-0.27-0.33-0.52-0.57-0.75c0.1-0.24,0.19-0.48,0.28-0.73c0.32-0.02,0.63-0.07,0.93-0.16c0.45-0.14,0.79-0.39,1.09-0.7
	c0.15-0.16,0.27-0.33,0.38-0.53c0.05-0.1,0.1-0.19,0.14-0.3c0.03-0.1,0.06-0.21,0.07-0.32c0.07-0.44,0.02-0.88-0.12-1.28
	C44.64,20.74,44.34,20.38,44,20.09z"
    />
    <path
      style={{ fill: isActive ? '#91BE63' : inactiveColorDark }}
      d="M28.06,2.21c-0.51-0.27-1.16-0.4-1.69-0.38c-0.44,0.03-0.96,0.23-1.3,0.48c-0.34,0.26-0.67,0.59-0.85,1.02
	c-0.12,0.28-0.23,0.58-0.28,0.9c-0.25,0.06-0.52,0.14-0.77,0.21c-0.21-0.25-0.45-0.47-0.7-0.63c-0.37-0.25-0.8-0.41-1.23-0.46
	c-0.43-0.05-0.86,0.05-1.26,0.24c-0.2,0.1-0.39,0.21-0.54,0.36c-0.15,0.15-0.29,0.33-0.4,0.51c-0.22,0.37-0.35,0.79-0.38,1.24
	c-0.01,0.31,0.03,0.63,0.1,0.95c-0.21,0.15-0.42,0.31-0.62,0.47c-0.29-0.16-0.58-0.28-0.88-0.35c-0.86-0.18-1.81,0.01-2.39,0.69
	c-0.63,0.64-0.73,1.6-0.47,2.44c0.09,0.29,0.24,0.57,0.42,0.84c-0.14,0.22-0.28,0.44-0.41,0.66c-0.32-0.05-0.64-0.05-0.95-0.01
	c-0.45,0.07-0.85,0.24-1.2,0.49c-0.35,0.25-0.64,0.59-0.78,1.02c-0.15,0.42-0.2,0.86-0.12,1.27c0.1,0.43,0.3,0.83,0.58,1.18
	c0.19,0.24,0.43,0.45,0.69,0.63c-0.05,0.25-0.1,0.51-0.14,0.77c-0.31,0.08-0.61,0.18-0.87,0.33c-0.2,0.11-0.39,0.24-0.55,0.39
	c-0.16,0.15-0.29,0.32-0.4,0.5c-0.22,0.36-0.36,0.79-0.38,1.23c0.02,0.44,0.15,0.87,0.38,1.23c0.11,0.18,0.24,0.35,0.4,0.5
	c0.17,0.15,0.35,0.28,0.55,0.39c0.27,0.15,0.56,0.26,0.87,0.33c0.04,0.26,0.09,0.51,0.14,0.77c-0.27,0.18-0.5,0.39-0.69,0.63
	c-0.28,0.35-0.48,0.75-0.58,1.18c-0.08,0.42-0.03,0.86,0.12,1.27c0.14,0.42,0.43,0.76,0.78,1.02c0.35,0.25,0.75,0.43,1.2,0.49
	c0.31,0.04,0.63,0.03,0.95-0.01c0.13,0.22,0.27,0.45,0.41,0.66c-0.19,0.27-0.33,0.55-0.42,0.84c-0.27,0.84-0.16,1.8,0.47,2.44
	c0.58,0.68,1.53,0.87,2.39,0.69c0.3-0.07,0.59-0.19,0.88-0.35c0.2,0.16,0.41,0.32,0.62,0.47c-0.07,0.32-0.11,0.63-0.1,0.95
	c0.03,0.44,0.16,0.86,0.38,1.24c0.11,0.19,0.24,0.36,0.4,0.51c0.16,0.15,0.34,0.26,0.54,0.36c0.4,0.19,0.83,0.29,1.26,0.24
	c0.43-0.06,0.85-0.21,1.22-0.46c0.25-0.16,0.48-0.38,0.69-0.63c0.25,0.08,0.5,0.15,0.75,0.21c0.05,0.32,0.13,0.62,0.25,0.9
	c0.18,0.43,0.46,0.77,0.8,1.02c0.34,0.25,0.97,0.45,1.41,0.48c0.53,0.03,1.18-0.1,1.69-0.38V2.21z"
    />
    <circle
      style={{ fill: isActive ? '#FFD82C' : inactiveColor }}
      cx="27.76"
      cy="19.21"
      r="12.41"
    />
    <path
      style={{ fill: isActive ? '#FFCE00' : inactiveColor }}
      d="M27.76,6.8c6.85,0,12.41,5.56,12.41,12.41s-5.56,12.41-12.41,12.41"
    />
    <path
      style={{ fill: isActive ? '#385C3B' : inactiveColorDark }}
      d="M20.66,21.45c0.26-0.08,0.54-0.11,0.82-0.14c0.28-0.02,0.56-0.01,0.84,0.01c0.57,0.05,1.14,0.22,1.66,0.51
	c0.52,0.29,0.99,0.69,1.35,1.18c0.18,0.24,0.34,0.5,0.47,0.77c0.14,0.27,0.25,0.55,0.35,0.83l-1.17-0.04c0.09-0.35,0.17-0.67,0.27-1
	c0.1-0.33,0.2-0.66,0.31-0.98c0.11-0.32,0.23-0.65,0.35-0.96c0.13-0.32,0.25-0.64,0.39-0.95c0.27-0.63,0.58-1.25,0.9-1.85
	c0.17-0.3,0.34-0.6,0.51-0.9c0.17-0.3,0.37-0.59,0.56-0.88c0.39-0.57,0.81-1.13,1.26-1.66c0.23-0.26,0.47-0.52,0.71-0.77
	c0.24-0.25,0.51-0.48,0.77-0.72c0.27-0.23,0.55-0.44,0.84-0.64c0.29-0.2,0.59-0.39,0.91-0.54c0.31-0.17,0.64-0.3,0.97-0.41
	c0.17-0.05,0.33-0.11,0.5-0.14c0.17-0.03,0.34-0.06,0.51-0.09l-0.42,0.29l-0.4,0.3c-0.13,0.09-0.25,0.22-0.38,0.32
	c-0.13,0.1-0.24,0.22-0.36,0.33c-0.12,0.11-0.23,0.22-0.34,0.35c-0.11,0.12-0.23,0.23-0.33,0.35l-0.32,0.36l-0.3,0.38
	c-0.2,0.25-0.39,0.51-0.58,0.77c-0.19,0.26-0.37,0.53-0.55,0.79l-0.52,0.82c-0.18,0.27-0.33,0.56-0.51,0.83
	c-0.33,0.56-0.65,1.13-0.96,1.7c-0.32,0.57-0.61,1.15-0.92,1.74c-0.3,0.58-0.59,1.17-0.88,1.76l-0.43,0.89l-0.42,0.89l-0.82,1.72
	l-0.36-1.75c-0.05-0.24-0.1-0.48-0.18-0.71c-0.08-0.23-0.17-0.45-0.29-0.67c-0.23-0.42-0.53-0.81-0.92-1.12
	c-0.19-0.16-0.4-0.29-0.63-0.41c-0.22-0.12-0.46-0.23-0.71-0.31C21.74,21.57,21.21,21.47,20.66,21.45z"
    />
  </svg>
);

export default FirstCogBadge;
