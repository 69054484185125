import React, { useEffect, useState, useRef } from 'react';
import AvatarGenerator from 'react-avatar-generator';
import TinyColor from 'tinycolor2';
import { ColorResult, CirclePicker } from 'react-color';
import { useSnackbar } from 'notistack';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Theme,
  Typography,
  createStyles,
  makeStyles
} from '@material-ui/core';
import { saveAvatar } from '../../../api/endpoints';
import { usePostWith } from '../../../hooks/useApi';
import { useApiErrorHandler, useAppBarContext } from '../../../hooks';

const colorPickerColors = [
  '#e91e63',
  '#9c27b0',
  '#550091',
  '#673ab7',
  '#3f51b5',
  '#2196f3',
  '#03a9f4',
  '#00bcd4',
  '#009688',
  '#018a23',
  '#4caf50',
  '#8bc34a',
  '#ffeb3b',
  '#ffc107',
  '#ff9800',
  '#ff5722',
  '#f44336',
  '#000000'
];

enum AvatarShape {
  CIRCLE = 'circle',
  TRIANGLE = 'triangle',
  SQUARE = 'square'
}

interface AvatarCreatorProps {
  userId: string;
}

interface AvatarProps {
  shape: AvatarShape;
  backgroundColor: string;
  colors: string[];
}

const defaultAvatarProps: AvatarProps = {
  shape: AvatarShape.CIRCLE,
  backgroundColor: '#000',
  colors: ['#454545', '#5c5c5c', '#949494']
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: '5em'
    },
    selectGrid: {
      minWidth: '60%'
    },
    label: {
      color: theme.palette.primary.main
    },
    randomiseButton: {
      marginRight: '1em'
    }
  })
);

const AvatarCreator: React.FC<AvatarCreatorProps> = ({ userId }) => {
  const { onApiError } = useApiErrorHandler();
  const { enqueueSnackbar } = useSnackbar();
  const { refreshAvatar } = useAppBarContext();
  const classes = useStyles();
  const avatarRef = useRef<AvatarGenerator>(null);
  const [avatar, setAvatar] = useState<AvatarProps>(defaultAvatarProps);
  const [avatarString, setAvatarString, postAvatarString, , isPostingAvatarString] = usePostWith<string | undefined, undefined>(
    saveAvatar(userId),
    undefined,
    {
      onError: onApiError('avatar')
    }
  );

  useEffect(() => {
    const send = async () => {
      const postAvatarResult = await postAvatarString();
      await new Promise(r => setTimeout(r, 200));
      const refreshAvatarResult = await refreshAvatar();

      if (postAvatarResult && refreshAvatarResult) {
        enqueueSnackbar('Your avatar was saved successfully!', { variant: 'success' });
      }
    };

    if (avatarString) {
      send();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avatarString]);

  const randomizeAvatar = () => avatarRef.current.randomize();

  const handleAvatarShapeChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    const value = event.target.value as AvatarShape;

    if (value) {
      setAvatar(prevState => ({ ...prevState, shape: value }));
      randomizeAvatar();
    }
  };

  const handleBackgroundColorChange = (result: ColorResult) => {
    const newColorHex = result.hex;

    const newColor = TinyColor(newColorHex);

    // Returns the perceived brightness of a color, from 0 to 255
    const colorBrightness = newColor.getBrightness();

    let shadeOne: string;
    let shadeTwo: string;
    let shadeThree: string;

    // 100 is the number that seemed to work best, but it's not always perfect...
    if (colorBrightness > 100) {
      // make the shades darker
      shadeOne = newColor.darken(10).toString('hex');
      shadeTwo = newColor.darken(20).toString('hex');
      shadeThree = newColor.darken(30).toString('hex');
    } else {
      // make the shades lighter
      shadeOne = newColor.lighten(10).toString('hex');
      shadeTwo = newColor.lighten(20).toString('hex');
      shadeThree = newColor.lighten(30).toString('hex');
    }

    setAvatar(prevState => ({
      ...prevState,
      backgroundColor: newColorHex,
      colors: [shadeOne, shadeTwo, shadeThree]
    }));
  };

  const handleAvatarSave = async () => {
    const imageData = avatarRef.current.getImageData();
    const croppedImageData = imageData.replace('data:image/png;base64,', '');
    setAvatarString(croppedImageData);
  };

  return (
    <Grid container direction="row" spacing={5} className={classes.container}>
      <Grid item xs={12}>
        <Typography variant="h5">Create Your Avatar!</Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container item direction="column" justify="center" alignItems="center" spacing={3}>
          <Grid item xs>
            <AvatarGenerator
              ref={avatarRef}
              height={100}
              width={100}
              shape={avatar.shape}
              backgroundColor={avatar.backgroundColor}
              colors={avatar.colors}
            />
          </Grid>
          <Grid item xs>
            <Button
              variant="outlined"
              onClick={randomizeAvatar}
              disabled={isPostingAvatarString}
              className={classes.randomiseButton}
            >
              Randomise
            </Button>
            <Button variant="outlined" color="primary" onClick={handleAvatarSave} disabled={isPostingAvatarString}>
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container item direction="column" justify="center" alignItems="center" spacing={3}>
          <Grid item xs className={classes.selectGrid}>
            <FormControl fullWidth>
              <InputLabel id="avatar-shape-select-label" className={classes.label}>
                Shape
              </InputLabel>
              <Select
                labelId="avatar-shape-select-label"
                id="avatar-shape-select"
                value={avatar.shape || ''}
                onChange={handleAvatarShapeChange}
              >
                {Object.keys(AvatarShape).map(shape => (
                  <MenuItem key={AvatarShape[shape]} value={AvatarShape[shape]}>
                    {AvatarShape[shape]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs>
            <CirclePicker
              color={avatar.backgroundColor}
              colors={colorPickerColors}
              onChangeComplete={handleBackgroundColorChange}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AvatarCreator;
