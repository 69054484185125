import React from 'react';
import { Typography, Theme, makeStyles, createStyles, Box, Paper, Grid } from '@material-ui/core';
import { Settings as SettingsIcon } from '@material-ui/icons';
import { Colors } from '../../themes';

export interface SpinningCogsProps {
  progress?: number;
  showMessage?: boolean;
  size?: number;
}

interface StylingProps {
  cols: string[];
  bools: boolean[];
  size: number;
}

const useStyles = ({ cols, bools, size }: StylingProps) =>
  makeStyles((theme: Theme) =>
    createStyles({
      componentContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      },
      cogsContainer: {
        position: 'relative',
        textAlign: 'center',
        width: '6em',
        color: theme.palette.background.default,
        transition: '0.15s ease-in-out',
        transform: `scale(${size}, ${size})`,
        '&:hover': {
          transform: `scale(${size * 1.3}, ${size * 1.3})`
        }
      },
      settingsIconA: {
        animation: '$rotationClockwise 2s infinite linear',
        color: bools[0] ? cols[0] : theme.palette.grey.A100,
        stroke: theme.palette.primary.contrastText,
        fontSize: sizes[0],
        strokeWidth: '1.5%'
      },
      settingsIconB: {
        animation: '$rotationAnticlockwise 2s infinite linear',
        color: bools[1] ? cols[1] : theme.palette.grey.A100,
        stroke: theme.palette.primary.contrastText,
        fontSize: sizes[1],
        strokeWidth: '1.5%'
      },
      settingsIconC: {
        animation: '$rotationClockwise 1.2s infinite linear',
        color: bools[2] ? cols[2] : theme.palette.grey.A100,
        stroke: theme.palette.primary.contrastText,
        fontSize: sizes[2],
        strokeWidth: '1.5%'
      },
      '@keyframes rotationClockwise': {
        from: {
          transform: 'rotate(0deg)'
        },
        to: {
          transform: 'rotate(359deg)'
        }
      },
      '@keyframes rotationAnticlockwise': {
        from: {
          transform: 'rotate(359deg)'
        },
        to: {
          transform: 'rotate(0deg)'
        }
      }
    })
  );

const calculateSize = (number: number, min: number, multiplier: number): number =>
  Math.sqrt(Math.max(number, min) / Math.PI) * multiplier;

const getRandomColor = (): string => {
  const noOfOptions = Object.keys(Colors).length;
  const lastDigit = Math.floor(Math.random() * noOfOptions);
  const colorKey = Object.keys(Colors)[lastDigit];

  return Colors[colorKey];
};

const phrases = ['Just a minute...', 'The Cogs are whirring...', 'Please wait a sec...', '<Hold Music>'];

const sizes = [calculateSize(20, 10, 25), calculateSize(30, 10, 25), calculateSize(10, 10, 25)];

const thresholds = [0, 1, 2].map(f => (f + 1) / 3);
const getRandomCols = () => [0, 1, 2].map(f => getRandomColor());
const getRandomPhrase = (): string => {
  const noOfOptions = phrases.length;
  const hash = Math.random();
  const lastDigit = Math.floor(hash * noOfOptions);
  const phrase = phrases[lastDigit] ?? '...';
  return phrase;
};

const SpinningCogs: React.FC<SpinningCogsProps> = ({ progress = 1.0, showMessage = true, size = 1.0 }) => {
  const [bools, setBools] = React.useState([true, true, true]);
  const [cols, setCols] = React.useState(getRandomCols());

  const classes = useStyles({ cols, bools, size })();

  React.useEffect(() => {
    setBools([0, 1, 2].map((v, i) => progress >= thresholds[i]));
  }, [progress]);

  const phrase = React.useMemo(() => getRandomPhrase(), []);

  return (
    <Grid container className={classes.componentContainer} direction="column">
      {showMessage && (
        <Grid item>
          <Typography variant="h5">{phrase}</Typography>
        </Grid>
      )}
      <Grid item>
        <Paper className={classes.cogsContainer} onClick={() => setCols(getRandomCols())}>
          <Box position="absolute" top={0} left={'0%'}>
            <SettingsIcon className={classes.settingsIconA} />
          </Box>
          <Box position="absolute" top={30} left={'30%'}>
            <SettingsIcon className={classes.settingsIconB} />
          </Box>
          <Box position="absolute" top={20} left={'80%'}>
            <SettingsIcon className={classes.settingsIconC} />
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default SpinningCogs;
