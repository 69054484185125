import React from 'react';
import { Grid, Typography, createStyles, makeStyles } from '@material-ui/core';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '../services/appInsights';
import { useApiErrorHandler } from '../hooks';
import { useGet } from '../hooks/useApi';
import { getAllBadges, getDepartments, getUsers } from '../api/endpoints';
import GearboxBadge from '../features/badges/GearboxBadge';
import DepartmentFilter from '../features/give-cogs/DepartmentFilter';
import { LoadingIndicator } from '../features/common';
import { Title } from '../features/typography';
import IBadge from '../models/IBadge';
import IUser from '../models/IUser';
import IDepartment from '../models/IDepartment';
import { Colors } from '../themes';

const useStyles = makeStyles(() =>
  createStyles({
    userGridContainer: {
      marginTop: '1em'
    },
    badgeGridItem: {
      '& svg': {
        height: '12em'
      },
      '& .MuiSvgIcon-root': {
        height: 'auto'
      }
    },
    userName: {
      paddingTop: '1em'
    }
  })
);

const getRandomColor = (user: IUser) => {
  const noOfOptions = Object.keys(Colors).length;
  const hash = user.UserIdAsDecimal;
  const lastDigit = Math.floor(hash * noOfOptions);
  const colorKey = Object.keys(Colors)[lastDigit];

  return Colors[colorKey];
};

const Team = () => {
  const classes = useStyles();
  const { onApiError } = useApiErrorHandler();

  const [users, isUsersLoading] = useGet<IUser[]>(getUsers(), {
    onError: onApiError('users')
  });
  const [allBadges, isBadgesLoading] = useGet<IBadge[]>(getAllBadges(), {
    onError: onApiError('badges')
  });
  const [departments, isLoadingDepartments] = useGet<IDepartment[]>(getDepartments(), { onError: onApiError('departments') });

  const [currentDepartment, setCurrentDepartment] = React.useState<string | null>(null);

  const isLoading = isUsersLoading || isBadgesLoading;

  return (
    <>
      <LoadingIndicator isLoading={isUsersLoading || isBadgesLoading} />
      <Grid container item xs={12}>
        <Grid item xs={3}>
          <Title text="Team" />
        </Grid>
        <Grid item xs={9}>
          <DepartmentFilter
            departments={departments ?? null}
            areDepartmentsLoading={isLoadingDepartments}
            onCurrentDepartment={currentDepartment => setCurrentDepartment(currentDepartment)}
          />
        </Grid>
      </Grid>
      {!isLoading &&
        users &&
        allBadges &&
        users
          ?.sort((a, b) => a.Name.localeCompare(b.Name))
          ?.filter(user => currentDepartment === null || currentDepartment === user.DepartmentName)
          ?.map(user => (
            <Grid className={classes.userGridContainer} container key={user.UserId}>
              <Grid item xs={4}>
                <Typography className={classes.userName} variant="h5">
                  {user.Name}
                </Typography>
              </Grid>
              <Grid item xs={8} className={classes.badgeGridItem}>
                {user.BadgeIds.map(badgeId => {
                  const wonBadge = allBadges!.find(badge => badge.BadgeId === badgeId);

                  return wonBadge ? (
                    <GearboxBadge key={badgeId} badge={wonBadge} achieved={true} customClr={getRandomColor(user)} />
                  ) : null;
                })}
              </Grid>
            </Grid>
          ))}
    </>
  );
};

export default withAITracking(reactPlugin, Team, 'Team Page');
