import { IAuthenticationSettings } from '../authentication/IAuthenticationSettings';
import {
  API_SCOPE,
  CLIENT_ID,
  REDIRECT_URI,
  TENANT_ID
} from '../environment-variables';

export const getAuthenticationSettings = (): IAuthenticationSettings => {
  return {
    B2CSettings: null,
    AADSettings: {
      TenantId: TENANT_ID
    },
    ClientId: CLIENT_ID,
    AuthRedirectUrl: REDIRECT_URI,
    ApiScope: API_SCOPE
  };
};
