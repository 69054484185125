import React from 'react';
import {
  BadgeProps,
  inactiveColor,
  inactiveColorDark,
  inactiveColorLight
} from './badgeHelpers';

const TenCogsBadge: React.FC<BadgeProps> = ({ isActive }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 56.69 56.69"
    xmlSpace="preserve"
  >
    <g>
      <defs>
        <path
          id="SVGID_1_"
          d="M4.16,27.98c0,13.62,11.04,24.66,24.66,24.66c13.62,0,24.66-11.04,24.66-24.66
       c0-13.62-11.04-24.66-24.66-24.66C15.2,3.33,4.16,14.37,4.16,27.98"
        />
      </defs>
      <clipPath id="SVGID_2_">
        <use xlinkHref="#SVGID_1_" style={{ overflow: 'visible' }} />
      </clipPath>
      <rect
        x="3.95"
        y="3.32"
        style={{
          clipPath: 'url(#SVGID_2_)',
          fill: isActive ? '#F39440' : inactiveColor
        }}
        width="49.32"
        height="49.33"
      />
      <path
        style={{
          clipPath: 'url(#SVGID_2_)',
          fill: isActive ? '#F9C698' : inactiveColorLight
        }}
        d="M47.05,22.29c-5.12-3.71-11.42-5.9-18.22-5.9c-6.81,0-13.1,2.19-18.22,5.9c-0.51,0.37-0.67,1.06-0.37,1.61
   l8.73,16.32h9.87h9.87l8.72-16.32C47.72,23.34,47.56,22.65,47.05,22.29"
      />
      <path
        style={{ clipPath: 'url(#SVGID_2_)', fill: '#FFFFFF' }}
        d="M44.64,23.74c-4.62-3.35-10.09-5.12-15.81-5.12s-11.19,1.77-15.81,5.12c-0.02,0.02-0.03,0.05-0.02,0.08
   l7.57,14.16h16.52l7.57-14.16C44.67,23.79,44.66,23.76,44.64,23.74"
      />
      <path
        style={{
          clipPath: 'url(#SVGID_2_)',
          fill: isActive ? '#722F13' : inactiveColorDark
        }}
        d="M19.51,25.29c-0.13,0.15-0.14,0.36-0.02,0.52c0.3,0.38,7.47,9.39,8.24,10.1c0.92,0.84,2.36,0.78,3.2-0.14
   c0.39-0.42,0.6-0.97,0.6-1.54c0-0.63-0.27-1.24-0.74-1.67c-0.77-0.71-10.37-7.06-10.77-7.33C19.86,25.12,19.64,25.15,19.51,25.29"
      />
      <path
        style={{
          clipPath: 'url(#SVGID_2_)',
          fill: isActive ? '#722F13' : inactiveColorDark
        }}
        d="M28.81,22.49c-0.33,0-0.59-0.26-0.59-0.59v-1.35c0-0.33,0.26-0.59,0.59-0.59s0.59,0.26,0.59,0.59v1.35
   C29.4,22.22,29.14,22.49,28.81,22.49"
      />
      <path
        style={{
          clipPath: 'url(#SVGID_2_)',
          fill: isActive ? '#722F13' : inactiveColorDark
        }}
        d="M22.56,23.6c0.05,0,0.1-0.01,0.15-0.03c0.31-0.1,0.48-0.43,0.38-0.74l-0.35-1.37
   c-0.1-0.31-0.43-0.48-0.74-0.38c-0.31,0.1-0.48,0.43-0.38,0.74l0.35,1.37C22.05,23.45,22.3,23.62,22.56,23.6"
      />
      <path
        style={{
          clipPath: 'url(#SVGID_2_)',
          fill: isActive ? '#722F13' : inactiveColorDark
        }}
        d="M16.61,26c0.1,0,0.2-0.02,0.3-0.07c0.29-0.15,0.4-0.51,0.25-0.8l-0.59-1.22c-0.15-0.29-0.51-0.4-0.79-0.25
   c-0.29,0.15-0.4,0.51-0.25,0.79l0.59,1.22C16.22,25.88,16.41,25.99,16.61,26"
      />
      <path
        style={{
          clipPath: 'url(#SVGID_2_)',
          fill: isActive ? '#722F13' : inactiveColorDark
        }}
        d="M35.23,23.6c-0.05,0-0.1-0.01-0.15-0.03c-0.31-0.1-0.48-0.43-0.38-0.74l0.35-1.37
   c0.1-0.31,0.43-0.48,0.74-0.38c0.31,0.1,0.48,0.43,0.38,0.74l-0.35,1.37C35.73,23.45,35.49,23.62,35.23,23.6"
      />
      <path
        style={{
          clipPath: 'url(#SVGID_2_)',
          fill: isActive ? '#722F13' : inactiveColorDark
        }}
        d="M41.17,26c-0.1,0-0.2-0.02-0.3-0.07c-0.29-0.15-0.4-0.51-0.25-0.8l0.59-1.22c0.15-0.29,0.51-0.4,0.79-0.25
   c0.29,0.15,0.4,0.51,0.25,0.79l-0.59,1.22C41.57,25.88,41.38,25.99,41.17,26"
      />
    </g>
  </svg>
);

export default TenCogsBadge;
