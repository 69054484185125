import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Theme,
  createStyles,
  makeStyles,
  TextField
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
import AssignBadgeForm from './AssignBadgeForm';
import IUserSummary from '../../models/IUserSummary';
import IBadge from '../../models/IBadge';
import { getAvailableBadgesForUser, postUserCogsToPayee, sendMessageFromPayerToPayee, assignBadge } from '../../api/endpoints';
import { useGet, usePostAt, usePostWith } from '../../hooks/useApi';
import { useApiErrorHandler } from '../../hooks';
import ITransaction from '../../models/ITransaction';

interface GiveCogModalProps {
  userId: string;
  userIsTeamLead: boolean;
  userCogsPayable: number;
  payee: IUserSummary;
  onClose: () => void;
  onSuccess: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentText: {
      color: theme.palette.text.primary,
      fontSize: 'small'
    },
    inputLabel: {
      '& label': {
        color: theme.palette.primary.main
      }
    },
    buttonIcon: {
      marginLeft: '0.25em'
    }
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const GiveCogDialog: React.FC<GiveCogModalProps> = ({ userId, userIsTeamLead, userCogsPayable, payee, onClose, onSuccess }) => {
  const classes = useStyles();
  const { onApiError } = useApiErrorHandler();
  const { enqueueSnackbar } = useSnackbar();

  const [currentBadge, setCurrentBadge] = React.useState<IBadge>();

  const [availableBadges, areAvailableBadgesLoading] = useGet<IBadge[]>(getAvailableBadgesForUser(payee.User.UserId), {
    onError: onApiError('badges')
  });

  const [postBadge, , isPostingBadge] = usePostAt(assignBadge(payee.User.UserId, currentBadge?.BadgeId ?? 'invalid-badge'), {
    onError: onApiError('badges')
  });

  const [postCogs, transaction, isPostingCogs] = usePostAt<ITransaction>(postUserCogsToPayee(userId, payee.User.UserId, 1), {
    onError: onApiError('user cogs')
  });

  const [message, setMessage, postMessage, , isPostingMessage] = usePostWith<string | undefined, undefined>(
    sendMessageFromPayerToPayee(userId, payee.User.UserId, transaction?.TransactionId ?? 'Invalid transaction id'),
    undefined,
    {
      onError: onApiError('messages')
    }
  );

  const handleSubmit = async () => {
    const postedCogs = await postCogs();

    if (!postedCogs) {
      enqueueSnackbar('Could not send cog.', { variant: 'error' });
    }
  };

  useEffect(() => {
    // If there is no message, just let the user know that posting a cog was successful
    if (!message && transaction) {
      onSuccess();
      return;
    }

    if (message && transaction) {
      const postMessageFunction = async () => {
        const postedMessage = await postMessage();
        if (!postedMessage) {
          enqueueSnackbar('Could not send message.', { variant: 'error' });
          return;
        }

        setMessage(undefined);

        if (currentBadge) {
          const postBadgeFunction = async () => {
            const postedBadge = await postBadge();
            if (!postedBadge) {
              enqueueSnackbar('Could not send badge.', { variant: 'error' });
              return;
            }
          };

          postBadgeFunction();
        }

        onSuccess();
      };

      postMessageFunction();
    }
  }, [transaction]);

  const handleBadgeSelect = (badge: IBadge | undefined) => setCurrentBadge(badge);

  const areButtonsDisabled = isPostingCogs || isPostingMessage || isPostingBadge || areAvailableBadgesLoading;

  return (
    <Dialog open TransitionComponent={Transition} keepMounted onClose={onClose}>
      <DialogTitle>{payee.User.Name}</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.contentText}>
          Recognise how awesome {payee.User.Name} is by giving them a Cog! If you include a message with the Cog,{' '}
          {payee.User.Name} will be notified about it; otherwise, the Cog will be given anonymously.
        </DialogContentText>
        <TextField
          id="cogMessage"
          label="Add a message (optional)"
          margin="dense"
          onChange={e => setMessage(e.target.value)}
          value={message || undefined}
          className={classes.inputLabel}
          fullWidth
        />
        {userIsTeamLead && (
          <AssignBadgeForm
            payee={payee}
            areAvailableBadgesLoading={areAvailableBadgesLoading}
            availableBadges={availableBadges || []}
            currentBadge={currentBadge}
            onBadgeSelect={handleBadgeSelect}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={areButtonsDisabled}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" disabled={areButtonsDisabled && userCogsPayable > 0}>
          Give 1 Cog
          <EmojiEmotionsIcon className={classes.buttonIcon} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GiveCogDialog;
