import React from 'react';
import {
  Badge,
  Theme,
  withStyles,
  createStyles,
  CircularProgress,
  Tooltip
} from '@material-ui/core';
import { Settings as SettingsIcon } from '@material-ui/icons';

export interface CogsBadgeProps {
  noOfCogs: number | null | undefined;
  tooltipTitle: string;
  isLoading?: boolean;
}

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '1em',
      cursor: 'default'
    },
    badge: {
      color: theme.palette.text.primary,
      fontWeight: 'bold',
      fontSize: '400%',
      borderRadius: '45%',
      left: '0.5em',
      top: '0.5em',
      height: '1em',
      minWidth: '1em'
    }
  })
)(Badge);

const CogsBadge: React.FC<CogsBadgeProps> = ({
  noOfCogs,
  tooltipTitle,
  isLoading = false
}) => (
  <Tooltip title={tooltipTitle} arrow>
    <StyledBadge
      badgeContent={
        isLoading ? (
          <CircularProgress thickness={4} color="inherit" />
        ) : (
          noOfCogs
        )
      }
      color="primary"
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      showZero
    >
      <SettingsIcon style={{ fontSize: 160 }} />
    </StyledBadge>
  </Tooltip>
);

export default CogsBadge;
