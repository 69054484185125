import React from 'react';
import { useSnackbar } from 'notistack';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '../services/appInsights';
import IUserSummary from '../models/IUserSummary';
import IMe from '../models/IMe';
import IDepartment from '../models/IDepartment';
import { useGet } from '../hooks/useApi';
import { useApiErrorHandler, useDebounce } from '../hooks';
import { getDepartments, getUserMeSummary, getUserSummaries } from '../api/endpoints';
import { LoadingIndicator } from '../features/common';
import AllUsersCogs from '../features/give-cogs/AllUsersCogs';
import GiveCogDialog from '../features/give-cogs/GiveCogDialog';
import HeaderGrid from '../features/give-cogs/HeaderGrid';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageContent: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between'
    },
    usersContainer: {
      flexBasis: '100%',
      flexGrow: 1
    },
    inputLabel: {
      '& label': {
        color: theme.palette.primary.main
      }
    }
  })
);

const GiveCogs = () => {
  const { onApiError } = useApiErrorHandler();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [searchName, setSearchName] = React.useState<string>('');
  const [matchedUserIds, setMatchedUserIds] = React.useState<string[]>([]);
  const [currentDepartment, setCurrentDepartment] = React.useState<string | null>(null);

  const [selectedUser, setSelectedUser] = React.useState<IUserSummary | null>(null);

  const [me, isLoadingMe, refreshMe] = useGet<IMe>(getUserMeSummary(), {
    onError: onApiError('me summary')
  });

  const [users, isLoadingUsers, refreshUsers] = useGet<IUserSummary[]>(getUserSummaries(), {
    onError: onApiError('user summaries')
  });

  const [departments, isLoadingDepartments] = useGet<IDepartment[]>(getDepartments(), { onError: onApiError('departments') });

  const [debouncedSearchName] = useDebounce<string>(searchName, 500);

  React.useEffect(() => {
    if (debouncedSearchName && users) {
      setMatchedUserIds(
        users
          .filter(f => f.User.Name.toLocaleLowerCase().indexOf(debouncedSearchName.toLocaleLowerCase()) > -1)
          .map(f => f.User.UserId)
      );
    }
  }, [debouncedSearchName, users]);

  const handleUserSelect = (user: IUserSummary | null) => setSelectedUser(user);

  const handleSuccess = async () => {
    setSelectedUser(null);
    await refreshUsers();
    await refreshMe();
    enqueueSnackbar('Cog has been sent!', { variant: 'success' });
  };

  const filteredUsers =
    me !== undefined
      ? users?.filter(
          user =>
            user.User.UserId !== me?.UserId && (user.User.DepartmentName === currentDepartment || currentDepartment === null)
        ) || []
      : [];

  return (
    <div className={classes.pageContent}>
      <LoadingIndicator isLoading={isLoadingUsers || isLoadingMe} />
      <HeaderGrid
        noOfUsers={users?.length || 0}
        departments={departments ?? null}
        searchName={searchName}
        noOfCogs={me?.UserSummary.UserCogs.CogsPayable}
        isMeLoading={isLoadingMe}
        areDepartmentsLoading={isLoadingDepartments}
        onSearchNameChanged={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setSearchName(e.target.value)}
        onDepartmentChange={currentDepartment => setCurrentDepartment(currentDepartment)}
      />
      <div className={classes.usersContainer}>
        <AllUsersCogs
          users={filteredUsers}
          isSearchMode={debouncedSearchName.length > 0}
          searchResults={matchedUserIds}
          onUserSelect={handleUserSelect}
        />
      </div>
      {me && selectedUser && (
        <GiveCogDialog
          userId={me?.UserId}
          userIsTeamLead={me?.IsTeamLead}
          userCogsPayable={me?.UserSummary.UserCogs.CogsPayable}
          payee={selectedUser}
          onClose={() => setSelectedUser(null)}
          onSuccess={handleSuccess}
        />
      )}
    </div>
  );
};

export default withAITracking(reactPlugin, GiveCogs, 'Give Cogs Page');
