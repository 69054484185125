import React from 'react';
import {
  BadgeProps,
  inactiveColor,
  inactiveColorDark,
  inactiveColorLight
} from './badgeHelpers';

const FiftyCogsBadge: React.FC<BadgeProps> = ({ isActive }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 56.69 56.69"
    xmlSpace="preserve"
  >
    <g>
      <defs>
        <path
          id="SVGID_3_"
          d="M3.95,27.98c0,13.62,11.04,24.66,24.66,24.66c13.62,0,24.66-11.04,24.66-24.66
			c0-13.62-11.04-24.66-24.66-24.66C14.99,3.33,3.95,14.37,3.95,27.98"
        />
      </defs>
      <clipPath id="SVGID_2_">
        <use xlinkHref="#SVGID_3_" style={{ overflow: 'visible' }} />
      </clipPath>
      <rect
        x="3.95"
        y="3.32"
        style={{
          clipPath: 'url(#SVGID_2_)',
          fill: isActive ? '#84AE5B' : inactiveColor
        }}
        width="49.32"
        height="49.33"
      />
      <path
        style={{
          clipPath: 'url(#SVGID_2_)',
          fill: isActive ? '#ABCF80' : inactiveColorLight
        }}
        d="M46.84,22.29c-5.12-3.71-11.42-5.9-18.22-5.9c-6.81,0-13.1,2.19-18.22,5.9
		c-0.51,0.37-0.67,1.06-0.37,1.61l8.73,16.32h9.87h9.87l8.72-16.32C47.51,23.34,47.35,22.65,46.84,22.29"
      />
      <path
        style={{ clipPath: 'url(#SVGID_2_)', fill: '#FFFFFF' }}
        d="M44.43,23.74c-4.62-3.35-10.09-5.12-15.81-5.12s-11.19,1.77-15.81,5.12c-0.02,0.02-0.03,0.05-0.02,0.08
		l7.57,14.16h16.52l7.57-14.16C44.46,23.79,44.45,23.76,44.43,23.74"
      />
      <path
        style={{
          clipPath: 'url(#SVGID_2_)',
          fill: isActive ? '#335B3C' : inactiveColorDark
        }}
        d="M28.66,23.41c-0.17,0-0.31,0.12-0.33,0.28
		c-0.07,0.41-1.58,10.12-1.57,11.02c0,1.07,0.87,1.94,1.94,1.95c0.49,0,0.95-0.18,1.31-0.51c0.4-0.36,0.62-0.88,0.62-1.42
		c0-0.9-1.56-10.62-1.63-11.04C28.97,23.54,28.83,23.41,28.66,23.41"
      />
      <path
        style={{
          clipPath: 'url(#SVGID_2_)',
          fill: isActive ? '#335B3C' : inactiveColorDark
        }}
        d="M28.61,22.49c-0.33,0-0.59-0.26-0.59-0.59v-1.35
		c0-0.33,0.26-0.59,0.59-0.59s0.59,0.26,0.59,0.59v1.35C29.2,22.22,28.93,22.49,28.61,22.49"
      />
      <path
        style={{
          clipPath: 'url(#SVGID_2_)',
          fill: isActive ? '#335B3C' : inactiveColorDark
        }}
        d="M22.35,23.6c0.05,0,0.1-0.01,0.15-0.03c0.31-0.1,0.48-0.43,0.38-0.74
		l-0.35-1.37c-0.1-0.31-0.43-0.48-0.74-0.38c-0.31,0.1-0.48,0.43-0.38,0.74l0.35,1.37C21.84,23.45,22.09,23.62,22.35,23.6"
      />
      <path
        style={{
          clipPath: 'url(#SVGID_2_)',
          fill: isActive ? '#335B3C' : inactiveColorDark
        }}
        d="M16.4,26c0.1,0,0.2-0.02,0.3-0.07c0.29-0.15,0.4-0.51,0.25-0.8l-0.59-1.22
		c-0.15-0.29-0.51-0.4-0.79-0.25c-0.29,0.15-0.4,0.51-0.25,0.79l0.59,1.22C16.01,25.88,16.2,25.99,16.4,26"
      />
      <path
        style={{
          clipPath: 'url(#SVGID_2_)',
          fill: isActive ? '#335B3C' : inactiveColorDark
        }}
        d="M35.02,23.6c-0.05,0-0.1-0.01-0.15-0.03c-0.31-0.1-0.48-0.43-0.38-0.74
		l0.35-1.37c0.1-0.31,0.43-0.48,0.74-0.38c0.31,0.1,0.48,0.43,0.38,0.74l-0.35,1.37C35.53,23.45,35.28,23.62,35.02,23.6"
      />
      <path
        style={{
          clipPath: 'url(#SVGID_2_)',
          fill: isActive ? '#335B3C' : inactiveColorDark
        }}
        d="M40.97,26c-0.1,0-0.2-0.02-0.3-0.07c-0.29-0.15-0.4-0.51-0.25-0.8
		l0.59-1.22c0.15-0.29,0.51-0.4,0.79-0.25c0.29,0.15,0.4,0.51,0.25,0.79l-0.59,1.22C41.36,25.88,41.17,25.99,40.97,26"
      />
    </g>
  </svg>
);

export default FiftyCogsBadge;
