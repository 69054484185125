import React from 'react';
import {
  BadgeProps,
  inactiveColor,
  inactiveColorDark,
  inactiveColorLight
} from './badgeHelpers';

const OneHundredCogsBadge: React.FC<BadgeProps> = ({ isActive }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 56.69 56.69"
    xmlSpace="preserve"
  >
    <g>
      <defs>
        <path
          id="SVGID_5_"
          d="M4.46,29.18c0,13.62,11.04,24.66,24.66,24.66c13.62,0,24.66-11.04,24.66-24.66
			c0-13.62-11.04-24.66-24.66-24.66C15.5,4.52,4.46,15.56,4.46,29.18"
        />
      </defs>
      <clipPath id="SVGID_2_">
        <use xlinkHref="#SVGID_5_" style={{ overflow: 'visible' }} />
      </clipPath>
      <rect
        x="3.95"
        y="3.32"
        style={{
          clipPath: 'url(#SVGID_2_)',
          fill: isActive ? '#9665A7' : inactiveColor
        }}
        width="49.32"
        height="49.33"
      />
      <path
        style={{
          clipPath: 'url(#SVGID_2_)',
          fill: isActive ? '#B48BBE' : inactiveColorLight
        }}
        d="M47.35,23.48c-5.12-3.71-11.42-5.9-18.22-5.9c-6.81,0-13.1,2.19-18.22,5.9
		c-0.51,0.37-0.67,1.06-0.37,1.61l8.73,16.32h9.87H39l8.72-16.32C48.02,24.54,47.86,23.85,47.35,23.48"
      />
      <path
        style={{ clipPath: 'url(#SVGID_2_)', fill: '#FFFFFF' }}
        d="M44.94,24.94c-4.62-3.35-10.09-5.12-15.81-5.12s-11.19,1.77-15.81,5.12c-0.02,0.02-0.03,0.05-0.02,0.08
		l7.57,14.16h16.52l7.57-14.16C44.97,24.99,44.96,24.95,44.94,24.94"
      />
      <path
        style={{
          clipPath: 'url(#SVGID_2_)',
          fill: isActive ? '#4F3163' : inactiveColorDark
        }}
        d="M38.32,26.07c-0.15-0.13-0.36-0.14-0.52-0.02
		c-0.38,0.3-9.41,7.45-10.12,8.22c-0.85,0.92-0.79,2.36,0.13,3.2c0.42,0.39,0.96,0.6,1.53,0.6c0.63,0,1.24-0.27,1.67-0.73
		c0.71-0.77,7.09-10.35,7.36-10.76C38.49,26.42,38.46,26.21,38.32,26.07"
      />
      <path
        style={{
          clipPath: 'url(#SVGID_2_)',
          fill: isActive ? '#4F3163' : inactiveColorDark
        }}
        d="M29.11,23.68c-0.33,0-0.59-0.26-0.59-0.59v-1.35
		c0-0.33,0.26-0.59,0.59-0.59c0.33,0,0.59,0.26,0.59,0.59v1.35C29.7,23.42,29.44,23.68,29.11,23.68"
      />
      <path
        style={{
          clipPath: 'url(#SVGID_2_)',
          fill: isActive ? '#4F3163' : inactiveColorDark
        }}
        d="M22.86,24.8c0.05,0,0.1-0.01,0.15-0.03c0.31-0.1,0.48-0.43,0.38-0.74
		l-0.35-1.37c-0.1-0.31-0.43-0.48-0.74-0.38c-0.31,0.1-0.48,0.43-0.38,0.74l0.35,1.37C22.35,24.65,22.6,24.81,22.86,24.8"
      />
      <path
        style={{
          clipPath: 'url(#SVGID_2_)',
          fill: isActive ? '#4F3163' : inactiveColorDark
        }}
        d="M16.91,27.2c0.1,0,0.2-0.02,0.3-0.07c0.29-0.15,0.4-0.51,0.25-0.8
		l-0.59-1.22c-0.15-0.29-0.51-0.4-0.79-0.25c-0.29,0.15-0.4,0.51-0.25,0.79l0.59,1.22C16.52,27.07,16.71,27.19,16.91,27.2"
      />
      <path
        style={{
          clipPath: 'url(#SVGID_2_)',
          fill: isActive ? '#4F3163' : inactiveColorDark
        }}
        d="M35.53,24.8c-0.05,0-0.1-0.01-0.15-0.03c-0.31-0.1-0.48-0.43-0.38-0.74
		l0.35-1.37c0.1-0.31,0.43-0.48,0.74-0.38c0.31,0.1,0.48,0.43,0.38,0.74l-0.35,1.37C36.03,24.65,35.79,24.81,35.53,24.8"
      />
      <path
        style={{
          clipPath: 'url(#SVGID_2_)',
          fill: isActive ? '#4F3163' : inactiveColorDark
        }}
        d="M41.47,27.2c-0.1,0-0.2-0.02-0.3-0.07c-0.29-0.15-0.4-0.51-0.25-0.8
		l0.59-1.22c0.15-0.29,0.51-0.4,0.79-0.25c0.29,0.15,0.4,0.51,0.25,0.79l-0.59,1.22C41.87,27.07,41.68,27.19,41.47,27.2"
      />
    </g>
  </svg>
);

export default OneHundredCogsBadge;
