import React from 'react';
import Spinner from 'react-spinkit';
import { Colors } from '../../themes';

interface SmallLoadingIndicatorProps {
  isLoading: boolean;
  color?: Colors;
  className?: string | undefined;
}

const SmallLoadingIndicator: React.FC<SmallLoadingIndicatorProps> = ({ isLoading, color = Colors.TURQUOISE, className }) => (
  <>{isLoading && <Spinner name="ball-pulse-sync" color={color} className={className} />}</>
);

export default SmallLoadingIndicator;
