import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core';
import {
  Star,
  ErrorOutline,
  Settings as SettingsIcon,
  ThumbUp
} from '@material-ui/icons';
import IBadge from '../../models/IBadge';
import { inactiveColor } from './badgeHelpers';
import {
  FirstCogBadge,
  TenCogsBadge,
  ThirtyCogsBadge,
  FiftyCogsBadge,
  OneHundredCogsBadge,
  FunkyBadge,
  CogKarmaBadge
} from './';

export interface GearboxBadgeProps {
  badge: IBadge;
  achieved: boolean;
  customClr?: string;
}

const useStyles = (customClr: string | null) =>
  makeStyles((theme: Theme) =>
    createStyles({
      badge: {
        color: customClr ?? theme.palette.primary.main,
        fontSize: 160
      },
      badgeNotWon: {
        color: inactiveColor,
        fontSize: 160
      }
    })
  );

const IconFromBadgeName = (
  badgeName: string,
  isActive: boolean,
  className: string
) => {
  switch (badgeName) {
    case 'First cog badge':
      return <FirstCogBadge isActive={isActive} />;
    case 'Ten cogs':
      return <TenCogsBadge isActive={isActive} />;
    case 'Thirty cogs':
      return <ThirtyCogsBadge isActive={isActive} />;
    case 'Fifty cogs':
      return <FiftyCogsBadge isActive={isActive} />;
    case 'One hundred cogs':
      return <OneHundredCogsBadge isActive={isActive} />;
    case 'Superstar':
      return <Star className={className} />;
    case 'Cog Karma':
      return <CogKarmaBadge isActive={isActive} />;
    case 'Thumbs Up!':
      return <ThumbUp className={className} />;
    case 'Super clever cog':
      return <SettingsIcon className={className} />;
    case 'Funky badge':
      return <FunkyBadge isActive={isActive} />;
    default:
      return <ErrorOutline className={className} />;
  }
};

const GearboxBadge: React.FC<GearboxBadgeProps> = ({
  badge,
  achieved,
  customClr
}) => {
  const classes = useStyles(customClr ?? null)();
  return (
    <>
      {IconFromBadgeName(
        badge.Name,
        achieved,
        achieved ? classes.badge : classes.badgeNotWon
      )}
    </>
  );
};

export default GearboxBadge;
