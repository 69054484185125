import React, { useState } from 'react';
import {
  createStyles,
  makeStyles,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import IMessage from '../../models/IMessage';
import MessageRow from './MessageRow';

interface MessageTableProps {
  messages: IMessage[];
  maxRows: number;
}

const useStyles = makeStyles(() =>
  createStyles({
    messageColumn: {
      width: '40%'
    },
    spinnerColumn: {
      width: '5%',
      alignContent: 'left'
    },
    unreadColumn: {
      width: '5%'
    },
    buttonGroup: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      maxWidth: '40%',
      margin: '0 auto'
    }
  })
);

const MessageTable: React.FC<MessageTableProps> = ({ messages, maxRows }) => {
  const [startingDisplayIndex, setStartingDisplayIndex] = useState<number>(0);
  const classes = useStyles();

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" className={classes.unreadColumn}>
                {' '}
              </TableCell>
              <TableCell>Date</TableCell>
              <TableCell className={classes.messageColumn}>Message</TableCell>
              <TableCell>Sender</TableCell>
              <TableCell align="center">Read</TableCell>
              <TableCell align="left" className={classes.spinnerColumn}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {messages
              .sort((a, b) => new Date(b.DateSent).getTime() - new Date(a.DateSent).getTime())
              .slice(startingDisplayIndex, startingDisplayIndex + maxRows)
              .map(message => (
                <MessageRow key={message.MessageId} message={message} classes={classes} />
              ))}
          </TableBody>
        </Table>
        <div className={classes.buttonGroup}>
          <Button
            size="large"
            color="secondary"
            onClick={() => setStartingDisplayIndex(startingDisplayIndex - maxRows)}
            disabled={startingDisplayIndex === 0}
          >
            <NavigateBeforeIcon fontSize="large" />
          </Button>
          <Button
            size="large"
            color="secondary"
            onClick={() => setStartingDisplayIndex(startingDisplayIndex + maxRows)}
            disabled={startingDisplayIndex + maxRows >= messages.length}
          >
            <NavigateNextIcon fontSize="large" />
          </Button>
        </div>
      </TableContainer>
    </>
  );
};

export default MessageTable;
