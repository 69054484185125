import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Link, Typography } from '@material-ui/core';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '../services/appInsights';

const NotFound = () => {
  const location = useLocation();

  return (
    <Typography>
      Could not find a page for "<code>{location.pathname}</code>" - go to{' '}
      <Link component={RouterLink} to="/">
        Home Page
      </Link>
      .
    </Typography>
  );
};

export default withAITracking(reactPlugin, NotFound, 'Not Found Page');
