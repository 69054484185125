import React from 'react';
import { createStyles, FormControl, InputLabel, makeStyles, MenuItem, Select, Theme } from '@material-ui/core';
import IDepartment from '../../models/IDepartment';
import { SmallLoadingIndicator } from '../../features/common';
import { Colors } from '../../themes';

interface DepartmentFilterProps {
  departments: IDepartment[] | null;
  areDepartmentsLoading: boolean;
  onCurrentDepartment: (currentDepartment: string | null) => void;
  showAllOption?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      marginRight: '3em'
    },
    formControl: {
      minWidth: 170,
      marginRight: '0.75em'
    },
    label: {
      color: theme.palette.primary.main
    }
  })
);

const DepartmentFilter: React.FC<DepartmentFilterProps> = ({
  departments,
  areDepartmentsLoading,
  onCurrentDepartment,
  showAllOption = true
}) => {
  const classes = useStyles();

  const [currentDepartment, setCurrentDepartment] = React.useState('');

  const handleSelect = event => {
    const { value } = event.target;

    setCurrentDepartment(value as string);
    onCurrentDepartment(value === 'All' ? null : (value as string));
  };

  return (
    <div className={classes.container}>
      <FormControl className={classes.formControl}>
        <InputLabel className={classes.label}>Select Department</InputLabel>
        <Select value={currentDepartment} onChange={handleSelect}>
          {showAllOption && <MenuItem value="All">All</MenuItem>}
          {departments?.map(department => (
            <MenuItem key={department.Name} value={department.Name}>
              {department.Name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <SmallLoadingIndicator isLoading={areDepartmentsLoading} color={Colors.PURPLE} />
    </div>
  );
};

export default DepartmentFilter;
