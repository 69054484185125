import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import UserCog from './UserCog';
import IUserSummary from '../../models/IUserSummary';

interface AllUsersCogsProps {
  users: IUserSummary[];
  isSearchMode: boolean;
  searchResults?: string[];
  onUserSelect: (user: IUserSummary) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    allCogsContainer: {
      display: 'grid',
      gridAutoFlow: 'dense',
      gridTemplateColumns: 'repeat(96, 1fr)',
      gridAutoRows: '1em'
    }
  })
);

const AllUsersCogs: React.FC<AllUsersCogsProps> = ({ users, isSearchMode, searchResults, onUserSelect }) => {
  const classes = useStyles();

  return (
    <div className={classes.allCogsContainer}>
      {users.map(user => (
        <UserCog
          key={user.User.UserId}
          user={user}
          isHighlighted={isSearchMode ? (searchResults && searchResults.includes(user.User.UserId) ? true : false) : undefined}
          onClick={() => onUserSelect(user)}
        />
      ))}
    </div>
  );
};

export default AllUsersCogs;
