// The maximum is exclusive and the minimum is inclusive
/**
 * @param min the minimum number (inclusive)
 * @param max the maximum number (exclusive)
 * @returns a random integer
 */
const getRandomInt = (min: number, max: number): number => {
  const minInt = Math.ceil(min);
  const maxInt = Math.floor(max);
  return Math.floor(Math.random() * (maxInt - minInt) + minInt);
};

const getStringHash = (s: string): number =>
  s.split('').reduce((a, b) => {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);

export { getRandomInt, getStringHash };
