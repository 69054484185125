import React, { useContext } from 'react';

interface ThemeContextProps {
  isDarkTheme: boolean;
  setIsDarkTheme(isDarkTheme: boolean): void;
}

const defaultContextProps: ThemeContextProps = {
  isDarkTheme: false,
  setIsDarkTheme: () => {}
};

const ThemeContext = React.createContext<ThemeContextProps>(
  defaultContextProps
);

const useThemeContext = () => useContext(ThemeContext);

export { ThemeContext };
export default useThemeContext;
