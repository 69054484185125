import React from 'react';
import { Typography } from '@material-ui/core';

export interface TitleProps {
  text: string;
}

const Title: React.FC<TitleProps> = ({ text }) => (
  <Typography variant="h2" component="h1">
    {text}
  </Typography>
);

export default Title;
