import * as React from 'react';
import { IPublicClientApplication, AccountInfo, PublicClientApplication, Configuration, LogLevel } from '@azure/msal-browser';
import { getAuthenticationSettings } from '../services/authenticationSettings';

export interface IMsalContext {
  instance: IPublicClientApplication;
  loginInProgress: boolean;
  accounts: AccountInfo[];
  primaryAccount: AccountInfo | null;
}

const getMsalConfig = (): Configuration => {
  const authenticationSettings = getAuthenticationSettings();

  const BASE_MSAL_CONFIG: Configuration = {
    auth: undefined,
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: authenticationSettings.StoreAuthStateInCookie ?? false
    },
    system: {
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              return;
            case LogLevel.Info:
              console.info(message);
              return;
            case LogLevel.Verbose:
              console.debug(message);
              return;
            case LogLevel.Warning:
              console.warn(message);
              return;
          }
        }
      }
    }
  };

  if (authenticationSettings.AADSettings) {
    return {
      ...BASE_MSAL_CONFIG,
      auth: {
        authority: `https://login.microsoftonline.com/${authenticationSettings.AADSettings.TenantId}`,
        clientId: authenticationSettings.ClientId,
        redirectUri: authenticationSettings.AuthRedirectUrl
      }
    };
  }

  if (authenticationSettings.B2CSettings) {
    return {
      ...BASE_MSAL_CONFIG,
      auth: {
        authority: authenticationSettings.B2CSettings.TenantSignIn,
        clientId: authenticationSettings.ClientId,
        redirectUri: authenticationSettings.AuthRedirectUrl,
        postLogoutRedirectUri: authenticationSettings.AuthRedirectUrl,
        knownAuthorities: [authenticationSettings.B2CSettings.TenantSignIn]
      }
    };
  }

  throw 'Please define one of AADSettings or B2CSettings';
};

const msalClient: IPublicClientApplication = new PublicClientApplication(getMsalConfig());

const defaultMsalContext: IMsalContext = {
  instance: msalClient,
  loginInProgress: false,
  accounts: [],
  primaryAccount: null
};

export const MsalContext = React.createContext<IMsalContext>(defaultMsalContext);
export const MsalConsumer = MsalContext.Consumer;
export const MsalClient = msalClient;
