import React from 'react';
import { ThemeProvider as MaterialUiThemeProvider } from '@material-ui/core/styles';
import { usePersistedState, useThemeContext } from '../hooks';
import { ThemeContext } from '../hooks/useThemeContext';
import { darkTheme, lightTheme } from '../themes';

interface ProviderProps {
  children: React.ReactNode;
}

const ThemeProvider: React.FC<ProviderProps> = ({ children }) => {
  const [isDarkTheme, setIsDarkTheme] = usePersistedState(false, 'dark-mode');

  return (
    <ThemeContext.Provider value={{ isDarkTheme, setIsDarkTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

const withThemeProvider = <Props extends {}>(
  WrappedComponent: React.ComponentType<Props>
) => (props: Props) => {
  const ThemedComponent = (props: Props) => {
    const { isDarkTheme } = useThemeContext();
    const theme = isDarkTheme ? darkTheme : lightTheme;

    return (
      <MaterialUiThemeProvider theme={theme}>
        <WrappedComponent {...props} />
      </MaterialUiThemeProvider>
    );
  };

  return (
    <ThemeProvider>
      <ThemedComponent {...props} />
    </ThemeProvider>
  );
};

export default withThemeProvider;
