import { createMuiTheme } from '@material-ui/core/styles';

enum Colors {
  GREEN = '#83BA3B',
  YELLOW = '#FAC32D',
  BLUE = '#52A1D9',
  RED = '#EC6740',
  PURPLE = '#9585BE',
  TURQUOISE = '#40B6A4'
}

const sharedTypography = {
  fontFamily: ['Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
  h1: {
    fontWeight: 500,
    fontSize: '4em',
    paddingBottom: '0.25em'
  },
  h2: {
    fontWeight: 400,
    fontSize: '3.75em',
    paddingBottom: '0.25em'
  },
  h3: {
    fontWeight: 300,
    fontSize: '3.5em'
  },
  h4: {
    fontWeight: 300,
    fontSize: '3.25em'
  },
  h5: {
    fontWeight: 300,
    fontSize: '3em'
  }
};

const sharedPaletteColours = {
  primary: {
    main: Colors.TURQUOISE
  },
  secondary: {
    main: Colors.PURPLE
  },
  error: {
    main: '#D33F49'
  },
  success: {
    main: Colors.GREEN
  },
  info: {
    main: Colors.BLUE
  }
};

const lightTheme = createMuiTheme({
  palette: {
    ...sharedPaletteColours,
    text: {
      primary: '#000000',
      secondary: Colors.TURQUOISE
    },
    type: 'light'
  },
  typography: sharedTypography
});

const darkTheme = createMuiTheme({
  palette: {
    ...sharedPaletteColours,
    background: {
      default: '#3E3C3C'
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#000000'
    },
    type: 'dark'
  },
  typography: sharedTypography
});

export { lightTheme, darkTheme, Colors };
