export default interface IBadge {
  BadgeId: string;
  BadgeType: BadgeType;
  Name: string;
  Description: string;
  MinCogsThreshold?: number;
  PredecessorBadgeId?: string;
}

enum BadgeType {
  Undefined = 0,
  Cogs = 1,
  AdHoc = 2
}

export { BadgeType };
