import React from 'react';
import { Backdrop, createStyles, makeStyles, Theme } from '@material-ui/core';
import SpinningCogs from './SpinningCogs';

export interface LoadingIndicatorProps {
  isLoading?: boolean[] | boolean;
  isProgressionMode?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff'
    }
  })
);

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
  isLoading = true,
  isProgressionMode = false
}) => {
  const classes = useStyles();

  const arr = isLoading as boolean[];
  const boolean = isLoading as boolean;

  if (
    (!arr && !boolean) ||
    (arr.length !== undefined && arr.filter(f => f).length === 0)
  ) {
    return <></>;
  }

  const progress = !isProgressionMode
    ? 1.0
    : arr.length !== undefined
    ? Math.min(1.0, (arr.filter(f => !f).length + 1) / arr.length)
    : 1.0;

  return (
    <Backdrop className={classes.backdrop} open={true}>
      <SpinningCogs progress={progress} />
    </Backdrop>
  );
};

export default LoadingIndicator;
