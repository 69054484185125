import React, { useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '../services/appInsights';
import { useGet } from '../hooks/useApi';
import { useApiErrorHandler } from '../hooks';
import * as uris from '../api/endpoints';
import IBadge from '../models/IBadge';
import IMe from '../models/IMe';
import GearboxBadge from '../features/badges/GearboxBadge';
import { LoadingIndicator } from '../features/common';
import { Title } from '../features/typography';

const AvailableBadges = () => {
  const { onApiError } = useApiErrorHandler();

  const [me, isMeLoading] = useGet<IMe>(uris.getUserMeSummary(), {
    onError: onApiError('me summary')
  });

  const [availableBadges, areAvailableBadgesLoading, refreshAvailableBadges] = useGet<IBadge[]>(
    uris.getAvailableBadgesForUser(me?.UserId || ''),
    {
      onError: onApiError('badges'),
      shouldGetImmediately: false
    }
  );

  useEffect(() => {
    const refresh = async () => await refreshAvailableBadges();

    if (me?.UserId) {
      refresh();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me?.UserId]);

  const isLoading = areAvailableBadgesLoading || isMeLoading || availableBadges === null || me === null;

  const hasWon = (badgeId: string) => (me && me.UserSummary.User.BadgeIds.some(f => f === badgeId)) ?? false;

  return (
    <>
      <Title text="Available Badges" />
      <Typography paragraph>
        The colourful badges are the ones you have already achieved, keep going to receive the badges that are currently greyed
        out!
      </Typography>
      <LoadingIndicator isLoading={[areAvailableBadgesLoading, isMeLoading]} />
      {!isLoading &&
        availableBadges &&
        availableBadges.map(badge => (
          <Grid container key={badge.BadgeId}>
            <Grid item xs={2}>
              <GearboxBadge badge={badge} achieved={hasWon(badge.BadgeId)} />
            </Grid>
            <Grid item container xs={10}>
              <Grid item xs={12}>
                <Typography style={{ paddingTop: '24px' }} variant="h4">
                  {badge.Name}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">{badge.Description}</Typography>
              </Grid>
            </Grid>
          </Grid>
        ))}
    </>
  );
};

export default withAITracking(reactPlugin, AvailableBadges, 'Available Badges Page');
