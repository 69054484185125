import React from 'react';
import { NavLink } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  Drawer as MaterialUiDrawer,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import PeopleIcon from '@material-ui/icons/People';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
import { drawerWidth, getDrawerHeaderStyle } from './styleHelpers';

export interface DrawerProps {
  isDrawerOpen: boolean;
  onDrawerClose(): void;
}

const links = [
  {
    text: 'Give Cogs',
    icon: LocalAtmIcon,
    link: '/'
  },
  {
    text: 'Team',
    icon: PeopleIcon,
    link: '/team'
  },
  {
    text: 'Available Badges',
    icon: SportsEsportsIcon,
    link: '/badges'
  }
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0
    },
    drawerPaper: {
      width: drawerWidth
    },
    drawerHeader: getDrawerHeaderStyle(theme)
  })
);

const Drawer: React.FC<DrawerProps> = ({ isDrawerOpen, onDrawerClose }) => {
  const classes = useStyles();

  return (
    <MaterialUiDrawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={isDrawerOpen}
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <div className={classes.drawerHeader}>
        <IconButton onClick={onDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List>
        {links.map(link => {
          const IconToRender = link.icon;

          return (
            <ListItem button component={NavLink} to={link.link} key={link.link}>
              <ListItemIcon onClick={onDrawerClose}>
                <IconToRender />
              </ListItemIcon>
              <ListItemText primary={link.text} onClick={onDrawerClose} />
            </ListItem>
          );
        })}
      </List>
    </MaterialUiDrawer>
  );
};

export default Drawer;
